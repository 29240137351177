
export namespace UrlUtils {
  /**
   * @description
   * Returns the subdomain of a given URL.
   * If the subdomain is in the format `feature-<number>-<subdomain>`, it returns `<subdomain>`.
   * Otherwise, it returns the subdomain as is.
   *
   * @param {string} fullURL - The URL from which to extract the subdomain.
   * @returns {string | undefined} The subdomain of the URL or undefined if it could not be parsed.
   */
  export function getSubDomain(fullURL: string): string | undefined {
    try {
      const referrerURL = new URL(fullURL);
      const domainParts = referrerURL.hostname.split('.');
      const subdomain = domainParts[0];
      if (subdomain.startsWith('feature-')) {
        return subdomain.split('-')[2];
      } else {
        return subdomain;
      }
    } catch (e) {
      return undefined;
    }
  }


  /**
   * @description
   * Returns the path and query parameters of a given URL.
   *
   * @param {string} fullURL - The URL from which to extract the path and query parameters.
   * @returns {string | undefined} The path and query parameters of the URL or undefined if it could not be parsed.
   */
  export function getURLPathWithQueryParameters(fullURL: string): string | undefined {
    try {
      const referrerURL = new URL(fullURL);
      return `${referrerURL.pathname}${referrerURL.search}`;
    } catch (e) {
      return undefined;
    }
  }
}
