import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NewDefaultCourseCarouselComponent} from './new-default-course-carousel.component';
import {CourseCardNewModule} from '@components/course-card-new/course-card-new.module';
import {HorizontalCourseCardModule} from '@components/horizontal-course-card-new/horizontal-course-card.module';


@NgModule({
  declarations: [NewDefaultCourseCarouselComponent],
  exports: [
    NewDefaultCourseCarouselComponent
  ],
    imports: [
        CommonModule,
        CourseCardNewModule,
        HorizontalCourseCardModule,
    ]
})
export class NewDefaultCourseCarouselModule {
}
