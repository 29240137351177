import {Injectable} from '@angular/core';
import {CustomHttpService} from "@services/request-token/custom-http.service";
import {IAdSourceType, IConversionSource, IConversionSourceHttp, IHeroBanner} from '@interfaces/marketing/marketing.interface';
import {IAdOrderDetails} from '@interfaces/shopping-cart/shopping-cart-list.interface';
import {LanguageId} from "@interfaces/common/language.interface";

@Injectable({
  providedIn: 'root'
})
export class MarketingHttpService {

  constructor(private customHttpService: CustomHttpService) { }

  getAdUSerOrder(sender: string): Promise<IAdOrderDetails> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/deepLinking/api/getUserAdOrder',
      sender: sender,
      receiver: 'getUserAdOrder',
      body: {}
    });
  }

  getUserAd(sender: string): Promise<IConversionSourceHttp> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/deepLinking/api/getUserAd',
      sender: sender,
      receiver: 'get-UserAd',
      body: {}
    });
  }

  addUserFromAd(sender: string, adSourceType: IAdSourceType, adSourceId: string): Promise<{result: boolean}> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/deepLinking/api/addUserAd',
      sender: sender,
      receiver: 'addUserAd',
      body: {
        adSourceId,
        adSourceType
      }
    })
  }

  getHeroBanner(languageId: LanguageId): Promise<IHeroBanner> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/banner/api/getHeroBanner',
      sender: 'banner',
      receiver: 'get-banner',
      body: {languageId},
    });
  }
}
