import {Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AbstractControl, FormControl, ReactiveFormsModule, UntypedFormControl, Validators} from '@angular/forms';
import {IHttpError} from '@interfaces/common/http.interface';
import {CouponSubscriptionPlan, CouponVaidationRequest, IPlanCoupon} from '@interfaces/coupon/coupon.interface';
import {DiscountItem, ISubscriptionPlan} from '@interfaces/subscription/subscription.interface';
import {CouponsService} from '@services/integrations/coupons/coupons.service';
import {BrazeService} from '@services/marketing/braze/braze.service';
import {UiLoaderService} from '@services/ui-loader/ui-loader.service';
import {LanguageControlService} from '@services/language/language-control.service';
import ar from './i18n/ar.json';
import en from './i18n/en.json';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {AlmTranslateCutModule} from '@pipes/alm-translate-cut';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatRippleModule} from '@angular/material/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {CouponDetails} from '@components/subscription/subscription-checkout/subscription-checkout.interface';
import {TrackingService} from '@services/tracking/tracking.service';

@Component({
  selector: 'alm-root-apply-coupon-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    AlmTranslateCutModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './apply-coupon-form.component.html',
  styleUrls: ['./apply-coupon-form.component.scss']
})
export class ApplyCouponFormComponent implements OnInit, OnChanges {
  couponsService = inject(CouponsService);
  brazeService = inject(BrazeService);
  languageControl = inject(LanguageControlService);
  trackingService = inject(TrackingService);

  @Input({required: true}) selectedPlan: ISubscriptionPlan;
  @Input() couponName?: string;
  @Output() onApplyingCoupon: EventEmitter<CouponDetails> = new EventEmitter<CouponDetails>();
  couponCode: FormControl<string>;
  couponInvalidError?: string;
  showCouponField: boolean = false;
  couponPlan: CouponSubscriptionPlan;
  loading: boolean;

  constructor() {
    this.languageControl.setTranslations('ar', ar)
    this.languageControl.setTranslations('en', en)
    this.couponCode = new FormControl<string>(null, Validators.required);
    this.couponCode.valueChanges.pipe(takeUntilDestroyed()).subscribe(value => {
      this.couponInvalidError = undefined;
    });
  }

  ngOnInit(): void {
  }

  async onValidatingCouponCode() {
    const couponName = this.couponCode.value?.trim();
    if (!couponName) return;
    try {
      const coupon = await this.validateCoupon(couponName, this.selectedPlan);
      if (coupon.valid) {
        this.brazeService.logEvent('promocode_success');
        this.trackingService.sendEvent('coupon_code_submitted', {
          event_properties: {
            coupon_code: this.couponCode.value,
            coupon_code_accepted: coupon.valid,
            selected_subscription_plan_currency: coupon.subscriptionPlan.currency,
            selected_subscription_plan_price: coupon.subscriptionPlan.finalPrice,
            selected_subscription_plan_type: this.selectedPlan.nameEn,
            selected_subscription_plan_id: coupon.subscriptionPlan.newPlanId.toString(),
          }
        });
      }
    } catch (error) {
      this.brazeService.logEvent('promocode_failed');
      this.trackingService.sendEvent('coupon_code_submitted', {
        event_properties: {
          coupon_code: this.couponCode.value,
          coupon_code_accepted: false,
          coupon_code_failure_reason: error.message,
          selected_subscription_plan_currency: this.selectedPlan.currency,
          selected_subscription_plan_price: this.selectedPlan.finalPrice,
          selected_subscription_plan_type: this.selectedPlan.nameEn,
          selected_subscription_plan_id: this.selectedPlan.id.toString(),
        },
      });
    }
  }

  resetCouponValue(): void {
    this.couponCode.reset();
    this.couponPlan = undefined;
    this.showCouponField = false;
    this.onApplyingCoupon.emit({
      couponName: undefined,
      coupon: {
        valid: false,
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('couponName')) {
      if (!this.couponName) return this.resetCouponValue();

      if (this.couponName !== this.couponCode.value) {
        this.couponCode.patchValue(this.couponName);
        this.showCouponField = true;
        this.validateCoupon(this.couponName, this.selectedPlan);
      }
    }
  }

  displayCouponField() {
    this.showCouponField = true;
  }

  private async validateCoupon(couponName: string, selectedPlan: ISubscriptionPlan): Promise<IPlanCoupon> {
    this.loading = true;
    const couponVaidationRequest: CouponVaidationRequest = {
      couponCode: couponName,
      countryCode: selectedPlan.countryName,
      newPlanId: selectedPlan.id,
      isSubscriptionPlan: true,
      validateCouponUserType: 1,
      languageId: this.languageControl.getCurrentLanguage().id,
    };
    return this.couponsService.validateCouponForSubscriptionPlan(couponVaidationRequest).then(coupon => {
      this.couponPlan = coupon.subscriptionPlan;
      if (coupon.valid) {
        this.onApplyingCoupon.emit({
          couponName,
          coupon
        });
      }
      return coupon;
    }).catch((error) => {
      this.couponInvalidError = error.message;
      this.onApplyingCoupon.emit({
        couponName,
        coupon: {
          valid: false,
        }
      });
      throw error;
    }).finally(() => {
      this.loading = false;
    });
  }
}
