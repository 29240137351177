import {ELanguage, ETheme, IConnectorUserInfo} from "@interfaces/common/connector.interface";
import {enc as ENC} from "crypto-js";
import {DEFAULT_LANGUAGE_CODE, DEFAULT_THEME} from "@constants/ui.constants";

export namespace CookiesUtils {

  export const defaultUserPreferences: IConnectorUserInfo = {
    lang: DEFAULT_LANGUAGE_CODE,
    theme: DEFAULT_THEME,
    user: null
  };
  export function generateEncodedUserInfoCookie(userInfo: IConnectorUserInfo): string {
    const userInfoCookie: Partial<IConnectorUserInfo>[] = [
      {lang : userInfo.lang},
      {theme: userInfo.theme},
      {user: userInfo.user},
    ];
    return ENC.Base64.stringify(ENC.Utf8.parse((JSON.stringify(userInfoCookie))));
  }

  export function getDecodedUserInfoFromCookie(encodedCookie: string | undefined): IConnectorUserInfo {

    if (!encodedCookie) return defaultUserPreferences;

    const decodedUserInfo = ENC.Utf8.stringify(ENC.Base64.parse(encodedCookie));
    const userInfoCookie: IConnectorUserInfo[] | IConnectorUserInfo = JSON.parse(decodedUserInfo);

    if (userInfoCookie instanceof Array) {
      return  userInfoCookie.reduce((userInfo, current) => {
        userInfo = {...userInfo, ...current};
        return userInfo
      }, defaultUserPreferences);
    } else { // support old users who own cookie contain object instead of array
      return userInfoCookie;
    }
  }

}
