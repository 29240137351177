import {Injectable} from '@angular/core';
import {CustomHttpService} from '@services/request-token/custom-http.service';
import {IPagination, IPaginationRequest} from '@interfaces/common/pagination.interface';
import {
  CourseCertificatesPaginated,
  IAssignedCourse, ICertificateDetails,
  ICourse,
  ICourseProgress,
  ISimplifiedCourse
} from '@interfaces/course/course.interface';
import {ICurriculumSection} from '@interfaces/viewer/curriculum.interface';
import {ITempCategory} from '@interfaces/temp-category/temp-category.interface';
import {IWeeklyAssignedCourse} from '@interfaces/learning-goals/learning-goals.interface';
import {ISimplifiedCategory} from '@interfaces/course/category.interface';
import {INotesPaginated} from '@interfaces/viewer/note.interface';
import {ICourseDiscoverySection} from "@anonymous/shared/models/anonymous-course.interface";
import {LanguageId} from "@interfaces/common/language.interface";
import { filter } from 'rxjs/operators';
import { LanguageControlService } from '@services/language/language-control.service';

@Injectable({
  providedIn: 'root'
})
export class CoursesHttpService {

  constructor(private customHttpService: CustomHttpService,private languageControl: LanguageControlService) {
  }

  getCoursesByCategoryIdAndLanguageIdv(sender: string, languageId: number, categoryId: number,
                                       paginationRequest: IPaginationRequest, filter: Object): {
    send: Promise<IPagination<ISimplifiedCourse>>,
    cancel: () => void
  } {
    return this.customHttpService.sendCancelableRequest({
      endpoint: 'new-orchestrator/getCourse/api/courses/category',
      sender: sender,
      receiver: 'category',
      body: {
        entityId: categoryId,
        languageId: languageId,
        paginationRequest: paginationRequest,
        filterMap: filter
      }
    });
  }

  getFilteredCourses(sender: string, languageId: number, paginationRequest: IPaginationRequest,
                     filter: Object): Promise<IPagination<ICourse>> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/getCourse/api/courses/filter',
      sender: sender,
      receiver: 'filter',
      body: {
        languageId: languageId,
        paginationRequest: paginationRequest,
        filterMap: filter
      }
    });
  }

  getCourseById(sender: string, languageId: number, paginationRequest: IPaginationRequest,
                filter: Object): Promise<IPagination<ICourse>> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/getCourse/api/courses/id/category',
      sender: sender,
      receiver: 'category',
      body: {
        languageId: languageId,
        paginationRequest: paginationRequest,
        filterMap: filter
      }
    }, false);
  }

  getRelatedCourses(sender: string, languageId: number, paginationRequest: IPaginationRequest,
                    filter: Object): Promise<IPagination<ICourse>> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/getCourse/api/courses/related',
      sender: sender,
      receiver: 'related',
      body: {
        languageId: languageId,
        paginationRequest: paginationRequest,
        filterMap: filter
      }
    });
  }

  getRankedCourses(sender: string, languageId: number, paginationRequest: IPaginationRequest,
                   filter: Object): Promise<IPagination<ISimplifiedCourse>> {
    return this.customHttpService.sendRequest({
      endpoint: 'orchestrator/api/courses/ranked',
      sender: sender,
      receiver: 'ranked',
      body: {
        languageId: languageId,
        paginationRequest: paginationRequest,
        filterMap: filter
      }
    });
  }

  getFeaturedCourses(sender: string, languageId: number, paginationRequest: IPaginationRequest,
                     filter: Object): Promise<IPagination<ICourse>> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/getCourse/api/courses/featured-courses',
      sender: sender,
      receiver: 'ranked',
      body: {
        languageId: languageId,
        paginationRequest: paginationRequest,
        filterMap: filter
      }
    });
  }

  getTrendingCourses(sender: string, languageId: number, paginationRequest: IPaginationRequest,
                       filter: Object): Promise<IPagination<ISimplifiedCourse>> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/getCourse/api/courses/trending-courses',
      sender: sender,
      receiver: 'popular-courses',
      body: {
        languageId: languageId,
        paginationRequest: paginationRequest,
        filterMap: filter
      }
    });
  }

  getPopularCourses(sender: string, languageId: number, paginationRequest: IPaginationRequest,
                    filter: Object): Promise<IPagination<ISimplifiedCourse>> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/getCourse/api/courses/popular-courses',
      sender: sender,
      receiver: 'popular-courses',
      body: {
        languageId: languageId,
        paginationRequest: paginationRequest,
        filterMap: filter
      }
    });
  }

  getMyCourses(sender: string, languageId: number, filter: Object,
               paginationRequest: IPaginationRequest): Promise<IPagination<ISimplifiedCourse>> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/getCourse/api/courses/myCourses',
      sender: sender,
      receiver: 'myCourses',
      body: {
        languageId: languageId,
        filterMap: filter,
        paginationRequest: paginationRequest
      }
    });
  }

  getCourseOutline(courseId: number, languageId: number, sender: string): Promise<{
    numberOfLessons: number,
    sections: ICurriculumSection []
  }> {
    return this.customHttpService.sendRequest({
      endpoint: 'orchestrator/api/courses/simple/curriculum',
      sender: sender,
      receiver: 'outline',
      body: {
        hierarchyLevel: 2,
        entityId: courseId,
        languageId: languageId
      }
    });
  }

  getPicksOfDay(sender: string, languageId: number): Promise<IPagination<ICourse>> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/getCourse/api/courses/picksOfTheDay',
      sender: sender,
      receiver: 'outline',
      body: {
        languageId: languageId
      }
    });
  }

  getRecentlyViewedCourses(sender: string, languageId: number, paginationRequest: IPaginationRequest): Promise<IPagination<ICourse>> {
    return this.customHttpService.sendRequest({
      endpoint: 'orchestrator/api/courses/RecentlyViewed',
      sender: sender,
      receiver: 'recentlyViewed',
      body: {
        languageId: languageId,
        paginationRequest: paginationRequest
      }
    });
  }

  getCoursesPromoViews(videoId: string, courseId: number): Promise<{
    'alltime_video_views': number
  }> {
    return this.customHttpService.sendRequest({
      endpoint: 'orchestrator/api/courses/save/promo/views',
      sender: 'course-details',
      receiver: 'coursesPromoViews',
      body: {
        videoId: videoId,
        courseId: courseId
      }
    });
  }

  getTopTempCategories(sender: string, languageId: number): Promise<ITempCategory []> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/getCategory/api/categories/topTemp',
      sender: sender,
      receiver: 'topTemp',
      body: {
        languageId: languageId
      }
    });
  }

  getCourseIdByPermanentLink(sender: string, permanentLink: string): Promise<{
    id: number
  }> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/getCourse/api/courses/id/permanentLink',
      sender: sender,
      receiver: 'permanentLink',
      body: {
        permanentLink: permanentLink
      }
    }, false);
  }

  getCourseRemainingProgress(sender: string, courseId: number): Promise<ICourseProgress> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/getCourseProgres/api/courseUserProgress/getCourseUserProgress',
      sender: sender,
      receiver: 'getCourseUserProgress',
      body: {
        courseId: courseId
      }
    });
  }

  getAssignedCoursesWithinWeek(sender: string, weekStartDate: string, weekEndDate: string, currentLanguageId: number): Promise<IWeeklyAssignedCourse[]> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/getCourseProgres/api/b2b/assigned-courses-within-weak',
      sender: sender,
      receiver: 'permanentLink',
      body: {
        weekStartDate: weekStartDate,
        weekEndDate: weekEndDate,
        languageId: currentLanguageId
      }
    });
  }

  getB2BCategories(sender: string, languageId: number, paginationRequest: IPaginationRequest): Promise<IPagination<ISimplifiedCategory>> {
    return this.customHttpService.sendRequest({
      endpoint: 'b2b-orchestrator/adminGetCategory/api/b2b/customized-category',
      sender: sender,
      receiver: 'customized-category',
      body: {
        languageId: languageId,
        paginationRequest: paginationRequest,
      }
    });
  }

  getMyCoursesPageItems(sender: string, languageId: number, paginationRequest: IPaginationRequest, filter: Object): {
    send: Promise<IPagination<ICourse>>,
    cancel: () => void
  } {
    return this.customHttpService.sendCancelableRequest({
      endpoint: 'new-orchestrator/getCourseProgres/api/b2b/my-progress-carousel',
      sender: sender,
      receiver: 'my-progress-carousel',
      body: {
        languageId: languageId,
        paginationRequest: paginationRequest,
        filter: filter
      }
    });
  }

  getAllOrgCourses(sender: string, languageId: number, paginationRequest: IPaginationRequest, filter: Object,sort:boolean = false): {
    send: Promise<IPagination<ICourse>>,
    cancel: () => void
  } {
    return this.customHttpService.sendCancelableRequest({
      endpoint: 'new-orchestrator/getCourseProgres/api/b2b/organization-courses-carousel',
      sender: sender,
      receiver: 'my-progress-carousel',
      body: {
        languageId: languageId,
        paginationRequest: paginationRequest,
        filter: filter,
        returnOldestFirst:sort
      }
    });
  }
  getProgressCourses(sender: string, languageId: number, paginationRequest: IPaginationRequest, filter: Object): Promise<IPagination<ICourse>> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/getCourseProgres/api/b2b/my-progress-carousel',
      sender: sender,
      receiver: 'my-progress-carousel',
      body: {
        languageId: languageId,
        paginationRequest: paginationRequest,
        filter: filter
      }
    });
  }

  getAssignedCourses(sender: string, languageId: number, paginationRequest: IPaginationRequest): Promise<IPagination<IAssignedCourse>> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/getCourseProgres/api/b2b/assigned-courses',
      sender: sender,
      receiver: 'assigned-courses',
      body: {
        languageId: languageId,
        paginationRequest: paginationRequest,
      }
    });
  }

  getRecommendedCourses(sender: string, languageId: number, paginationRequest: IPaginationRequest): Promise<IPagination<ISimplifiedCourse>> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/getCourse/api/courses/interestes',
      sender: sender,
      receiver: 'interestes',
      body: {
        languageId: languageId,
        paginationRequest: paginationRequest,
      }
    });
  }

  getMyCertificatesPageItems(
    sender: string,
    languageId: number,
    paginationRequest: IPaginationRequest,
    filter: Object
  ): {
    send: Promise<CourseCertificatesPaginated>,
    cancel: () => void
  } {
    return this.customHttpService.sendCancelableRequest({
      endpoint: 'new-orchestrator/certificate/api/certificates',
      sender: sender,
      receiver: 'certificates',
      body: {
        languageId: languageId,
        paginationRequest: paginationRequest,
        filter: filter
      }
    });
  }

  getCertificateDetails(sender: string, certificateCode: string, languageId: number): Promise<ICertificateDetails> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/certificate/api/GetCertificateDetails',
      sender: sender,
      receiver: 'certificate',
      body: {
        certificateCode: certificateCode,
        languageId: languageId
      }
    });
  }

  getCertificateUrl(sender: string, certificateId: number): Promise<{
    certificateURL: string
  }> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/certificate/api/GetCertificateURL',
      sender: sender,
      receiver: 'certificate-url',
      body: {
        certificateId: certificateId,
      }
    });
  }


  getUserNotesPageItems(sender: string, languageId: number, paginationRequest: IPaginationRequest, filter: Object): {
    send: Promise<INotesPaginated>,
    cancel: () => void
  } {
    return this.customHttpService.sendCancelableRequest({
      endpoint: 'new-orchestrator/getCourse/api/note/myNotes',
      sender: sender,
      receiver: 'myNotes',
      body: {
        languageId: languageId,
        paginationRequest: paginationRequest,
        filter: filter
      }
    });
  }

  getUserCertificates(
    sender: string,
    languageId: number,
    paginationRequest: IPaginationRequest,
    filter: Object
  ): Promise<CourseCertificatesPaginated> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/certificate/api/certificates',
      sender: sender,
      receiver: 'certificates',
      body: {
        languageId: languageId,
        paginationRequest: paginationRequest,
        filter: filter
      }
    });
  }

  getUserNotes(sender: string, languageId: number, paginationRequest: IPaginationRequest, filter: Object): Promise<INotesPaginated> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/getCourse/api/note/myNotes',
      sender: sender,
      receiver: 'myNotes',
      body: {
        languageId: languageId,
        paginationRequest: paginationRequest,
        filter: filter
      }
    });
  }

  getNewCourses(sender: string, paginationRequest: IPaginationRequest, filter: object, languageId: number): Promise<IPagination<ICourse>> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/getCourse/api/courses/getNewCourses',
      sender: sender,
      receiver: 'getNewCourses',
      body: {
        languageId: languageId,
        paginationRequest: paginationRequest,
        filterMap: filter
      }
    });
  }

  getCourseDiscoveryList(sender: string, languageId: LanguageId): Promise<ICourseDiscoverySection[]> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/getCourse/api/courses/courses-discovery',
      sender: sender,
      receiver: 'courses-discovery',
      body: {
        languageId: languageId,
      }
    });
  }

  getPrivateCourses(paginationRequest: IPaginationRequest,filter: object,sort: boolean =false): Promise<any> {

    const currentLanguageId = this.languageControl.currentLanguage.getValue().id;
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/getCourseProgres/api/b2b/organization-private-courses-carousel',
      sender: 'viewer',
        receiver: 'courseProgress',
        body: {
          paginationRequest: paginationRequest,
          filter:filter,
          languageId: currentLanguageId,
          "returnOldestFirst":sort
        }

      });
    }

  getB2bPromotedCategories() {
    return this.customHttpService.getRequest({
      endpoint: 'catalog/categories/b2b-home-carousel',
    });
  }
}
