<div class="footer-almentor">
  <svg>
    <use href="assets/images/sprite.svg#logo-small" />
  </svg>
  <ul>
    <li><a href="https://blog.almentor.net/" target="_blank">
      {{'footer.blog' | translate}}
    </a></li>
    <li><a href="https://about.almentor.net/" target="_blank">
      {{'footer.footer_almentor' | translate | translateCut: 0}}
    </a></li>
    <li><a href="https://about.almentor.net/blog" target="_blank">
      {{'footer.footer_almentor' | translate | translateCut: 3}}
    </a></li>
    <li><a href="https://about.almentor.net/meet-the-team" target="_blank">
      {{'footer.footer_almentor' | translate | translateCut: 5}}
    </a></li>
    <li><a href="https://about.almentor.net/contact" target="_blank">
      {{'footer.footer_almentor' | translate | translateCut: 1}}
    </a></li>
  </ul>
</div>
