import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {UiLoaderService} from '@services/ui-loader/ui-loader.service';
import {ConnectorV2Service} from '@services/connector/connector-v2.service';
import {AppRedirectsService} from '@services/app-redirects/app-redirects.service';
import {UserManagerService} from '@services/user/user-manager.service';
import {environment} from '@environments/environment';
import {EPrevError, IEnvironmentPlatforms, IUserPlatformRoute} from '@interfaces/user/user-common.interface';
import {UserService} from '@services/integrations/user/user.service';
import {EPrivilegesPlatform} from '@interfaces/privileges/privileges.interface';
import {SSORedirectUrlParams} from '@interfaces/common/http.interface';
import {REDIRECT_PARAMETER} from '@constants/query-strings.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private userManagerService: UserManagerService,
              private userService: UserService,
              private router: Router,
              private appRedirects: AppRedirectsService,
              private uiLoader: UiLoaderService,
              private connectorService: ConnectorV2Service,
  ) {
  }

  private checkUserAuth(state: RouterStateSnapshot, route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.userManagerService.checkUserAuthorization(route, this.userManagerService.userPlatform)
        .then(authResponse => {
          if (authResponse.authorized) {
            if (this.userManagerService.userPrivileges.value?.website.access) {
              this.connectorService.connectorLoaded.next(true);
              this.connectorService.connectorLoaded.complete();
              if (authResponse.reachedDevicesLimit) {
                this.router.navigate(['/devices']);
                resolve(false);
              } else if (!authResponse.name) {
                this.router.navigate(['/user-info'], {queryParams: {[REDIRECT_PARAMETER]: state.url}});
                resolve(false);
              } else {
                resolve(true);
              }
            } else {
              resolve(false);
              if (authResponse.isAllBlocked) {
                this.redirectToRedirectCenter(this.userManagerService.userPlatform, state.url, {prevErr: EPrevError.Blocked});
              } else {
                this.redirectToRedirectCenter(this.userManagerService.userPlatform, state.url, {prevErr: EPrevError.NoAccess}, route);
              }
            }
          } else {
            resolve(false);
            if (authResponse.reachedDevicesLimit) {
              this.connectorService.connectorLoaded.next(true);
              this.connectorService.connectorLoaded.complete();
              this.router.navigate(['/devices']);
            } else if (route.data.redirectToAnonymous === false) {
                this.appRedirects.redirectToSSO();
            } else {
              this.checkForAlternativePlatform(route, state).then(platformRoute => {
                this.redirectToPlatform(platformRoute);
              }).catch(() => {
                this.redirectToRedirectCenter(this.userManagerService.userPlatform, state.url, {prevErr: EPrevError.NoAccess}, route, (typeof this.userManagerService.userPrivileges.value?.website.access == 'undefined'));
              });
            }
          }
        });
    });
  }

  checkForAlternativePlatform(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IUserPlatformRoute> {
    return new Promise((resolve, reject) => {
      let currentUser = this.userManagerService.userInfo.getValue();
      let localUser = this.connectorService.connectorUser.getValue();
      if (!currentUser && !localUser) {
        const currentPlatform = this.userManagerService.userPlatform;
        if (!currentPlatform || currentPlatform === environment.platforms.b2c.code) {
          const anonymousDomain: string[] = /^(?:\w+\:\/\/)?([^\/]+)([^\?]*)\??(.*)$/.exec(environment.platforms.anonymous.link) || [];
          resolve({
            platform: {
              platform: EPrivilegesPlatform.B2bBase,
              domain: anonymousDomain[1].split('.')[0]
            },
            path: routeSnapshot?.data.redirectToAnonymous? state.url : undefined,
          });
        } else {
          reject();
        }
      } else {
        this.userService.getUserPlatforms().then(userPlatforms => {
          const B2COrB2BUserPlatform = this.userManagerService.getB2COrB2BUserPlatform(userPlatforms);
          if (B2COrB2BUserPlatform?.platform === this.userManagerService.userPlatform) return reject();
          if (B2COrB2BUserPlatform) { // redirect to relative B2C or B2B platform
            resolve({
              platform: B2COrB2BUserPlatform,
              path: state.url,
            });
          } else if (userPlatforms.length === 1) {
            resolve({
              platform: userPlatforms[0],
            });
          } else {
            reject();
          }
        }).catch(() => {
          this.connectorService.removeUser();
          reject();
        });
      }
    });
  }

  private redirectToPlatform(platformRoute: IUserPlatformRoute) {
    this.uiLoader.startUiLoader('outside-loader');
    let platformUrl;
    let baseUrl = environment.platforms.base.link;
    const standardPlatform = Object.values<IEnvironmentPlatforms>(environment.platforms).find(p => p.code === platformRoute.platform.platform);
    if (standardPlatform) {
      platformUrl = `${standardPlatform.link}`;
    } else {
      platformUrl = `https://${platformRoute.platform.domain}.${baseUrl}`;
    }
    window.location.replace(platformUrl + (platformRoute.path || ''));
  }

  private redirectToRedirectCenter(platform: string | null, redirectPath: string, extraParams?: SSORedirectUrlParams, route?: ActivatedRouteSnapshot, shouldRedirectToAnonymous?: boolean) {
    this.uiLoader.startUiLoader('outside-loader');
    if (platform === environment.platforms.b2c.code && shouldRedirectToAnonymous) {
      if (route?.data) {
        let routeData = route.data;
        if (routeData.redirectToAnonymous) {
          this.appRedirects.redirectToAnonymous(redirectPath);
        } else {
          this.appRedirects.redirectToSSO('redirect-center', extraParams);
        }
      } else {
        this.appRedirects.redirectToSSO('redirect-center', extraParams);
      }
    } else {
      this.appRedirects.redirectToSSO('redirect-center', extraParams);
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkUserAuth(state, route);
  }

}
