import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppContainerComponent} from './app-container.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {HeaderModule} from '@components/header/header.module';
import {MainHeaderNewModule} from '@components/main-header-new/main-header-new.module';
import {SimplifiedHeaderModule} from '@components/simplified-header/simplified-header.module';
import {TopRibbonModule} from '@components/top-ribbon/top-ribbon.module';


@NgModule({
  declarations: [AppContainerComponent],
  exports: [
    AppContainerComponent
  ],
    imports: [
        CommonModule,
        MatSidenavModule,
        HeaderModule,
        MainHeaderNewModule,
        SimplifiedHeaderModule,
        TopRibbonModule,
    ]
})
export class AppContainerModule { }
