import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {LanguageControlService} from "@services/language/language-control.service";
import {ELanguage} from "@interfaces/common/connector.interface";

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(
    private languageService: LanguageControlService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const languageCode = this.languageService.getCurrentLanguage().code;
    let updatedRequest = request.clone({
      setHeaders: {
        'Accept-Language': languageCode,
      },
    });
    return next.handle(updatedRequest);
  }
}

