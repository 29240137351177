import {Injectable} from '@angular/core';
import {CustomHttpService} from '@services/request-token/custom-http.service';
import {IPagination, IPaginationRequest} from '@interfaces/common/pagination.interface';
import {ICourse} from '@interfaces/course/course.interface';
import {
  SearchResponse,
  InstantSearchResults,
  ISearchMentor,
  SearchEntityType
} from '@interfaces/search/search.interface';
import {UserManagerService} from '@services/user/user-manager.service';
import {environment} from "@environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SearchHttpService {

  constructor(private customHttpService: CustomHttpService, private userManagerService: UserManagerService) {
  }

  instantSearch(query: string): Promise<InstantSearchResults> {
    return this.customHttpService.getRequest({
      endpoint: `search-svc/auto-complete?query=${query}`,
    });
  }

  getSearchResultsIds<T>(sender: string, query: string, entityType: SearchEntityType, paginationRequest: IPaginationRequest, filterMap?: { objectID: string }): Promise<SearchResponse<T>> {
    const isB2BUser = this.userManagerService.userPlatform != null && this.userManagerService.userPlatform != environment.platforms.b2c.code;

    return this.customHttpService.sendRequest({
      endpoint: 'search-svc/search',
      sender: sender,
      receiver: 'search',
      body: {
        entityType: entityType,
        filterMap: filterMap ? filterMap : {},
        paginationRequest: paginationRequest,
        query: query,
        isB2b: isB2BUser
      }
    });
  }

  getCoursesByIds(sender: string, coursesIds: number[], languageId: number): Promise<ICourse[]> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/getCourse/api/courses/search-courses',
      sender: sender,
      receiver: 'ids',
      body: {
        languageId: languageId,
        coursesIds: coursesIds
      }
    });
  }

  getMentorsByIds(sender: string, mentorsIds: string[], languageId: number): Promise<ISearchMentor[]> {
    return this.customHttpService.sendRequest({
      endpoint: 'orchestrator/api/mentor/course/details',
      sender: sender,
      receiver: 'details',
      body: {
        filterMap: {
          'language.id': languageId,
          'main_user.main_user_type_uuid': mentorsIds
        }
      }
    });
  }
}
