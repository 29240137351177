import {Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LanguageControlService} from "@services/language/language-control.service";
import {DEFAULT_LANGUAGE_CODE} from "@constants/ui.constants";
import {ILanguageCode} from "@interfaces/common/language.interface";

const MIDDLE_MOUSE_BUTTON = 1;

@Directive({
  selector: 'a[almCustomHref]',
  standalone: true
})
export class CustomHrefDirective implements OnInit {
  /**
   * A directive that extends `<a>` elements to behave in accordance with PWA (Progressive Web Apps) guidelines
   * while maintaining SEO-friendly links. It also localizes URLs based on the available language.
   *
   * Please avoid using standard Angular `(click)` events with this directive. Instead, utilize the "clickEvent" Output
   * for capturing click interactions.
   *
   * You may use both absolute (e.g., `https://yourdomain.com/page1`) and relative (e.g., `/page1`) URLs.
   * Please note that only relative URLs will be localized.
   *
   * Usage:
   * 1. Add this directive to the `<a>` element as an attribute and set its value to the desired URL.
   *  For Example:  <a [almCustomHref]="yourURL">Link Text</a>
   * 2. To handle click events, use `(clickEvent)` instead of `(click)`.
   *  For Example: <a [almCustomHref]="yourURL" (clickEvent)="yourMethod()">Link Text</a>
   *
   * @param {string} almCustomHref - The desired URL for your `<a>` element, can be both absolute and relative.
   * @required
   */
  @Input() almCustomHref: string;
  /**
   * The EventEmitter instance for user click events.
   * This is designed to fire only with a standard, unmodified click on a link,
   * ignoring special clicks, like those involving modifier keys or targeted at opening a new tab.
   * @class
   * @template T - The payload type for the event.
   */
  @Output() clickEvent: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  /**
   * An EventEmitter instance purposed for tracking all user clicks.
   * This fires unconditionally for every type of click, including those with modifier keys like ctrl+click or shift+click.
   * This event is useful when you want to separate click analytics and actionable clicks.
   * Since a click opening a new tab does not require additional navigation on the current page,
   * this event lets you listen without triggering further actions.
   */
  @Output() clickTracking: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  private fullPath: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private languageControl: LanguageControlService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  @HostListener('mouseup', ['$event'])
  handleMouseup(event: MouseEvent): void {
    if (event.button === MIDDLE_MOUSE_BUTTON) {
      return this.emitClickTrackingEvent(event);
    }
  }
  @HostListener('click', ['$event'])
  handleClick(event: MouseEvent): void {
    const target = this.el.nativeElement.getAttribute('target');
    const modifiersPressed = ['ctrlKey', 'shiftKey', 'altKey'].some(key => event[key]);

    // here we need to preventDefault ASAP, so we flipped the if condition
    if (target !== '_blank' && !modifiersPressed) {
      event.preventDefault();
    } else {
      return this.emitClickTrackingEvent(event);
    }

    // Otherwise prevent the default behavior
    this.emitClickTrackingEvent(event)
    // Check if there are any subscribers to the customHrefClick event
    if (this.clickEvent.observed) {
      // Emit the customHrefClick event
      this.clickEvent.emit(event);
    } else {
      // Navigate using the Angular router
      this.router.navigateByUrl(this.fullPath);
    }
  }

  ngOnInit(): void {
    if (!this.almCustomHref) {
      throw new Error('almCustomHref is required for CustomHrefDirective');
    }
    const {code: currentLanguageCode} = this.languageControl.getCurrentLanguage();
    this.fullPath = this.constructFullPath(currentLanguageCode);
    this.renderer.setAttribute(this.el.nativeElement, 'href', this.fullPath);
  }

  private constructFullPath(languageCode: ILanguageCode): string {
    const isAbsoluteUrl = /^(?:[a-z]+:)?\/\//i.test(this.almCustomHref);

    if (isAbsoluteUrl) {
      // Absolute URL: Do not append the language code
      return this.almCustomHref;
    } else {
      // Root-relative URL: Append the language code
      const languageCodePath = languageCode !== DEFAULT_LANGUAGE_CODE ? `/${languageCode}` : '';
      const customHref = this.almCustomHref.startsWith('/') ? this.almCustomHref: `/${this.almCustomHref}`;
      return `${languageCodePath}${customHref}`;
    }
  }

  /**
   * Emits a click tracking event if click tracking is observed.
   *
   * @param {MouseEvent} event - The mouse event that triggered the click.
   *
   * @return {void}
   */
  private emitClickTrackingEvent(event: MouseEvent) {
    if (this.clickTracking.observed) {
      this.clickTracking.emit(event);
    }
  }
}
