import {
  Component,
  EventEmitter,
  HostListener, Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output, PLATFORM_ID,
  SimpleChanges
} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {IUserInfo} from '@interfaces/authorized-user/user.interface';
import {FilesManagerService} from '@services/files/files-manager.service';
import {ThemeControlService, THEMES} from '@services/theme/theme-control.service';
import {UserCartService} from '@services/integrations/user-cart/user-cart.service';
import {ChatService} from '@services/integrations/chat/chat.service';
import {ILoyaltyInfo} from '@interfaces/user/user-common.interface';
import {LanguageControlService} from '@services/language/language-control.service';
import ar from './i18n/ar.json';
import en from './i18n/en.json';

import {isPlatformBrowser} from '@angular/common';
import {UserManagerService} from "@services/user/user-manager.service";
import { CustomContentService } from '@services/custom-content/custom-content.service';

@Component({
  selector: 'alm-root-main-header-new',
  templateUrl: './main-header-new.component.html',
  styleUrls: ['./main-header-new.component.scss']
})
export class MainHeaderNewComponent implements OnInit {

  @Input() loginLink: string = '';
  @Input() signupLink: string = '';
  @Input() userInfoSubject?: BehaviorSubject<IUserInfo | null>;
  @Input() unReadNotificationCount: number = 0;
  @Input() numberOfUnreadMessages: number = 0;
  @Input() loyaltyInfo?: ILoyaltyInfo;
  @Input() removeHeader?: boolean;
  @Input() isB2bUser: boolean = false;
  @Output() onMobileMenuToggle: EventEmitter<boolean> = new EventEmitter();
  @Output() onMobileSearchOpen: EventEmitter<boolean> = new EventEmitter();
  @Output() closeGtmRibbon: EventEmitter<boolean> = new EventEmitter();
  @Output() openGtmRibbon: EventEmitter<boolean> = new EventEmitter();
  @Output() campaignRibbonOpen: EventEmitter<boolean> = new EventEmitter();
  @Output() campaignRibbonClosed: EventEmitter<boolean> = new EventEmitter();

  userInfo: IUserInfo | null = null;
  currentTheme: THEMES;
  numberOfCartItems: number = 0;
  // numberOfUnreadMessages: number = 0;
  openSearchResponsive: boolean = false;
  gtmRibbonToggleCount: number = 0;
  gtmRibbonClosed?: boolean;
  isCampaignRibbonClosed?: boolean;
  updatedImage: string | undefined = undefined;
  isDesktopMenuOpened: boolean = false;
  THEMES = THEMES;

  private currentWindowWidth: number;
  private subscriptions: Subscription[] = [];

  constructor(private filesManager: FilesManagerService,
              private themeService: ThemeControlService,
              private cartService: UserCartService, private chatService: ChatService,
              private userManagerService: UserManagerService, private languageControl: LanguageControlService,
              @Inject(PLATFORM_ID) private platformId: any,
              public customContentService: CustomContentService,
              public themeControl: ThemeControlService
              ) {
    this.currentWindowWidth = isPlatformBrowser(this.platformId) ? window.innerWidth : 0;
    this.languageControl.setTranslations('ar', ar);
    this.languageControl.setTranslations('en', en);
  }

  ngOnInit(): void {
    if (this.userInfoSubject) {
      let userSub = this.userInfoSubject.subscribe((userInfo) => {
        this.userInfo = userInfo;
        if (this.userInfo) {
          // this.getCartItemCount();
          // this.getUnreadMessagesCount();
          this.setGtmRibbonToggleCount();
          this.showCampaignRibbon();
        }
      });
      this.subscriptions.push(userSub);
    } else {
      this.userInfo = null;
    }
    // let cartUpdatedSub = this.cartService.cartUpdated.subscribe(() => {
    //   this.getCartItemCount();
    // });
    // this.subscriptions.push(cartUpdatedSub);
    let userImageSub = this.userManagerService.userImage.subscribe((image) => {
      this.updatedImage = image;
    });
    this.subscriptions.push(userImageSub);
  }

  private getCartItemCount() {
    if (this.userInfo) {
      this.cartService.getCartItemNumber().then((count) => {
        this.numberOfCartItems = count.numberOfCart;
      });
    }
  }

  private setGtmRibbonToggleCount() {
    if (this.loyaltyInfo && this.userInfo) {
      let toggleCount: string | null = null;
      if (isPlatformBrowser(this.platformId)) {
        toggleCount = localStorage.getItem(`${this.userInfo.uuid}-loyal`);
      }
      if (this.loyaltyInfo.loyal) {
        if (toggleCount) {
          this.gtmRibbonToggleCount = parseInt(toggleCount);
          this.openGtmRibbon.emit(true);
          if (this.gtmRibbonToggleCount >= 5) {
            this.openGtmRibbon.emit(false);
          }
        } else {
          if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem(`${this.userInfo.uuid}-loyal`, this.gtmRibbonToggleCount.toString());
          }
          this.openGtmRibbon.emit(true);
        }
      }
    }
  }

  private showCampaignRibbon() {
    if ((this.userInfo && !this.userInfo.subscribed && this.userInfo.userType !== 2) || !this.userInfo) {
      this.campaignRibbonOpen.emit(true);
    } else {
      this.campaignRibbonClosed.emit(true);
    }
  }

  // private getUnreadMessagesCount() {
  //   if (this.userInfo) {
  //     this.chatService.getUnreadMsg("main-header", true).then((unreadMessages) => {
  //       this.numberOfUnreadMessages = unreadMessages.count;
  //     });
  //   }
  // }
  getProfileImage(userId: string) {
    return this.filesManager.getHashedImage(`user-${userId}-profile`);
  }

  menuToggle(isProfile: boolean) {
    this.onMobileMenuToggle.emit(isProfile);
  }

  closeMobileSearch() {
    this.openSearchResponsive = false;
  }

  openMobileSearch($event: MouseEvent) {
    this.openSearchResponsive = true;
    this.onMobileSearchOpen.emit();
  }

  @HostListener('window:resize')
  onWindowResize() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.currentWindowWidth != window.innerWidth) {
        this.currentWindowWidth = window.innerWidth;
        this.closeMobileSearch();
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map((sub) => {
      sub.unsubscribe();
    });
  }

  onClosingGtmRibbon($event: boolean) {
    this.gtmRibbonClosed = $event;
    this.closeGtmRibbon.emit($event);
    if (this.gtmRibbonToggleCount >= 0 && this.gtmRibbonToggleCount < 5) {
      this.gtmRibbonToggleCount += 1;
      if (this.userInfo) {
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem(`${this.userInfo.uuid}-loyal`, this.gtmRibbonToggleCount.toString());
        }
      }
    }
  }

  updateUnreadMessages() {
    this.chatService.getUnreadMsg('user-avatar', true).then((unreadMessages) => {
      this.numberOfUnreadMessages = unreadMessages.count;
    });
  }

  onClosingCampaignRibbon($event: boolean) {
    this.isCampaignRibbonClosed = $event;
    this.campaignRibbonClosed.emit($event);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loyaltyInfo) {
      this.loyaltyInfo = changes.loyaltyInfo.currentValue;
      if (this.loyaltyInfo?.loyal) {
        this.setGtmRibbonToggleCount();
      }
    }
    if (!this.isCampaignRibbonClosed) {
      this.showCampaignRibbon();
    }
  }


  onDesktopMenuToggle(isOpened: boolean) {
    this.isDesktopMenuOpened = isOpened;
  }
}
