import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {IUserInfo} from '@interfaces/authorized-user/user.interface';
import {IUserPrivileges} from '@interfaces/privileges/privileges.interface';
import {ActivatedRoute, ActivatedRouteSnapshot, Params} from '@angular/router';
import {ConnectorV2Service} from '@services/connector/connector-v2.service';
import {AuthV2Service, IAuthorizedResponse} from '@services/integrations/auth-v2/auth-v2.service';
import {PrivilegesControlService} from '@services/privileges/privileges-control.service';
import {MarketingService} from '@services/integrations/marketing/marketing.service';
import {ConversionStrings} from '@interfaces/marketing/marketing.interface';
import {filter, take} from 'rxjs/operators';
import {CustomHttpService} from '@services/request-token/custom-http.service';
import {environment} from '@environments/environment';
import {IEnvironmentPlatforms, IUserPlatform} from '@interfaces/user/user-common.interface';
import {EUserType} from '@interfaces/common/connector.interface';
import {TrackingDataService} from "@services/tracking/tracking-data.service";
import {UrlUtils} from '@utilities/url.utils';


export interface IUserAuthorizedResponse {
  authorized: boolean,
  reachedDevicesLimit: boolean,
  isAllBlocked?: boolean,
  firstAuthAfterSubscription: boolean,
  name?: string,
}

@Injectable({
  providedIn: 'root'
})
export class UserManagerService {

  userInfo: BehaviorSubject<IUserInfo | null> = new BehaviorSubject<IUserInfo | null>(null);
  userImage: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined)
  userPrivileges: BehaviorSubject<IUserPrivileges | null> = new BehaviorSubject<IUserPrivileges | null>(null);
  userPlatform: string | null = null;
  isFirstLogin: BehaviorSubject<{ loaded: boolean, isFirstLogin: boolean }> = new BehaviorSubject<{ loaded: boolean, isFirstLogin: boolean }>({
    isFirstLogin: false,
    loaded: false
  });

  constructor(
    private connectorService: ConnectorV2Service,
    private auth2Service: AuthV2Service,
    private privilegesControl: PrivilegesControlService,
    private marketingService: MarketingService,
    private route: ActivatedRoute,
    private customHttpService: CustomHttpService,
    private trackingData: TrackingDataService,
    ) { }

  async checkUserAuthorization(route: ActivatedRouteSnapshot, platform: string | null, update?: boolean): Promise<IUserAuthorizedResponse> {
     let authDefault: IUserAuthorizedResponse = {
      authorized: false,
      reachedDevicesLimit: false,
      firstAuthAfterSubscription: false,
    }
    let currentUser = this.userInfo.getValue();
    if (currentUser && !update) {
      return {
        ...authDefault,
        authorized: true,
        name: currentUser.name,
      };
    } else {
      // routeUUID mainly used for futureX integration to auth user with uuid from saml redirect after server set the cookies
      const routeUUID = route.queryParams.uuid;
      if (routeUUID) this.setConnectorUserUUID(routeUUID);
      let localUser = this.connectorService.connectorUser.getValue();
      if (localUser?.uuid) {
        try {
          const authResponse = await this.auth2Service.authorize('authorized', platform, platform ? null : location.hostname.split('.')[0]);
          this.customHttpService.platformCode = authResponse.platform;
          return await this.checkIfUserLoggedIn(authResponse, currentUser);
        } catch (e) {
          return authDefault;
        }
      } else {
        return authDefault;
      }
    }
  }

  getB2COrB2BUserPlatform(userPlatforms: IUserPlatform[], redirectSubdomain?: string) :IUserPlatform | undefined {
    const environmentPlatforms: { [key: string]: IEnvironmentPlatforms } = environment.platforms;
    const standardPlatforms = Object.keys(environmentPlatforms).map(platformKey => {
      const platform = environmentPlatforms[platformKey];
      return {
        id: platformKey,
        code: platform.code,
        link: platform.link,
      }
    });
    const platformsExceptB2C = standardPlatforms.filter(p => p.id !== 'b2c');

    if (redirectSubdomain) {
      const isB2CSubdomain = this.isB2CUser(redirectSubdomain);
      const isB2BSubdomain = !platformsExceptB2C.some(platform => UrlUtils.getSubDomain(platform.link) === redirectSubdomain);
      if (!isB2CSubdomain && !isB2BSubdomain) return;
    }

    return userPlatforms.find(userPlatform => {
      return userPlatform.platform === environment.platforms.b2c.code
        || platformsExceptB2C.every(p => p.code !== userPlatform.platform)
    });
  }


  private addUserFromAd() {
    let queryParams$: Observable<Params>;
    // condition to catch Query params only one time
    if (window.location.search) {
      queryParams$ = this.route.queryParams.pipe(filter(p =>  !!Object.keys(p).length), take(1));
    } else {
      queryParams$ = this.route.queryParams.pipe(take(1));
    }
    queryParams$.subscribe((params: Params) => {
      const conversionStrings = Object.values(ConversionStrings);
      if (Object.keys(params).some(p => conversionStrings.includes(p as ConversionStrings))) {
        this.marketingService.createAdConversionCookie(params);
      }
      let adSourceInfo = this.marketingService.checkForAdConversionCookie();
      if (adSourceInfo) {
        this.marketingService.addUserFromAd('user-service', JSON.parse(adSourceInfo)).then((data) => {
          this.marketingService.deleteAdConversionCookie();
        }).catch((e) => {
        })
      } else {
        this.marketingService.getUserAd('user-service');
      }
    });
  }

  private async checkIfUserLoggedIn(authorizedResponse: IAuthorizedResponse, currentUser: IUserInfo | null): Promise<IUserAuthorizedResponse> {
    const userAuthorizedResponse: IUserAuthorizedResponse = {
      authorized: true,
      reachedDevicesLimit: authorizedResponse.isReachedMaxDevices,
      isAllBlocked: authorizedResponse.isUserBlockAll,
      firstAuthAfterSubscription: authorizedResponse.firstAuthAfterSubscription,
    }
    this.setUserPrivileges(authorizedResponse.privilege);
    if (authorizedResponse.isLogged && !authorizedResponse.forceLogout) {
      this.userPlatform = authorizedResponse.platform;
      this.addUserFromAd();
      if (currentUser) {
        return {
          ...userAuthorizedResponse,
          name: currentUser.name
        };
      } else {
        return await this.setUserInfo(authorizedResponse);
      }
    } else {
      if (authorizedResponse.forceLogout) {
        await this.auth2Service.logUserOut().toPromise();
        this.connectorService.removeUser();
      }
      return userAuthorizedResponse;
    }
  }

  private setUserInfo(authorizedResponse: IAuthorizedResponse): Promise<IUserAuthorizedResponse> {
    return new Promise<IUserAuthorizedResponse>(resolve => {
      this.auth2Service.getUserInfo('auth')
        .then((userInfo) => {
          if (userInfo) {
            userInfo.cuuid = userInfo.uuid;
            userInfo.subscribed = userInfo.subscriptionStatus == 2 || userInfo.subscriptionStatus == 3;
            userInfo.firstAuthAfterSubscription = authorizedResponse.firstAuthAfterSubscription;
            this.userInfo.next(userInfo);
            this.isFirstLogin.next({loaded: true, isFirstLogin: authorizedResponse.isFisrtLogin});
            resolve({
              authorized: true,
              reachedDevicesLimit: authorizedResponse.isReachedMaxDevices,
              isAllBlocked: authorizedResponse.isUserBlockAll,
              firstAuthAfterSubscription: authorizedResponse.firstAuthAfterSubscription,
              name: userInfo.name,
            });
            this.setTrackingMetadata(userInfo);
          } else {
            resolve({authorized: false, reachedDevicesLimit: false, firstAuthAfterSubscription: false});
          }
        }).catch(() => {
        resolve({authorized: false, reachedDevicesLimit: false, firstAuthAfterSubscription: false});
      })
    });
  }

  private setUserPrivileges(privilegesToken: string) {
    this.userPrivileges.next(this.privilegesControl.getUserPrivileges(privilegesToken));
  }

  setConnectorUserUUID(routeUUID: string) {
    const user = {
      uuid: routeUUID,
      type: this.customHttpService.platformCode === environment.platforms.b2c.code? EUserType.B2C:  EUserType.B2B,
      link: window.location.href
    }
    this.connectorService.addUser(user);
    this.connectorService.connectorUser.next(user)
  }

  private setTrackingMetadata(userInfo: IUserInfo) {
    this.trackingData.setEventMetaData({
      user_uuid: userInfo.uuid,
    })
  }

  private isB2CUser(redirectSubdomain: string) {
    return redirectSubdomain === UrlUtils.getSubDomain(environment.platforms.anonymous.link)
    || redirectSubdomain === UrlUtils.getSubDomain(environment.platforms.b2c.link)
  }
}
