import {Injectable} from '@angular/core';
import {IUserInfo} from '@interfaces/authorized-user/user.interface';
import {LanguageControlService} from '@services/language/language-control.service';
import {IDemographics, IInterest} from '@interfaces/common/auth.interface';
import {UserHttpService} from '@services/integrations/user/user-http.service';
import {
  IAccountInfo,
  IB2bBannerInfo,
  ILoyaltyInfo,
  IPersonalInfo,
  IPurchaseLog,
  ISocialMediaProfiles,
  IUserPlatform
} from '@interfaces/user/user-common.interface';
import {UserSubscriptionPlan} from '@interfaces/subscription/subscription.interface';
import {LanguageId} from "@interfaces/common/language.interface";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  plan: { [key: number]: UserSubscriptionPlan } = {};
  loyaltyInfo?: ILoyaltyInfo;
  b2bUserFreeCap: number = 0;
  b2bUserFlatRate: number = 0;

  constructor(private httpUser: UserHttpService, private languageControl: LanguageControlService) {
  }

  saveUserInterests(sender: string, interests: number[]): Promise<{ result: boolean }> {
    return this.httpUser.saveUserInterests(sender, interests);
  }

  getAllInterestsByLanguageId(sender: string): Promise<IInterest[]> {
    let currentLanguageId = this.languageControl.currentLanguage.getValue().id;
    return this.httpUser.getAllInterestsByLanguageId(sender, currentLanguageId);
  }


  saveUserDemographics(sender: string, demographics: IDemographics): Promise<any> {
    return this.httpUser.saveUserDemographics(sender, demographics);
  }

  getUserInfo(): Promise<IUserInfo> {
    return this.httpUser.getUserInfo();
  }

  getPersonalInfo(sender: string): Promise<IPersonalInfo> {
    return this.httpUser.getPersonalInfo(sender);
  }

  getSocialMediaProfiles(sender: string): Promise<ISocialMediaProfiles[]> {
    return this.httpUser.getSocialMediaProfiles(sender);
  }

  getAccountInfo(sender: string): Promise<IAccountInfo> {
    return this.httpUser.getAccountInfo(sender);
  }

  updatePersonalInfo(sender: string, payload: IPersonalInfo): Promise<{result: boolean}> {
    return this.httpUser.updatePersonalInfo(sender, payload);
  }

  updateSocialMediaProfiles(sender: string, socialProfiles: { channelUrl: string }[]) {
    return this.httpUser.updateSocialMediaProfiles(sender, socialProfiles);
  }

  getUserSubscriptionPlan(sender: string, shouldRefresh?: boolean, forceSpecificLanguage?: LanguageId): Promise<UserSubscriptionPlan> {
    return new Promise<UserSubscriptionPlan>((resolve, reject) => {
      let currentLanguageId = forceSpecificLanguage ?? this.languageControl.currentLanguage.getValue().id;
      if (this.plan[currentLanguageId] && !shouldRefresh) {
        resolve(this.plan[currentLanguageId]);
      } else {
        this.httpUser.getUserSubscriptionPlan(sender, currentLanguageId).then(plan => {
          this.plan[currentLanguageId] = plan;
          resolve(this.plan[currentLanguageId]);
        }).catch((e) => reject(e));
      }
    });
  }

  getUserPurchaseLogOrders(sender: string, languageId: number): Promise<IPurchaseLog[]> {
    return this.httpUser.getUserPurchaseLogOrders(sender, languageId);
  }

  changeMobileNumber(sender: string, phoneNumber: string,
                     userInfo?: { email?: string, password?: string, userType: number, accessToken?: string } | {}): Promise<{ result: boolean }> {
    let changeMobileNumberBody: any = {
      mobileNumber: phoneNumber
    };
    if (userInfo) {
      changeMobileNumberBody = {
        ...changeMobileNumberBody,
        userData: {...userInfo}
      }
    }
    return this.httpUser.changeMobileNumber(sender, changeMobileNumberBody);
  }

  sendPinCode(sender: string, isResend: boolean, receiver?: string,
              userInfo?: { email?: string, password?: string, userType: number, accessToken?: string } | {}): Promise<{ result: boolean }> {
    let sendPinCodeObject: any = {
      isResend: isResend
    }
    if (userInfo) {
      sendPinCodeObject['userData'] = {...userInfo};
    } else {
      sendPinCodeObject['reciever'] = receiver;
    }
    return this.httpUser.sendPinCode(sender, sendPinCodeObject);
  }

  verifyEmailPinCode(sender: string, activationCode: number,
                     userInfo?: { email?: string, password?: string, userType: number, accessToken?: string } | {}): Promise<{ result: boolean }> {
    let verifyCodeObject: {
      pinCode: number,
      userData?: { email?: string, password?: string, userType: number, accessToken?: string } | {}
    } = {
      pinCode: activationCode
    }
    if (userInfo) {
      verifyCodeObject['userData'] = {...userInfo};
    }
    return this.httpUser.verifyEmailPinCode(sender, verifyCodeObject);
  }

  activateMobileNumber(sender: string, activationCode: number): Promise<{ result: boolean }> {
    return this.httpUser.activateMobileNumber(sender, activationCode);
  }

  getLoyaltyInfo(): Promise<ILoyaltyInfo> {
    return this.httpUser.getLoyaltyInfo();
  }

  isUserLoyal(): Promise<ILoyaltyInfo> {
    return this.getLoyaltyInfo().then((loyal) => {
      this.loyaltyInfo = loyal;
      return loyal;
    });
  }

  /**
   * @deprecated
   * It is deprecated because it is belonged to old b2b before SSO
   */
  getB2bBannerInfo(languageId?: number): Promise<IB2bBannerInfo> {
    let currentLanguageId = languageId ? languageId : this.languageControl.currentLanguage.getValue().id;
    return this.httpUser.getB2bBannerInfo(currentLanguageId).then((bannerInfo) => {
      this.b2bUserFreeCap = bannerInfo.userCap;
      this.b2bUserFlatRate = bannerInfo.flatRate;
      return bannerInfo;
    })
  }

  getUserPlatforms(): Promise<IUserPlatform[]> {
    return this.httpUser.getUserPlatforms();
  }
}
