import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  UserSettingsChangePlanDialogComponent
} from '@pages/user-settings-page/components/user-settings-change-plan-dialog/user-settings-change-plan-dialog.component';
import {TranslateModule} from '@ngx-translate/core';
import {AlmTranslateCutModule} from '@alm-translate-cut/alm-translate-cut.module';
import {MatDialogModule} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { SkeletonPlaceholderModule } from '@components/skeleton-placeholder/skeleton-placeholder.module';
import { InlineLoaderModule } from '@directives/inline-loader/inline-loader.module';
import {
  ApplyCouponFormComponent
} from '@components/apply-coupon-form/apply-coupon-form.component';



@NgModule({
  declarations: [
    UserSettingsChangePlanDialogComponent
  ],
  exports: [
    UserSettingsChangePlanDialogComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    AlmTranslateCutModule,
    MatDialogModule,
    MatIconModule,
    SkeletonPlaceholderModule,
    InlineLoaderModule,
    ApplyCouponFormComponent
  ]
})
export class UserSettingsChangePlanDialogModule { }
