import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderSearchComponent} from './header-search/header-search.component';
import {MatIconModule} from '@angular/material/icon';
import {HeaderNavMenuComponent} from './header-nav-menu/header-nav-menu.component';
import {UserControlMenuComponent} from './user-control-menu/user-control-menu.component';
import {RouterModule} from "@angular/router";
import {TranslateModule} from '@ngx-translate/core';
import {AlmTranslateCutModule} from '@alm-translate-cut/alm-translate-cut.module';
import {MatMenuModule} from '@angular/material/menu';
import {CoursesMenuModule} from '@components/courses-menu/courses-menu.module';
import {ImageLoaderModule} from "@directives/image-loader/image-loader.module";
import {HeaderUserMenuComponent} from './header-user-menu/header-user-menu.component';
import {HeaderMobileMenuComponent} from './header-mobile-menu/header-mobile-menu.component';
import {HeaderSearchBoxHistoryComponent} from './header-search-box-history/header-search-box-history.component';
import {HeaderSearchBoxSuggestionsComponent} from './header-search-box-suggestions/header-search-box-suggestions.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputAutofocusModule} from "@directives/mat-input-autofocus/mat-input-autofocus.module";
import {HeaderNotificationMenuComponent} from "@components/header/header-notification-menu/header-notification-menu.component";
import {HeaderNotificationMenuCardComponent} from "@components/header/header-notification-menu-card/header-notification-menu-card.component";
import {HeaderNotificationMenuDiscussionCardComponent} from './header-notification-menu-discussion-card/header-notification-menu-discussion-card.component';
import {HeaderNotificationMenuAnnouncementCardComponent} from './header-notification-menu-announcement-card/header-notification-menu-announcement-card.component';
import { MatButtonModule} from "@angular/material/button";
import { HeaderNotificationMenuSystemCardComponent } from './header-notification-menu-system-card/header-notification-menu-system-card.component';
import {MatDividerModule} from '@angular/material/divider';
import { HeaderNotificationMenuOrganizationNoteCardComponent } from './header-notification-menu-organization-note-card/header-notification-menu-organization-note-card.component';
import {CoursesMenuV2Module} from "@components/courses-menu-v2/courses-menu-v2.module";
import {
  UserSettingsChangePlanDialogModule
} from '@pages/user-settings-page/components/user-settings-change-plan-dialog/user-settings-change-plan-dialog.module';
import {ConfirmationChangePlanDialogModule} from '@components/confirmation-change-plan-dialog/confirmation-change-plan-dialog.module';
import {CustomHrefDirective} from '@directives/custom-href/custom-href.directive';

@NgModule({
  declarations: [
    HeaderSearchComponent,
    HeaderNavMenuComponent,
    UserControlMenuComponent,
    HeaderUserMenuComponent,
    HeaderMobileMenuComponent,
    HeaderSearchBoxHistoryComponent,
    HeaderSearchBoxSuggestionsComponent,
    HeaderNotificationMenuComponent,
    HeaderNotificationMenuCardComponent,
    HeaderNotificationMenuDiscussionCardComponent,
    HeaderNotificationMenuAnnouncementCardComponent,
    HeaderNotificationMenuSystemCardComponent,
    HeaderNotificationMenuOrganizationNoteCardComponent
  ],
  exports: [
    HeaderNavMenuComponent,
    HeaderSearchComponent,
    UserControlMenuComponent,
    HeaderMobileMenuComponent,
    HeaderUserMenuComponent,
    HeaderNotificationMenuComponent,
    HeaderNotificationMenuCardComponent,
    HeaderNotificationMenuDiscussionCardComponent,
    HeaderNotificationMenuAnnouncementCardComponent,
    HeaderNotificationMenuSystemCardComponent,
    HeaderNotificationMenuOrganizationNoteCardComponent
  ],
    imports: [
        CommonModule,
        MatIconModule,
        RouterModule,
        TranslateModule,
        AlmTranslateCutModule,
        MatMenuModule,
        CoursesMenuModule,
        ImageLoaderModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatInputAutofocusModule,
        MatButtonModule,
        MatDividerModule,
        CoursesMenuV2Module,
        UserSettingsChangePlanDialogModule,
        ConfirmationChangePlanDialogModule,
        CustomHrefDirective,
    ]
})
export class HeaderModule {
}
