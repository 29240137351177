import {Injectable} from '@angular/core';
import {EPaymentStatusId} from "@interfaces/payment-website/payment-status.interface";
import {TrackingPaymentStatus} from "@services/tracking/tracking.interface";
import {OrderStatus, OrderStatusEnum} from '@interfaces/shopping-cart/shopping-cart-list.interface';

interface SubscriptionPlan {
    id: number | string;
    name: string;
    newPlanId: string;
}

const MONTHLY_PLAN_ID = 'b364eb8d-54c9-4232-8fd1-2f6d3bd88fb4';
const YEARLY_PLAN_ID = 'c3f5ac5f-09b0-4602-b2b3-85a311f3d7b7';
const QUARTERLY_PLAN_ID = '803cd10d-7fa9-40f1-861b-1eaacdb5b8be';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionMarketingService {

    subscriptionPlans: SubscriptionPlan[] = [
        {id: 1, name: 'Egypt Monthly Plan', newPlanId: MONTHLY_PLAN_ID},
        {id: 2, name: 'Egypt Yearly Plan', newPlanId: YEARLY_PLAN_ID},
        {id: 3, name: 'Rest of countries Monthly Plan', newPlanId: MONTHLY_PLAN_ID},
        {id: 4, name: 'Rest of countries Yearly Plan', newPlanId: YEARLY_PLAN_ID},
        {id: 5, name: 'UAE Monthly Plan', newPlanId: MONTHLY_PLAN_ID},
        {id: 6, name: 'UAE Yearly Plan', newPlanId: YEARLY_PLAN_ID},
        {id: 7, name: 'Saudi Arabia Monthly Plan', newPlanId: MONTHLY_PLAN_ID},
        {id: 8, name: 'Saudi Arabia Yearly Plan', newPlanId: YEARLY_PLAN_ID},
        {id: 9, name: 'Qatar Monthly Plan', newPlanId: MONTHLY_PLAN_ID},
        {id: 10, name: 'Qatar Yearly Plan', newPlanId: YEARLY_PLAN_ID},
        {id: 11, name: 'Egypt Monthly Plan', newPlanId: MONTHLY_PLAN_ID},
        {id: 12, name: 'Egypt Yearly Plan', newPlanId: YEARLY_PLAN_ID},
        {id: 13, name: 'UAE Monthly Plan', newPlanId: MONTHLY_PLAN_ID},
        {id: 14, name: 'UAE Yearly Plan', newPlanId: YEARLY_PLAN_ID},
        {id: 15, name: 'Saudi Arabia Monthly Plan', newPlanId: MONTHLY_PLAN_ID},
        {id: 16, name: 'Saudi Arabia Yearly Plan', newPlanId: YEARLY_PLAN_ID},
        {id: 17, name: 'Qatar Monthly Plan', newPlanId: MONTHLY_PLAN_ID},
        {id: 18, name: 'Qatar Yearly Plan', newPlanId: YEARLY_PLAN_ID},
        {id: 19, name: 'Rest of countries Monthly Plan', newPlanId: MONTHLY_PLAN_ID},
        {id: 20, name: 'Rest of countries Yearly Plan', newPlanId: YEARLY_PLAN_ID},
        {id: 21, name: 'Egypt Monthly Plan', newPlanId: MONTHLY_PLAN_ID},
        {id: 22, name: 'Egypt Yearly Plan', newPlanId: YEARLY_PLAN_ID},
        {id: 23, name: 'UAE Monthly Plan', newPlanId: MONTHLY_PLAN_ID},
        {id: 24, name: 'UAE Yearly Plan', newPlanId: YEARLY_PLAN_ID},
        {id: 25, name: 'Saudi Arabia Monthly Plan', newPlanId: MONTHLY_PLAN_ID},
        {id: 26, name: 'Saudi Arabia Yearly Plan', newPlanId: YEARLY_PLAN_ID},
        {id: 27, name: 'Qatar Monthly Plan', newPlanId: MONTHLY_PLAN_ID},
        {id: 28, name: 'Qatar Yearly Plan', newPlanId: YEARLY_PLAN_ID},
        {id: 29, name: 'Rest of countries Monthly Plan', newPlanId: MONTHLY_PLAN_ID},
        {id: 30, name: 'Rest of countries Yearly Plan', newPlanId: YEARLY_PLAN_ID},
        {id: 31, name: 'Egypt Quarterly plan', newPlanId: QUARTERLY_PLAN_ID},
        // new subscription service plans
        {id: YEARLY_PLAN_ID, name: "Yearly Plan", newPlanId: YEARLY_PLAN_ID},
        {id: QUARTERLY_PLAN_ID, name: "Quarterly Plan", newPlanId: QUARTERLY_PLAN_ID},
        {id: MONTHLY_PLAN_ID, name: "Monthly Plan", newPlanId: MONTHLY_PLAN_ID},
    ];

    getSubscriptionPlanName(id: number | string): string {
        return this.subscriptionPlans.find(plan => plan.id === id)?.name || 'N/A';
    }

    getPaymentStatus(orderStatus: OrderStatus): TrackingPaymentStatus {
      switch (orderStatus) {
        case 'Paid':
          return 'Success';
        case 'PendingUserAction':
          return "Pending"
        default:
          return "Failed";
      }
    }
}
