import {Injectable} from '@angular/core';
import {DcbPaymentMethod, DcbPaymentOptions, SubscriptionPlans} from '@interfaces/subscription/subscription.interface';
import {CustomHttpService} from '@services/request-token/custom-http.service';
import {HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionHttpService {

  constructor(private customHttpService: CustomHttpService) {
  }

  getSubscriptionPlans(): Promise<SubscriptionPlans> {
    return this.customHttpService.getRequest({
      endpoint: 'plan-svc/plans',
      skipOnSSR: true,
    })
  }

  getDcbSubscriptionPlans(operator?: DcbPaymentMethod): Promise<DcbPaymentOptions[]> {
    let queryParams = new HttpParams();
    if (operator) {
      queryParams = queryParams.set('paymentMethod', operator);
    } else {
      queryParams = queryParams.set('useCallerCountry', true);
    }
    return this.customHttpService.getRequest({
      endpoint: 'plan-svc/plans/dcb',
      skipOnSSR: !operator,
      queryParams,
    })
  }

  enrollInCourse(courseId: number): Promise<{ result: any }> {
     return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/progress/api/course/enroll',
      sender: 'enroll',
      receiver: 'enroll',
      body: {
        courseId: courseId
      }
    })
  }

  updateFutureXEnrollment(sender: string, courseId: number): void {
    this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/futureX/api/userReporting/enrollmentProgress',
      sender: sender,
      receiver: 'enroll',
      body: {courseId}
    })
  }

  cancelPlan(reason: string): Promise<void> {
    return this.customHttpService.sendPutRequest({
      endpoint: 'subscription-management/v2/cancel-auto-renew',
      sender: 'settings',
      receiver: 'cancel',
      body: {
        reason: reason
      }
    })
  }

  changePlan(planId: number): Promise<{result: boolean}> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/updatSubscription/api/subscriptionMainUser/Change/Plan',
      sender: 'settings',
      receiver: 'plan',
      body: {
        entityId: planId
      }
    })
  }

  checkFreeTrialEligibility(phoneNumber?: string): Promise<void> {
    let queryParams = new HttpParams();
    if (phoneNumber) {
      queryParams = queryParams.set('phoneNumber', phoneNumber);
    }
      return this.customHttpService.getRequest({
        endpoint: 'subscription-management/check-free-trial-eligibility',
        queryParams,
      });
  }
}
