import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {LookupsService} from '@services/integrations/lookups/lookups.service';
import {ICategory, ISimplifiedCategory} from '@interfaces/course/category.interface';
import {Router} from '@angular/router';
import {LanguageControlService} from '@services/language/language-control.service';
import {ILanguage} from '@interfaces/common/language.interface';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ThemeControlService, THEMES} from '@services/theme/theme-control.service';
import {IUserInfo} from '@interfaces/authorized-user/user.interface';
import ar from './i18n/ar.json';
import en from './i18n/en.json';
import {GoogleTagManagerService} from '@services/marketing/google-tag-manager.service';
import {AmplitudeService} from '@services/marketing/amplitude.service';
import {environment} from '@environments/environment';
import {AppRedirectsService} from "@services/app-redirects/app-redirects.service";
import {CoursesService} from "@services/integrations/courses/courses.service";
import {BrazeUserAttributesService} from "@services/marketing/braze/events/braze-user-attributes.service";
import {BrazeCoursesEventsService} from "@services/marketing/braze/events/braze-courses-events.service";
import {ELanguage} from "@interfaces/common/connector.interface";
import { CustomContentService } from '@services/custom-content/custom-content.service';
import {DEFAULT_LOGIN_PATH} from '@constants/http.constants';

enum CurrentView {
  mainView = 1,
  coursesListView,
  b2bCategoriesView
}

@Component({
  selector: 'alm-root-header-mobile-menu',
  templateUrl: './header-mobile-menu.component.html',
  styleUrls: ['./header-mobile-menu.component.scss']
})
export class HeaderMobileMenuComponent implements OnInit {


  allCategories: ISimplifiedCategory [] | undefined;
  allB2bCategories: ISimplifiedCategory[] = [];
  selectedCategoryId: number | undefined;
  nextLanguage: ILanguage | undefined;
  languages: ILanguage[] = [];
  currentLanguage: ILanguage;
  currentTheme: THEMES;
  userInfo: IUserInfo | null = null;

  @Input() currentView: CurrentView = CurrentView.mainView;
  @Input() loginLink: string = '';
  @Input() signupLink: string = '';
  @Input() isB2bUser: boolean = false;
  @Input() userInfoSubject?: BehaviorSubject<IUserInfo | null>;
  @Input() organizationNames?: {nameAr: string, nameEn: string};
  private subscriptions: Subscription[] = [];
  @Output() onMobileMenuToggle: EventEmitter<boolean> = new EventEmitter();
  @Output() onItemSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() onViewChange: EventEmitter<CurrentView> = new EventEmitter<CurrentView>();

  constructor(private lookupsService: LookupsService, private router: Router, private languageControl: LanguageControlService,
              private themeControl: ThemeControlService, private gtmService: GoogleTagManagerService,
              private amplitudeService: AmplitudeService,
              private appRedirects: AppRedirectsService,
              private brazeCoursesEventsService: BrazeCoursesEventsService,
              private brazeUserAttributesService: BrazeUserAttributesService,
              private coursesService: CoursesService,
              public customContentService: CustomContentService) {
    this.languageControl.setTranslations('ar', ar);
    this.languageControl.setTranslations('en', en);
    this.currentLanguage = this.languageControl.currentLanguage.getValue();
    this.currentTheme = this.themeControl.currentTheme.getValue();
  }

  ngOnInit(): void {
    this.languageControl.getAllLanguages().then((languages) => {
      this.languages = languages;
      this.getNextLanguage(this.currentLanguage);
    });
    let langSub = this.languageControl.currentLanguage.subscribe((currentLanguage) => {
      this.currentLanguage = currentLanguage;
      this.getNextLanguage(currentLanguage);
    });
    this.subscriptions.push(langSub);
    let themeSub = this.themeControl.currentTheme.subscribe((currentTheme) => {
      this.currentTheme = currentTheme;
    });
    this.subscriptions.push(themeSub);
    if (this.userInfoSubject) {
      let userInfoSub = this.userInfoSubject.subscribe((userInfo) => {
        this.userInfo = userInfo;
      });
      this.subscriptions.push(userInfoSub);
    }
  }

  switchToCoursesListView() {
    this.currentView = CurrentView.coursesListView;
    this.onViewChange.emit(this.currentView);
    this.lookupsService.getAllCategories('header', 1).then(allCategories => {
      this.allCategories = allCategories;
    });
  }

  switchToB2bCategoriesView() {
    this.currentView = CurrentView.b2bCategoriesView;
    this.onViewChange.emit(this.currentView);
    this.coursesService.getB2BCategories("header", {
      page: 0, size: 1000
    }).then((b2bCategories) => {
      this.allB2bCategories = b2bCategories.items;
    })
  }

  switchToMainView() {
    this.currentView = CurrentView.mainView;
    this.onViewChange.emit(this.currentView);
  }

  handleNavigationToCategory(category: ISimplifiedCategory) {
    this.router.navigate(['/categories/' + category.permanentLink]).then(() => {
      this.switchToMainView();
      this.brazeCoursesEventsService.categoryClick(category.id);
      this.onItemSelect.emit();
    });
  }

  onLanguageChange(language: ELanguage): void {
    this.languageControl.changeLanguageAndRedirect(language);
    this.itemClicked();
    this.brazeUserAttributesService.setUserLanguage(language);
    this.amplitudeService.languageChangedEvent({
      Website_Language_Changed_Language: language === 'ar' ? 'Arabic' : 'English'
    });
  }


  switchToLightTheme(): void {
    let theme = THEMES.LIGHT;
    this.themeControl.changeTheme(theme);
    this.currentTheme = THEMES.LIGHT;
    this.itemClicked();
    this.brazeUserAttributesService.setUserTheme(theme);
    this.gtmService.sendCustomEvent('THEME-CHANGED', {changedTo: 'DARK'});
    this.amplitudeService.themeChangedEvent({
      Website_Theme_Changed_Theme: 'LIGHT'
    });
  }

  switchToDarkTheme(): void {
    let theme = THEMES.DARK;
    this.themeControl.changeTheme(theme);
    this.currentTheme = THEMES.DARK;
    this.itemClicked();
    this.brazeUserAttributesService.setUserTheme(theme);
    this.gtmService.sendCustomEvent('THEME-CHANGED', {changedTo: 'DARK'});
    this.amplitudeService.themeChangedEvent({
      Website_Theme_Changed_Theme: 'DARK'
    });
  }

  getNextLanguage(currentLanguage: ILanguage): void {
    this.languages.map((language: ILanguage) => {
      if (language.code != currentLanguage.code) {
        this.nextLanguage = language;
      }
    });
  }

  itemClicked() {
    this.onItemSelect.emit();
  }

  ngOnDestroy(): void {
    this.subscriptions.map((sub) => {
      sub.unsubscribe();
    });
  }

  onNavigationToSignUp() {
    this.appRedirects.redirectToSSO(DEFAULT_LOGIN_PATH, {ref: 'header_signup_button'});
  }

  navigateToLogin() {
    this.appRedirects.redirectToSSO(DEFAULT_LOGIN_PATH, {ref: 'header_login_button'});
  }


}
