import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {NavigationInfo} from "@services/routing/routing-helper/routing.interface";
import {TitleService} from "@services/title/title.service";

@Injectable({
  providedIn: 'root'
})
export class NavigationTrackerService {
  private previousNavigation: NavigationInfo = { url: undefined, title: undefined };
  private currentNavigation: NavigationInfo = { url: undefined, title: undefined };

  constructor(
    private router: Router,
    private titleService: TitleService,
  ) {
    this.currentNavigation.url = this.router.url;
    this.currentNavigation.title = this.titleService.getTitle();

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        this.previousNavigation = { ...this.currentNavigation };
        this.currentNavigation.url = (event as NavigationEnd).urlAfterRedirects;
        this.currentNavigation.title = 'fetching...';
      });

    this.titleService.title$.subscribe(title => {
      this.currentNavigation.title = title;
    });
  }

  public getPreviousNavigation(): NavigationInfo {
    return this.previousNavigation;
  }

  public getCurrentNavigation(): NavigationInfo {
    return this.currentNavigation;
  }
}
