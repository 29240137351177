<div class="main-dialog-container" mat-dialog-content *ngIf="currentPlan && plans">
  <div class="dialog-header">
    <div class="dialog-title">
      <h3>{{'settings_change_plan_dialog.title' | translate}}</h3>
    </div>
    <div class="dialog-close">
      <button type="button" (click)="closeDialog()" class="btn-alm-close">
        <svg aria-hidden="true">
          <use href="assets/images/sprite.svg#times" />
        </svg>
      </button>
    </div>
  </div>
  <div class="dialog-content">
    <div *ngIf="userInfo && userInfo?.subscribed && userInfo?.subscriptionStatus != 3"
      class="dialog-change-subscription-content">
      <div class="main-container">
        <div>{{ 'settings_change_plan_dialog.current_plan' | translate }}:</div>
        <div class="plan-container current-plan-container">
          <div class="title">{{ currentPlan.name }} <span class="tag">ACTIVE</span></div>
          <div class="plan-details">
            <span class="text-secondary-color">
              {{ currentPlan.amountPerMonth }} {{currentPlan.currency}}/{{'settings_change_plan_dialog.month' |
              translate}}
              <span class="current-plan-end-date">
                ends on {{currentPlan.renewDate | date : 'MMMM d, y'}}
              </span>
            </span>
          </div>
        </div>

        <div class="text-margin-top">{{ 'settings_change_plan_dialog.switch_to' | translate }}:</div>
        <div *ngFor="let plan of plans" class="plan-container" [ngClass]="{'selectedPlan': nextPlan.id === plan.id}"
          (click)="changePlan(plan)">
          <div class="title-wrapper">
            <div class="title">{{ plan.name }} <span class="tag">Save 50%</span></div>
            <div *ngIf="nextPlan.id === plan.id" class="check-icon-container">
              <svg>
                <use href="assets/images/sprite.svg#check" />
              </svg>
            </div>
          </div>
          <div class="plan-details">

            <span *ngIf="plan?.message" class="promo text-secondary-color">{{plan.message}}</span>
            <span>
              {{ plan.amountPerMonth }} {{plan.currency}}
              <span class="text-secondary-color">
                /{{'settings_change_plan_dialog.month' | translate}}
              </span>
            </span>
            <span>
              <span class="text-secondary-color">
                {{plan.finalPrice}} {{plan.description}}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div class="vertical-separator"></div>
      <div class="details-container">
        <div *ngIf="!calculationLoader; else skeletonLoader">
          <div class="plan-details-title">{{ 'settings_change_plan_dialog.your_new_plan' | translate }}:</div>
          <ng-container *ngFor="let item of summaryDetails">
            <div class="items-row">
              <span>{{ item.name }}</span>
              <span>{{ item.price }} {{ item.currency }}</span>
            </div>
            <div *ngIf="item.hint" class="dialog-change-subscription-content__note text-secondary-color">
              {{item.hint}}
            </div>
          </ng-container>
          <ng-container *ngIf="showCouponField; else applyCoupontemplate">
            <div class="coupon-container">
              <alm-root-apply-coupon-form [selectedPlan]="nextPlan"></alm-root-apply-coupon-form>
            </div>
          </ng-container>
          <ng-template #applyCoupontemplate>
            <div class="button-container">
              <button class="btn btn-alm-outline" (click)="displayCouponField()">
                {{ 'settings_change_plan_dialog.apply_coupon' | translate }}
              </button>
            </div>
          </ng-template>


          <div class="total-paid-wrapper">
            <div class="items-row-border-top">
              <span>{{ 'settings_change_plan_dialog.total_due' | translate }}</span>
              <span>-{{ currentPlan.finalPrice }} {{ currentPlan.currency }}</span>
            </div>
            <div class="dialog-change-subscription-content__note">
              {{'settings_change_plan_dialog.note' | translate}}
            </div>
          </div>
        </div>
        <button class="btn btn-alm-filled action-button " (click)="confirmPlan()" [inlineLoader]="calculationLoader">
          {{'settings_change_plan_dialog.confirm' | translate}}
          <svg aria-hidden="true">
            <use *ngIf="currentLanguage === ELanguage.EN" href="assets/images/sprite.svg#arrow-forward" />
            <use *ngIf="currentLanguage === ELanguage.AR" href="assets/images/sprite.svg#arrow-backward" />
          </svg>
        </button>
      </div>
    </div>
    <div *ngIf="userInfo && userInfo?.subscribed && userInfo?.subscriptionStatus == 3"
      class="dialog-change-subscription-content">
      <div class="dialog-change-subscription-content__title">
        <h3>{{'settings_change_plan_dialog.grace_title' | translate}}</h3>
      </div>

      <div class="dialog-change-subscription-content__plan">
        <p>
          {{'settings_change_plan_dialog.grace_description' | translate}}
        </p>
      </div>

      <div class="dialog-change-subscription-content__grace-info">
        <p>{{'settings_change_plan_dialog.grace_info' | translate}}</p>
        <svg>
          <use href="assets/images/sprite.svg#info" />
        </svg>
      </div>

      <div class="dialog-change-subscription-content__footer">
        <button class="btn btn-alm-outline" (click)="closeDialog()">{{'settings_change_plan_dialog.buttons_text' |
          translate | translateCut:0}}</button>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <div class="confirm-plan-container">
      <ng-container *ngIf="!calculationLoader; else footerLoader">
        <div class="items-row-border-top">
          <span>{{ 'settings_change_plan_dialog.total_due' | translate }}</span>
          <span>-{{ currentPlan.finalPrice }} {{ currentPlan.currency }}</span>
        </div>
        <div class="dialog-change-subscription-content__note">
          {{'settings_change_plan_dialog.note' | translate}}
        </div>
      </ng-container>
      <ng-template #footerLoader>
        <alm-root-skeleton-placeholder [count]="2"></alm-root-skeleton-placeholder>
      </ng-template>

      <button class="btn btn-alm-filled action-button" (click)="confirmPlan()" [inlineLoader]="calculationLoader">
        {{'settings_change_plan_dialog.confirm' | translate}}
        <svg aria-hidden="true">
          <use *ngIf="currentLanguage === ELanguage.EN" href="assets/images/sprite.svg#arrow-forward" />
          <use *ngIf="currentLanguage === ELanguage.AR" href="assets/images/sprite.svg#arrow-backward" />
        </svg>
      </button>
    </div>
  </div>
</div>

<ng-template #skeletonLoader>
  <alm-root-skeleton-placeholder [count]="10"></alm-root-skeleton-placeholder>
</ng-template>
