import {inject, Injectable} from '@angular/core';
import {AuthDialogContainerComponent} from '@components/auth-dialog-container/auth-dialog-container.component';
import {UserManagerService} from '@services/user/user-manager.service';
import {DialogService} from '@services/custom-dialogs/dialog.service';
import {catchError, combineLatest, Observable, retry, Subscription, switchMap, throwError} from 'rxjs';
import {BrazeUserEventsService} from '@services/marketing/braze/events/braze-user-events.service';
import {debounceTime, filter, map, take} from 'rxjs/operators';
import {RouterEventsService} from '@services/routing/router-events/router-events.service';
import {IUserInfo} from '@interfaces/authorized-user/user.interface';
import {ONE_MINUTE_IN_MS, ONE_SECOND_IN_MS} from '@constants/durations.constants';
import {PAYMENT_STATUS_PATH} from '@constants/http.constants';
import {LanguageControlService} from '@services/language/language-control.service';

@Injectable({
  providedIn: 'root'
})
export class UserAfterLoginActionsService {
  private routerEventsService = inject(RouterEventsService);
  private languageControl = inject(LanguageControlService);
  constructor(private userManagerService: UserManagerService, private dialogService: DialogService, private brazeUserEventsService: BrazeUserEventsService) {
  }

  isFirstLogin(): Promise<boolean> {
    let isFirstLoginSub: Subscription | undefined;
    return new Promise<boolean>(resolve => {
      isFirstLoginSub = this.userManagerService.isFirstLogin.subscribe((firstLogin) => {
        if (firstLogin.loaded) {
          resolve(firstLogin.isFirstLogin);
        }
      });
    }).then((isFirstLogin) => {
      if (isFirstLoginSub) {
        isFirstLoginSub.unsubscribe();
      }
      return isFirstLogin;
    });
  }

  /**
   * Determines whether the demographics popup should be shown to the user.
   *
   * If this is the user's first login, the popup will be shown if the user has not
   * completed the demographic form or selected their interests. Otherwise, the
   * popup will be shown if the user has logged in 5 or fewer times.
   * @returns {Observable<boolean>} An observable that resolves to a boolean
   * indicating whether the demographics popup should be shown.
   */
  private shouldShowDemographicsPopup(): Observable<boolean> {
    const firstLogin$ = this.userManagerService.isFirstLogin.pipe(
      filter(firstLogin => firstLogin.loaded),
      map(firstLogin => firstLogin.isFirstLogin),
      take(1),
    );
    const shouldShowPopup$ = this.userManagerService.userInfo.pipe(
      filter(userInfo => !!userInfo),
      map((userInfo: IUserInfo) => userInfo.loginCountDown <= 5 && (!userInfo.hasDemographicData || !userInfo.interests)),
      take(1),
    );

    return firstLogin$.pipe(
      filter(firstLogin => firstLogin),
      switchMap(() => shouldShowPopup$),
    );
  }

  /**
   * Handles the logic for determining whether the demographics popup should be shown.
   *
   * This function combines two observables: one that checks whether the demographics
   * popup should be shown based on user data, and another that checks if the current
   * page allows the popup to be displayed. If both conditions are met, the demographics
   * popup is shown. The function retries if the conditions are not met.
   * it will stop checking if showDemographicsPopup returns false.
   */
  handleDemographicsPopup(): void {
    const distractionFreeURLs = [
      '/plans',
      '/checkout',
      `/${PAYMENT_STATUS_PATH}`,
      '/viewer',
      '/user-info',
    ];
    const supportedLanguages = this.languageControl.allLanguageCodes.join('|');
    const showDemographicsPopup$ = this.shouldShowDemographicsPopup();
    const pageEnabledToShowPopup$ = this.routerEventsService.navigationEnd.pipe(
      /* NOTE: debounce disabled as requested from the product team */
      //debounceTime(ONE_MINUTE_IN_MS),
      map(event => {
        return !distractionFreeURLs.some(url => {
          const regex = new RegExp(`^(/(${supportedLanguages}))?${url}`);
          return regex.test(event.urlAfterRedirects);
        });
      }),
    );

    combineLatest([pageEnabledToShowPopup$, showDemographicsPopup$]).pipe(
      map(([pageEnabledToShowPopup, showDemographics]) => {
        if (showDemographics && !pageEnabledToShowPopup) {
          throw new Error('Should not show demographics popup');
        }
        return pageEnabledToShowPopup && showDemographics;
      }),
      catchError(error => {
        return throwError(error);
      }),
      retry(ONE_SECOND_IN_MS),
      take(1),
    ).subscribe((shouldShowPopup) => {
      if (shouldShowPopup) {
        this.showDemographicsPopup();
      }
    });
  }

  /**
   * Shows the demographics popup. This is either the demographics form or
   * the interest selection, depending on whether the user has already filled in
   * demographic data.
   *
   * If the user has logged in for the first time, logs the user's info to Braze.
   * @private
   */
  private showDemographicsPopup() {
    let userInfo = this.userManagerService.userInfo.getValue();
    this.dialogService.openDialog(AuthDialogContainerComponent, 'auth',
      {
        type: userInfo.hasDemographicData ? 2 : 1,
        loginInfo: userInfo
      }, {disableClose: true}
    );
    if (userInfo?.loginCountDown === 1) {
      this.brazeUserEventsService.logUserinfo(userInfo);
    }
  }
}
