<div class="apply-coupon-form" *ngIf="showCouponField || couponPlan?.discountItems?.length; else applyCouponTemplate">
  <ng-container *ngIf="couponPlan?.discountItems?.length else validateCouponTemplate">
    <div class="coupon-display">
      <div>
        <span>{{ 'apply_coupon_form.coupon' | translate }} - </span>
        <span class="coupon-discount-amount">{{couponPlan?.discountItems[0].discountIdentifier}}</span>
      </div>
      <span class="coupon-discount-amount"> - {{(selectedPlan.price - couponPlan.finalPrice) | number: '1.0-0'}} {{selectedPlan.currency | translate}}</span>
    </div>
    <a class="form-action" (click)="resetCouponValue()">
      {{ 'apply_coupon_form.remove_coupon' | translate}}
    </a>
  </ng-container>
  <ng-template #validateCouponTemplate>
    <div class="apply-coupon-form_action-to-call__coupon">
      <mat-form-field class="form-group coupon-input" appearance="outline">
        <input matInput type="text" name="couponCode"
               [placeholder]="'apply_coupon_form.checkout_payment_visa_labels' | translate | translateCut: 5"
               class="form-control" [formControl]="couponCode"
               (keyup.enter)="onValidatingCouponCode()">
      </mat-form-field>
      <div class="form-actions">
        <a class="form-action"
           *ngIf="!loading; else ValidatingCoupon"
           (click)="onValidatingCouponCode()">
          {{'apply_coupon_form.validate_coupon' | translate}}
        </a>
        <ng-template #ValidatingCoupon>
          <mat-spinner style="height: 24px;width: 24px;"></mat-spinner>
        </ng-template>
      </div>
    </div>
    <div class="apply-coupon-form__validation-container">
      <div class="apply-coupon-form__validation-container__invalid-feedback"
           *ngIf="couponInvalidError">
        {{(couponInvalidError | translate)}}
      </div>
    </div>
  </ng-template>
</div>
<ng-template #applyCouponTemplate>
  <button mat-ripple class="apply-coupon-button" (click)="displayCouponField()">
    {{ 'apply_coupon_form.apply_coupon' | translate }}
  </button>
</ng-template>

