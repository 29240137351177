import { Injectable } from '@angular/core';
import {CustomHttpService} from '@services/request-token/custom-http.service';
import {INotificationList} from '@interfaces/notification/notification.interface';

@Injectable({
  providedIn: 'root'
})
export class NotificationHttpService {

  constructor(private customHttpService: CustomHttpService) { }

  getAllNotifications(sender: string): Promise<INotificationList> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/getUsrNotify/api/notifications/history',
      sender: sender,
      receiver: 'history',
      body: {}
    });
  }

  markNotificationAsRead(sender: string, notificationIdObject: object): Promise<{result: boolean}> {
    return this.customHttpService.sendRequest( {
      endpoint: 'new-orchestrator/addEditUsrNotify/api/notifications/markAsRead',
      sender: sender,
      receiver: 'markAsRead',
      body: notificationIdObject
    });
  }

  getUnreadNotificationsCount(sender: string): Promise<{count: number}> {
    return this.customHttpService.sendRequest( {
      endpoint: 'new-orchestrator/getUsrNotify/api/notifications/unReadCount',
      sender: sender,
      receiver: 'unReadCount',
      body: {}
    });
  }

  getCoursePermanentLinkById(id: number): Promise<{permanentLink: string}>{
    return this.customHttpService.sendRequest( {
      endpoint: 'orchestrator/api/courses/getPermanentLink/byId',
      sender: 'notifications',
      receiver: 'byId',
      body: {
        entityId: id
      }
    });
  }
}
