<div class="header-search form-container">
  <div class="header-search_form-input">
    <div class="search-input-field">
      <mat-form-field (keyup.enter)="onSearchSubmit(searchAutocompleteTrigger)"
                      (focusin)="initSearchHistory()" class="form-group w-100" appearance="outline">
        <input matInput class="form-control" #searchInputElement #searchAutocompleteTrigger="matAutocompleteTrigger"
               [almMatInputAutofocus]="autoFocus"
               placeholder="{{'header_search.placeholder' | translate}}" [matAutocomplete]="searchAutocomplete"
               name="searchInput" type="text" aria-label="Search" [formControl]="searchInput"/>
      </mat-form-field>
      <button type="button" (click)="onSearchSubmit(searchAutocompleteTrigger)">
        <span class="sr-only">{{'header_search.btn' | translate}}</span>
        <svg>
          <use href="assets/images/sprite.svg#search"/>
        </svg>
      </button>
    </div>
  </div>
  <mat-autocomplete [disableRipple]="true" (opened)="onAutocompleteOpened(searchAutocomplete)"
                    [class]="'header-search__autocomplete'"
                    #searchAutocomplete="matAutocomplete">
    <div class="header-search__autocomplete-options-container"
         *ngIf="searchInput.value.length < 4 && searchHistory.length > 0">
      <p class="header-search__autocomplete-options-title">
        {{'header_search.recent_search' | translate}}</p>
      <mat-option *ngFor="let searchItem of searchHistory" [value]="searchItem"
                  (click)="onSearchSubmit(searchAutocompleteTrigger)">
        <div class="header-search__autocomplete-option-item">
          <svg>
            <use href="assets/images/sprite.svg#timer"/>
          </svg>
          <p>{{searchItem}}</p>
          <svg class="header-search__autocomplete-option-item-delete"
               (click)="onHistorySearchDelete(searchItem, $event)">
            <use href="assets/images/sprite.svg#times"/>
          </svg>
        </div>
      </mat-option>
    </div>
    <div class="header-search__autocomplete-options-container" *ngIf="searchInput.value.length > 2 && searchSuggestions">
      <p class="header-search__autocomplete-options-title">{{'header_search.suggested' | translate}}</p>
      <div *ngIf="searchSuggestions.wordsDetails.results.length">
        <mat-option *ngFor="let Keyword of searchSuggestions.wordsDetails.results; let index = index"
                    [value]="Keyword"
                    (click)="handleKeywordClick(searchAutocompleteTrigger, Keyword, searchSuggestions.wordsDetails, index)">
          <div class="header-search__autocomplete-option-item">
            <svg>
              <use href="assets/images/sprite.svg#search"/>
            </svg>
            <p>{{Keyword}}</p>
          </div>
        </mat-option>
      </div>
      <div *ngIf="searchSuggestions.coursesDetails.results.length">
        <mat-option *ngFor="let course of searchSuggestions.coursesDetails.results; let index = index"
                    [value]="course.name"
                    (click)="handleCourseClick(searchAutocompleteTrigger, course, searchSuggestions.coursesDetails, index)">
          <div class="header-search__autocomplete-option-item">
            <svg>
              <use href="assets/images/sprite.svg#courses"/>
            </svg>
            <p>{{course.name}}</p>
          </div>
        </mat-option>
      </div>
      <div *ngIf="searchSuggestions.mentorsDetails.results.length">
        <mat-option *ngFor="let mentor of searchSuggestions.mentorsDetails.results; let index = index"
                    [value]="mentor.name"
                    (click)="handleMentorClick(searchAutocompleteTrigger, mentor, searchSuggestions.mentorsDetails, index)">
          <div class="header-search__autocomplete-option-item">
            <svg>
              <use href="assets/images/sprite.svg#learner"/>
            </svg>
            <p>{{mentor.name}}</p>
          </div>
        </mat-option>
      </div>
    </div>
  </mat-autocomplete>
</div>
