import {Injectable} from '@angular/core';
import {CoursesHttpService} from '@services/integrations/courses/courses-http.service';
import {IPagination, IPaginationCache, IPaginationRequest} from '@interfaces/common/pagination.interface';
import {
  CourseCertificatesPaginated, CourseNavigationEvent,
  IAssignedCourse,
  ICertificateDetails,
  ICourse,
  ICourseProgress,
  ISimplifiedCourse
} from '@interfaces/course/course.interface';
import {LanguageControlService} from '@services/language/language-control.service';
import {ICurriculumSection} from '@interfaces/viewer/curriculum.interface';
import {ITempCategory} from '@interfaces/temp-category/temp-category.interface';
import {IWeeklyAssignedCourse} from '@interfaces/learning-goals/learning-goals.interface';
import {ISimplifiedCategory} from '@interfaces/course/category.interface';
import {INotesPaginated} from '@interfaces/viewer/note.interface';
import {LanguageId} from "@interfaces/common/language.interface";
import {ICourseDiscoverySection} from "@anonymous/shared/models/anonymous-course.interface";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class CoursesService {

  private menuCourses: {
    [key: number]: IPaginationCache<IPagination<ISimplifiedCourse>>
  } = {};
  private recentCourses: {
    [key: string]: ISimplifiedCourse[]
  } = {};
  private prevRequest?: {
    send: Promise<any>,
    cancel: () => void
  };
  private prevProgressCoursesRequest?: {
    send: Promise<IPagination<ICourse>>,
    cancel: () => void
  };
  private prevCertificatesRequest?: {
    send: Promise<CourseCertificatesPaginated>,
    cancel: () => void
  };
  private prevUserNotesRequest?: {
    send: Promise<INotesPaginated>,
    cancel: () => void
  };

  private coursesCache: Record<number, ISimplifiedCourse[]> = {};

  constructor(private coursesHttpService: CoursesHttpService,
              private languageService: LanguageControlService,
              private languageControl: LanguageControlService,
              private router: Router,
              ) {
  }

  getMenuCoursesByCategoryId(categoryId: number): Promise<IPagination<ISimplifiedCourse>> {
    const currentLanguageId = this.languageService.currentLanguage.getValue().id;
    const cacheKey: number | string  = `${currentLanguageId}-${categoryId}`;

    // @ts-ignore Check if the courses for the given category and language are already in the cache
    if (this.menuCourses[cacheKey]) {
      //@ts-ignore
      return Promise.resolve(this.menuCourses[cacheKey]);
    } else {
      // Cancel the previous request if it exists
      if (this.prevRequest) {
        this.prevRequest.cancel();
      }

      // Fetch from backend and update cache
      this.prevRequest = this.coursesHttpService.getCoursesByCategoryIdAndLanguageIdv('menu', currentLanguageId, categoryId,
        {page: 0, size: 4}, {});

      return this.prevRequest.send.then((coursesList) => {
        //@ts-ignore
        this.menuCourses[cacheKey] = coursesList;
        return coursesList;
      }).catch((e) => {
        // Handle the error or rethrow it
        console.error(e);
        throw e;
      });
    }
  }

  getFilteredCourses(sender: string, paginationRequest: IPaginationRequest,
                     filter: Object): Promise<IPagination<ICourse>> {
    const currentLanguageId = this.languageService.currentLanguage.getValue().id;
    return this.coursesHttpService.getFilteredCourses(sender, currentLanguageId, paginationRequest, filter);
  }

  getCourseById(sender: string, languageId: number, paginationRequest: IPaginationRequest,
                filter: Object): Promise<IPagination<ICourse>> {
    return new Promise<IPagination<ICourse>>((resolve, reject) => {
      this.coursesHttpService.getCourseById(sender, languageId, paginationRequest, filter)
        .then(resolve)
        .catch(reject);
    });
  }

  getRelatedCourses(sender: string, languageId: number, paginationRequest: IPaginationRequest,
                    filter: Object): Promise<IPagination<ICourse>> {
    return new Promise<IPagination<ICourse>>((resolve, reject) => {
      this.coursesHttpService.getRelatedCourses(sender, languageId, paginationRequest, filter)
        .then(resolve)
        .catch(reject);
    });
  }

  getRankedCourses(sender: string, languageId: number, paginationRequest: IPaginationRequest,
                   filter: Object): Promise<IPagination<ISimplifiedCourse>> {
    return this.coursesHttpService.getRankedCourses(sender, languageId, paginationRequest, filter);
  }

  getTrendingCourses(sender: string, paginationRequest: IPaginationRequest,
                     filter: Object): Promise<IPagination<ISimplifiedCourse>> {
    const currentLanguageId = this.languageControl.currentLanguage.getValue().id;
    return this.coursesHttpService.getTrendingCourses(sender, currentLanguageId, paginationRequest, filter);
  }

  getFeaturedCourses(sender: string, paginationRequest: IPaginationRequest,
                     filter: Object): Promise<IPagination<ICourse>> {
    const currentLanguageId = this.languageControl.currentLanguage.getValue().id;
    return this.coursesHttpService.getFeaturedCourses(sender, currentLanguageId, paginationRequest, filter);
  }

  getPopularCourses(sender: string, paginationRequest: IPaginationRequest,
                    filter: Object): Promise<IPagination<ISimplifiedCourse>> {
    const currentLanguageId = this.languageControl.currentLanguage.getValue().id;
    return this.coursesHttpService.getPopularCourses(sender, currentLanguageId, paginationRequest, filter);
  }

  getMultipleCoursesByIds(coursesIds: number[]): Promise<IPagination<ICourse>> {
    const currentLanguageId = this.languageControl.currentLanguage.getValue().id;
    const paginationRequest = {
      page: 0,
      size: 1000,
      sort: `c.id,[${coursesIds}]`
    };
    const filter = {
      'c.id': coursesIds,
      learningOutcomeRequired: true
    };
    return this.coursesHttpService.getFilteredCourses('learning-program-details',
      currentLanguageId, paginationRequest, filter).then();
  }

  getMyCourses(sender: string, isCompleted: boolean, sortOption?: string, paginationSize?: number): Promise<IPagination<ISimplifiedCourse>> {
    const currentLanguageId = this.languageControl.currentLanguage.getValue().id;
    const filter = {
      isCompleted: isCompleted,
    };
    const paginationRequest = {
      page: 0,
      size: paginationSize ? paginationSize : 10000,
      sort: sortOption
    };
    return this.coursesHttpService.getMyCourses(sender, currentLanguageId, filter, paginationRequest);
  }

  getCourseOutline(courseId: number, sender: string): Promise<{
    numberOfLessons: number,
    sections: ICurriculumSection []
  }> {
    const currentLanguageId = this.languageControl.currentLanguage.getValue().id;
    return this.coursesHttpService.getCourseOutline(courseId, currentLanguageId, sender);
  }

  getPicksOfDay(sender: string): Promise<IPagination<ICourse>> {
    const currentLanguageId = this.languageControl.currentLanguage.getValue().id;
    return this.coursesHttpService.getPicksOfDay(sender, currentLanguageId);
  }

  getRecentCourses(sender: string): Promise<ISimplifiedCourse[]> {
    return new Promise<ISimplifiedCourse[]>((resolve, reject) => {
      let currentLanguageId: number = this.languageService.currentLanguage.getValue().id;
      let sortObject: IPaginationRequest = {
        size: 12,
        page: 0,
        sort: 'releasedAt,desc'
      };
      this.getFilteredCourses(sender, sortObject,
        {})
        .then((courses) => {
          this.recentCourses[currentLanguageId] = courses.items;
          resolve(this.recentCourses[currentLanguageId]);
        })
        .catch(reject);
    });
  }

  getNewCourses(sender: string, paginationRequest: IPaginationRequest, filter: object): Promise<IPagination<ICourse>> {
    const currentLanguageId = this.languageControl.currentLanguage.getValue().id;
    return this.coursesHttpService.getNewCourses(sender, paginationRequest, filter, currentLanguageId);
  }
  getPrivateCourses(sender: string, paginationRequest: IPaginationRequest, filter: object): Promise<IPagination<ICourse>> {
    const currentLanguageId = this.languageControl.currentLanguage.getValue().id;
    return this.coursesHttpService.getPrivateCourses(paginationRequest, filter);
  }


  getRecentlyViewedCourses(sender: string, paginationRequest: IPaginationRequest): Promise<IPagination<ICourse>> {
    const currentLanguageId = this.languageControl.currentLanguage.getValue().id;
    return this.coursesHttpService.getRecentlyViewedCourses(sender, currentLanguageId, paginationRequest);
  }

  getCoursesPromoViews(videoId: string, courseId: number): Promise<{
    'alltime_video_views': number
  }> {
    return this.coursesHttpService.getCoursesPromoViews(videoId, courseId);
  }

  getTempCategories(sender: string): Promise<ITempCategory []> {
    const currentLanguageId = this.languageControl.currentLanguage.getValue().id;
    return this.coursesHttpService.getTopTempCategories(sender, currentLanguageId);
  }

  getCourseIdByPermanentLink(sender: string, permanentLink: string): Promise<{
    id: number
  }> {
    return this.coursesHttpService.getCourseIdByPermanentLink(sender, permanentLink);
  }

  getCourseRemainingProgress(sender: string, courseId: number): Promise<ICourseProgress> {
    return this.coursesHttpService.getCourseRemainingProgress(sender, courseId);
  }

  getAssignedCoursesWithinWeek(sender: string, weekStartDate: string, weekEndDate: string): Promise<IWeeklyAssignedCourse[]> {
    const currentLanguageId = this.languageControl.currentLanguage.getValue().id;
    return this.coursesHttpService.getAssignedCoursesWithinWeek(sender, weekStartDate, weekEndDate, currentLanguageId);
  }

  getB2BCategories(sender: string, paginationRequest: IPaginationRequest): Promise<IPagination<ISimplifiedCategory>> {
    const currentLanguageId = this.languageControl.currentLanguage.getValue().id;
    return this.coursesHttpService.getB2BCategories(sender, currentLanguageId, paginationRequest);
  }

  getMyCoursesPageItems(sender: string, paginationRequest: IPaginationRequest, filter: Object): Promise<IPagination<ICourse>> {
    if (this.prevProgressCoursesRequest) {
      this.prevProgressCoursesRequest.cancel();
    }
    const currentLanguageId = this.languageControl.currentLanguage.getValue().id;
    this.prevProgressCoursesRequest = this.coursesHttpService.getMyCoursesPageItems(sender, currentLanguageId, paginationRequest, filter);
    return this.prevProgressCoursesRequest?.send;
  }
  getAllOrgCourses(sender: string, paginationRequest: IPaginationRequest, filter: any): Promise<IPagination<ICourse>> {
    let oldestFirst: boolean;
    if (filter?.hasOwnProperty('c.level')) {
      filter.levelIds = filter['c.level'];
      delete filter['c.level'];
    }
    if (filter?.hasOwnProperty('c.language')) {
      filter.languageIds = filter['c.language'];
      delete filter['c.language'];
    }
    if(paginationRequest.sort == 'releaseDate,asc'){
     oldestFirst = true
    }else{
      oldestFirst = false
    }

    if (this.prevProgressCoursesRequest) {
      this.prevProgressCoursesRequest.cancel();
    }
    const currentLanguageId = this.languageControl.currentLanguage.getValue().id;
    this.prevProgressCoursesRequest = this.coursesHttpService.getAllOrgCourses(sender, currentLanguageId, paginationRequest, filter,oldestFirst);
    return this.prevProgressCoursesRequest?.send;
  }


  getProgressCourses(sender: string, paginationRequest: IPaginationRequest, filter: Object): Promise<IPagination<ICourse>> {
    const currentLanguageId = this.languageControl.currentLanguage.getValue().id;
    return this.coursesHttpService.getProgressCourses(sender, currentLanguageId, paginationRequest, filter);
  }

  getAssignedCourses(sender: string, paginationRequest: IPaginationRequest): Promise<IPagination<IAssignedCourse>> {
    const currentLanguageId = this.languageControl.currentLanguage.getValue().id;
    return this.coursesHttpService.getAssignedCourses(sender, currentLanguageId, paginationRequest);
  }

  getRecommendedCourses(sender: string, paginationRequest: IPaginationRequest): Promise<IPagination<ISimplifiedCourse>> {
    const currentLanguageId = this.languageControl.currentLanguage.getValue().id;
    return this.coursesHttpService.getRecommendedCourses(sender, currentLanguageId, paginationRequest);
  }

  getMyCertificatesPageItems(sender: string, paginationRequest: IPaginationRequest, filter: Object): Promise<CourseCertificatesPaginated> {
    if (this.prevCertificatesRequest) {
      this.prevCertificatesRequest.cancel();
    }
    const currentLanguageId = this.languageControl.currentLanguage.getValue().id;
    this.prevCertificatesRequest = this.coursesHttpService.getMyCertificatesPageItems(sender, currentLanguageId, paginationRequest, filter);
    return this.prevCertificatesRequest?.send;
  }

  getCertificateDetails(sender: string, certificateCode: string, languageId: number): Promise<ICertificateDetails> {
    return this.coursesHttpService.getCertificateDetails(sender, certificateCode, languageId);
  }

  async getCertificateUrl(sender: string, certificateId: number): Promise<string> {
    const certificate = await this.coursesHttpService.getCertificateUrl(sender, certificateId);
    return certificate.certificateURL;
  }


  getUserCertificates(sender: string, paginationRequest: IPaginationRequest, filter: Object): Promise<CourseCertificatesPaginated> {
    const currentLanguageId = this.languageControl.currentLanguage.getValue().id;
    return this.coursesHttpService.getUserCertificates(sender, currentLanguageId, paginationRequest, filter);
  }

  getUserNotes(sender: string, paginationRequest: IPaginationRequest, filter: Object): Promise<INotesPaginated> {
    if (this.prevUserNotesRequest) {
      this.prevUserNotesRequest.cancel();
    }
    const currentLanguageId = this.languageControl.currentLanguage.getValue().id;
    this.prevUserNotesRequest = this.coursesHttpService.getUserNotesPageItems(sender, currentLanguageId, paginationRequest, filter);
    return this.prevUserNotesRequest?.send;
  }

  getCourseDiscoveryList(sender: string, languageId: LanguageId): Promise<ICourseDiscoverySection[]> {
    return this.coursesHttpService.getCourseDiscoveryList(sender, languageId);
  }

  handleCourseNavigation(courseNavigationEvent: CourseNavigationEvent) {
    this.router.navigate([courseNavigationEvent.navigationIntent, courseNavigationEvent.permanentLink])
  }

  async getB2bPromotedCategories(): Promise<number[]> {
    return this.coursesHttpService.getB2bPromotedCategories();
  }
}
