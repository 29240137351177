import {Injectable} from '@angular/core';
import {ChatHttpService} from "@services/integrations/chat/chat-http.service";
import {LanguageControlService} from "@services/language/language-control.service";
import {IChatResponse, INewThreadChatResponse} from "@interfaces/chat/chat.interface";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  onChatStatusUpdate:BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private chatHttpService: ChatHttpService, private languageControl: LanguageControlService) {
  }

  getAllChatsForLearner(sender: string, courseId: number): Promise<IChatResponse[]> {
    let languageId = this.languageControl.currentLanguage.getValue().id;
    return this.chatHttpService.getAllChatsForLearner(sender, languageId, courseId);
  }

  createThreadChat(sender: string, mentorId: number, mentorUUID: string, courseId: number, isLearner: boolean): Promise<INewThreadChatResponse> {
    return this.chatHttpService.createThreadChat(sender, mentorId, mentorUUID, courseId, isLearner);
  }

  getThreadChatById(sender: string, threadId: string) {
    let languageId = this.languageControl.currentLanguage.getValue().id;
    return this.chatHttpService.getThreadChatById(sender, threadId, languageId)
  }

  addChatMsg(sender: string, threadChatID: string, message: string, isLearner: boolean): Promise<{result: boolean}> {
    return this.chatHttpService.addChatMsg(sender, threadChatID, message, isLearner);
  }

  updateChatStatus(sender: string, threadChatID: string, isLearner: boolean): Promise<{result: boolean}> {
    return this.chatHttpService.updateChatStatus(sender, threadChatID, isLearner).then((value)=> {
      this.onChatStatusUpdate.next(true);
      return value;
    })
  }

  getUnreadMsg(sender: string, isLearner: boolean) {
    return this.chatHttpService.getUnreadMsg(sender, isLearner);
  }

  getChatDetails(sender: string, isLearner: boolean): Promise<IChatResponse[]> {
    let languageId = this.languageControl.currentLanguage.getValue().id;
    return this.chatHttpService.getChatDetails(sender, languageId, isLearner);
  }
}
