import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CategoryPageComponent} from './category-page.component';
import {PaginatedCoursesListComponent} from "./components/paginated-courses-list/paginated-courses-list.component";
import {CategoryPageFilterComponent} from './components/category-page-filter/category-page-filter.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {TranslateModule} from '@ngx-translate/core';
import {AlmTranslateCutModule} from '@alm-translate-cut/alm-translate-cut.module';
import {CategoryBannerComponent} from "@pages/category-page/components/category-banner/category-banner.component";
import {FiltersModule} from "@components/filters/filters.module";
import {ImageLoaderModule} from "@directives/image-loader/image-loader.module";
import {SubscriptionBannerModule} from "@components/subscription-banner/subscription-banner.module";
import { CategorySubscriptionBannerComponent } from './components/category-subscription-banner/category-subscription-banner.component';
import {CourseCardNewModule} from "@components/course-card-new/course-card-new.module";
import {NewDefaultCourseCarouselModule} from "@components/new-default-course-carousel/new-default-course-carousel.module";


@NgModule({
  declarations: [
    CategoryPageComponent,
    PaginatedCoursesListComponent,
    CategoryPageFilterComponent,
    CategoryBannerComponent,
    CategorySubscriptionBannerComponent,
  ],
  exports: [
    CategoryPageComponent
  ],
    imports: [
        CommonModule,
        CourseCardNewModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        TranslateModule,
        AlmTranslateCutModule,
        FiltersModule,
        ImageLoaderModule,
        SubscriptionBannerModule,
        NewDefaultCourseCarouselModule
    ]
})
export class CategoryPageModule {
}
