import { Directive, Input, Renderer2, ElementRef, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[inlineLoader]'
})
export class InlineLoaderDirective implements OnChanges {
  @Input() inlineLoader: boolean = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['inlineLoader']) {
      if (this.inlineLoader) {
        this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
        this.renderer.addClass(this.el.nativeElement, 'button--loading')
      } else {
        this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
        this.renderer.removeClass(this.el.nativeElement, 'button--loading')
      }
    }
  }
}
