import { Component, Inject, OnInit } from '@angular/core';
import {ISubscriptionPlan, UserSubscriptionPlan} from '@interfaces/subscription/subscription.interface';
import { IUserInfo } from '@interfaces/authorized-user/user.interface';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubscriptionService } from '@services/integrations/subscription/subscription.service';
import { DialogService } from '@services/custom-dialogs/dialog.service';
import { UserManagerService } from '@services/user/user-manager.service';
import { LanguageControlService } from "@services/language/language-control.service";
import { ILanguageCode } from "@interfaces/common/language.interface";
import { ELanguage } from "@interfaces/common/connector.interface";
import { take, timer } from 'rxjs';

@Component({
  selector: 'alm-root-user-settings-change-plan-dialog',
  templateUrl: './user-settings-change-plan-dialog.component.html',
  styleUrls: ['./user-settings-change-plan-dialog.component.scss']
})
export class UserSettingsChangePlanDialogComponent implements OnInit {
  currentPlan?: UserSubscriptionPlan;
  nextPlan?: ISubscriptionPlan;
  plans: ISubscriptionPlan[] = [];
  userInfo: IUserInfo | null = null;
  date = '';
  discountPercentage = '30%';
  currentLanguage: ILanguageCode;
  ELanguage = ELanguage;
  calculationLoader: boolean = false;
  showCouponField: boolean = false;
  public summaryDetails: { [key: string]: any }[] = [
    {
      name: 'Original Monthly Plan (x12)',
      price: 2024,
      currency: 'EGP'
    },
    {
      name: 'Monthly Plan',
      price: 399,
      currency: 'EGP'
    },
    {
      name: 'Monthly Plan',
      price: 399,
      currency: 'EGP'
    },
    {
      name: 'Monthly Plan',
      price: 399,
      currency: 'EGP'
    },
    {
      name: 'Monthly Plan',
      price: 399,
      currency: 'EGP'
    },
    {
      name: 'Credit',
      price: -399,
      currency: 'EGP',
      hint: '14 days converted from current plan'
    },
    {
      name: 'Additional amounts',
      price: 399,
      currency: 'EGP',
      hint: '14 days converted from current plan'
    },
  ]


  constructor(@Inject(MAT_DIALOG_DATA) private subData: { plan: UserSubscriptionPlan, date: string },
    private subscriptionService: SubscriptionService,
    private dialogService: DialogService,
    private userManagerService: UserManagerService,
    private languageControl: LanguageControlService
  ) {
    this.currentLanguage = languageControl.currentLanguage.getValue().code;
  }

  ngOnInit(): void {
    this.userInfo = this.userManagerService.userInfo.getValue();
    if (this.subData.plan?.id) {
      this.currentPlan = this.subData.plan;
      this.subscriptionService.getAllSubscriptionPlans().then((plans) => {
        this.plans = plans.filter(plan => plan.id !== this.subData.plan.id);
        if (this.plans?.length) {
          this.nextPlan = this.plans[0]
        }
        /**
         * desctription: the below method for adding extra properties to mock the future response
         *
         */
        this.addNeededPropertiesToPlans();
      }).catch(() => {
        this.closeDialog();
      });
    } else {
      this.closeDialog();
    }

    this.date = this.subData.date;

    this.getDiscountPercentage();
  }

  closeDialog(data?: any) {
    this.dialogService.closeDialog('change-subscription-plan', data);
  }

  changePlan(plan: ISubscriptionPlan) {
    this.nextPlan = plan;
    this.calculationLoader = true;
    timer(2000).pipe(take(1)).subscribe(data => {
      this.calculationLoader = false
    })
  }

  confirmPlan() {
    console.log(this.nextPlan);
    this.closeDialog(this.nextPlan)
  }

  displayCouponField(): void {
    this.showCouponField = true;
  }


  private getDiscountPercentage(): void {
    this.subscriptionService.getAllSubscriptionPlans().then((plans) => {
        const annualPlan = plans.find(p => p.isAnnualPlan);
        if (annualPlan?.originalPrice) {
          const discount = (annualPlan.originalPrice - annualPlan.finalPrice) / annualPlan.originalPrice;
          this.discountPercentage = Math.round(discount * 100) + '%';
        }
      }
    );
  }

  private addNeededPropertiesToPlans(): void {
    this.plans.forEach(plan => {
      if (plan.duration === 365) {
        plan.message = 'Get 12 months for the price of 6';

      }
    });
  }
}
