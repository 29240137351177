import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineLoaderDirective } from './inline-loader.directive';



@NgModule({
  declarations: [
    InlineLoaderDirective
  ],
  exports: [InlineLoaderDirective],
  imports: [
    CommonModule
  ],
})
export class InlineLoaderModule { }
