import {Component, HostListener, Inject, Input, OnChanges, OnInit, PLATFORM_ID, SimpleChanges} from '@angular/core';
import {IUserInfo} from '@interfaces/authorized-user/user.interface';
import {BehaviorSubject} from 'rxjs';
import {FilesManagerService} from '@services/files/files-manager.service';
import {ILoyaltyInfo} from '@interfaces/user/user-common.interface';
import {ChatService} from '@services/integrations/chat/chat.service';
import {isPlatformBrowser} from '@angular/common';
import {environment} from '@environments/environment';
import {LanguageControlService} from '@services/language/language-control.service';
import {B2bHomeBannerService} from '@services/integrations/b2b-home-banner/b2b-home-banner.service';
import { CustomContentService } from '@services/custom-content/custom-content.service';

@Component({
  selector: 'alm-root-app-container',
  templateUrl: './app-container.component.html',
  styleUrls: ['./app-container.component.scss']
})
export class AppContainerComponent implements OnInit, OnChanges {

  @Input() removeHeader: boolean = false;
  @Input() userInfoSub?: BehaviorSubject<IUserInfo | null>;
  @Input() unReadNotificationCount: number = 0;
  @Input() numberOfUnreadMessages: number = 0;
  @Input() onNavigationChange: boolean = false;
  @Input() loyaltyInfo?: ILoyaltyInfo;
  @Input() isB2bUser: boolean = false;
  @Input() hasSimplifiedHeader: boolean = false;
  @Input() showAppAnnouncement: boolean = false;

  loginLink: string = `${environment.platforms.sso.link}/join?ref=header_login_button`;
  signupLink: string = `${environment.platforms.sso.link}/join?ref=header_signup_button`;

  mobileMenuView = 1;
  isMobileMenuOpen: boolean = false;
  isProfileMenu: boolean = false;
  isGtmRibbonOpen: boolean = false;
  private currentWidth: number;
  isCampaignRibbon: boolean = false;
  appDirection: 'rtl' | 'ltr';
  organizationNames?: { nameAr: string, nameEn: string };

  constructor(private filesManager: FilesManagerService, private chatService: ChatService,
              @Inject(PLATFORM_ID) private platformId: any,
              private languageService: LanguageControlService,
              private b2bHomeBannerService: B2bHomeBannerService,
              private customContentService: CustomContentService) {
    this.currentWidth = isPlatformBrowser(this.platformId) ? window.innerWidth : 0;
    this.appDirection = this.languageService.currentLanguage.getValue().direction;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.onNavigationChange) {
      this.closeMobileMenu();
    }
    if (changes.loyaltyInfo) {
      this.loyaltyInfo = changes.loyaltyInfo.currentValue;
    }
  }

  ngOnInit(): void {
    this.languageService.currentLanguage.subscribe((lang) => {
      this.appDirection = lang.direction;
    });
  }

  @HostListener('window:resize')
  onWindowResize() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth != this.currentWidth) {
        this.closeMobileMenu();
        this.currentWidth = window.innerWidth;
      }
    }
  }

  onMenuButtonClick(isProfile: boolean) {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth < 992) {
        this.isProfileMenu = isProfile;
        this.isMobileMenuOpen = !this.isMobileMenuOpen;
        this.mobileMenuView = 1;
        if (this.isMobileMenuOpen && isProfile) {
          this.chatService.getUnreadMsg('user-mobile-menu', true).then((unReadMessages) => {
            this.numberOfUnreadMessages = unReadMessages.count;
          });
        }
        if (this.isB2bUser && !this.organizationNames) {
          this.b2bHomeBannerService.getB2bOrganizationInfo('mobile-menu').then((info) => {
            this.organizationNames = {
              nameAr: info.nameAr,
              nameEn: info.nameEn
            };
          }).catch(() => {
          });
        }
      }
    }
  }

  closeMobileMenu() {
    this.isMobileMenuOpen = false;
    this.isProfileMenu = false;
    this.mobileMenuView = 1;
  }

  getProfileImage(userId: string | undefined): string {
    if (userId) {
      return this.filesManager.getHashedImage(`user-${userId}-profile`);
    }
    return '';
  }

  changeHeaderMenuView($event: number) {
    this.mobileMenuView = $event;
  }

  onClosingGtmRibbon($event: boolean) {
    this.isGtmRibbonOpen = !$event;
  }

  openGtmRibbon($event: boolean) {
    this.isGtmRibbonOpen = $event;
  }

  openCampaignRibbon($event: boolean) {
    this.isCampaignRibbon = $event;
  }

  closeCampaignRibbon($event: boolean) {
    this.isCampaignRibbon = !$event;
  }

  hideAppAnnouncement(): void {
    sessionStorage.setItem('app-announcement-ribbon', 'hide');
    this.showAppAnnouncement = false;
  }
}
