<header class="header" [class.header__menu-open]="isDesktopMenuOpened">
  <div class="header-bg">
    <alm-root-top-ribbon-danger [userInfo]="userInfo"></alm-root-top-ribbon-danger>
    <div class="gtm-ribbon-wrapper"
         *ngIf="loyaltyInfo && loyaltyInfo.loyal && loyaltyInfo.referralCount <= loyaltyInfo.inviteesCap && gtmRibbonToggleCount < 5 && !gtmRibbonClosed">
      <alm-root-gtm-ribbon (onClosingGtmRibbon)="onClosingGtmRibbon($event)"></alm-root-gtm-ribbon>
    </div>
    <div class="header-container container">
      <div class="col header-logo">
        <button class="btn btn-link icon-item mobile-menu-icon"
                [ngSwitch]="themeControl.currentTheme | async"
                (click)="menuToggle(false)">
          <svg *ngSwitchCase="THEMES.DARK">
            <use href="assets/images/sprite.svg#menu-icon-light"/>
          </svg>
          <svg *ngSwitchCase="THEMES.LIGHT">
            <use href="assets/images/sprite.svg#menu-icon-dark"/>
          </svg>
        </button>
        <a class="header-logo_icon" routerLink="/home">
          <div class="logo">
            <span class="sr-only">Almentor</span>
            <svg>
              <use href="assets/images/sprite.svg#logo"/>
            </svg>
          </div>
        </a>
        <div class="nav-container">
          <alm-root-header-nav-menu [userInfo]="userInfo" (onMenuToggle)="onDesktopMenuToggle($event)"
                                    [isB2BUser]="(userInfo && (userInfo.userType === 2)) || isB2bUser"></alm-root-header-nav-menu>
        </div>
      </div>

      <div class="spacer"></div>
      <div class="search-bar-container" *ngIf="!(customContentService.hasCustomContent$ | async)">
        <alm-root-header-search></alm-root-header-search>
      </div>
      <div class="mobile-menu" >
        <ul>
          <li class="search-btn" *ngIf="!(customContentService.hasCustomContent$ | async)" (click)="openMobileSearch($event)">
            <span class="d-lg-none">{{'main_header_new.mobile_search_btn' | translate}}...</span>
            <button class="btn btn-icon">
              <svg class="header-search-icon">
                <use href="assets/images/sprite.svg#search"/>
              </svg>
            </button>
          </li>
          <div *ngIf="openSearchResponsive" (click)="closeMobileSearch()" class="search-responsive">
            <alm-root-header-search *ngIf="!(customContentService.hasCustomContent$ | async)" (click)="$event.stopPropagation()" [autoFocus]="true"
                                    (closeSearchInput)="closeMobileSearch()"></alm-root-header-search>
          </div>
          <li *ngIf="userInfo" class="d-lg-none mobile-menu__profile-image-container">
            <img class="mobile-menu__profile-image profile-image-dark" [imageLoader]="getProfileImage(userInfo.uuid)"
                 [src]="updatedImage"
                 [isLazyLoadedImage]="false"
                 errorImage="assets/images/default-avatar-placeholder-dark.jpg" alt="Avatar" (click)="menuToggle(true)">
            <img class="mobile-menu__profile-image profile-image-light" [imageLoader]="getProfileImage(userInfo.uuid)"
                 [src]="updatedImage"
                 [isLazyLoadedImage]="false"
                 errorImage="assets/images/default-avatar-placeholder-light.jpg" alt="Avatar"
                 (click)="menuToggle(true)">
            <span *ngIf="numberOfUnreadMessages" class="mobile-menu__messages-count" dir="ltr">
        {{numberOfUnreadMessages}}
      </span>
          </li>
        </ul>
      </div>
      <div class="header_divider">
      </div>
      <alm-root-user-control-menu [userInfo]="userInfo" [loginLink]="loginLink" [signupLink]="signupLink"
                                  class="user-controls-container"
                                  [numberOfCartItems]="numberOfCartItems"
                                  [unReadNotificationCount]="unReadNotificationCount"
                                  [updatedImage]="updatedImage"
                                  [numberOfUnreadMessages]="numberOfUnreadMessages"
                                  [isB2bUser]="isB2bUser"
                                  (onOpeningUserMenu)="updateUnreadMessages()"></alm-root-user-control-menu>


    </div>
    <!--    <div class="gtm-ribbon-mobile-wrapper" *ngIf="!isCampaignRibbonClosed && !removeHeader && ((this.userInfo && !this.userInfo.subscribed && this.userInfo.userType !== 2) || !this.userInfo)">-->
    <!--      <alm-root-campaign-ribbon (onClosingCampaignRibbon)="onClosingCampaignRibbon($event)"></alm-root-campaign-ribbon>-->
    <!--    </div>-->
    <!--    <div class="gtm-ribbon-mobile-wrapper"-->
    <!--         *ngIf="loyaltyInfo && loyaltyInfo.loyal && loyaltyInfo.referralCount <= loyaltyInfo.inviteesCap && gtmRibbonToggleCount < 5 && !gtmRibbonClosed && !removeHeader">-->
    <!--      <alm-root-gtm-ribbon (onClosingGtmRibbon)="onClosingGtmRibbon($event)"></alm-root-gtm-ribbon>-->
    <!--    </div>-->
  </div>
</header>
