import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";

@Component({
  selector: 'alm-root-error-sncak-bar',
  templateUrl: './error-sncak-bar.component.html',
  styleUrls: ['./error-sncak-bar.component.scss']
})
export class ErrorSncakBarComponent implements OnInit {

  errorMessage?: string;
  action?: {text: string, callback: () => void};

  constructor(@Inject(MAT_SNACK_BAR_DATA) private data: { error: string, action?: {text: string, callback: () => void} }, private _snackRef: MatSnackBarRef<ErrorSncakBarComponent>) {
  }

  ngOnInit(): void {
    this.errorMessage = this.data.error;
    this.action = this.data.action;
  }

  dismiss() {
    this._snackRef.dismiss();
  }

  onclickAction() {
    this.action?.callback();
    this.dismiss();
  }
}
