import {ELanguage} from "@interfaces/common/connector.interface";

export enum LanguageId {
  ENGLISH = 1,
  ARABIC = 2,
}

export enum LanguageName {
  English = "English",
  Arabic = "Arabic"
}

export enum LanguageLocaleName {
  English = "English",
  Arabic = "العربية"
}

export interface ILanguage {
  id: LanguageId;
  name: LanguageName;
  localeName: LanguageLocaleName;
  code: ELanguage;
  direction: ILanguageDirection;
}

export type ILanguageCode = ELanguage;
export type ILanguageDirection = 'rtl' | 'ltr';

export type TranslationMap = {
  [key in ELanguage]: string
}
