import {inject, Injectable} from '@angular/core';
import {TrackingService} from '@services/tracking/tracking.service';
declare global {
  interface Window {
    VWO: any[];
  }
}
declare const _vwo_exp: any;

@Injectable({
  providedIn: 'root'
})
export class VwoExperimentTrackingService {
  trackingService = inject(TrackingService);

  constructor() { }

  public trackExperiments(): void {
    window.VWO = window.VWO || [];
    window.VWO.push(['onVariationApplied', (data: any) => {
      if (!data) {
        return;
      }
      const expId = data[1];
      const variationId = data[2];

      if (typeof(_vwo_exp[expId].comb_n[variationId]) !== 'undefined' && _vwo_exp[expId].GTM) {
        this.trackingService.sendEvent('ab_testing_experiment', {
          event_properties: {
            "CampaignId": expId,
            "CampaignName": _vwo_exp[expId].name,
            "VariationId": variationId,
            "VariationName": _vwo_exp[expId].comb_n[variationId]
          }
        });
      }
    }]);
  };
}
