import {ISimplifiedMentor} from "@interfaces/course/mentor.interface";
import {IPaginationOptions} from "@interfaces/common/pagination.interface";

export interface ISearchMentor extends ISimplifiedMentor{
  courses: {id: number, name: string, permanentLink: string}[]
}

export interface InstantSearchResults {
  wordsDetails:   SearchDetails<string>;
  coursesDetails: SearchDetails<Result>;
  mentorsDetails: SearchDetails<Result>;
}

export enum SearchResultType {
  Word = 'Word',
  Course = 'Course',
  Mentor = 'Mentor',
  LearningProgram = 'LearningProgram',
}

export interface SearchDetails<T> {
  queryId:   string;
  type:      SearchResultType;
  indexName: string;
  results:   T[];
}

export interface Result {
  id:            string;
  permanentLink: string;
  name:          string;
}

export interface EventClickAfterSearch {
  objectId: string;
  eventName: AlgoliaEvents;
  indexName: string;
  position: number;
  queryID: string;
}

export type SearchEntityType = 'COURSE' | 'MENTOR' | 'BUNDLE';

export interface SearchResponse<T = unknown> {
  items: T[];
  pagination: IPaginationOptions
  indexName: string;
  queryId: string;
}

export enum AlgoliaEvents {
  WordClicked = 'Word Clicked',
  CourseClicked = 'Course Clicked',
  MentorClicked = 'Mentor Clicked',
  LearningProgramClicked = 'Bundle Clicked',
}

