import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, switchMap, throwError} from 'rxjs';
import {CookieService} from "ngx-cookie";
import {catchError, map, tap} from "rxjs/operators";
import {AuthV2Service} from "@services/integrations/auth-v2/auth-v2.service";
import {AuthHttpV2Service} from "@services/integrations/auth-v2/auth-http-v2.service";
import {ConnectorV2Service} from "@services/connector/connector-v2.service";
import {RefreshTokenResponse} from "@interfaces/user/user-common.interface";
import {JWT_ACCESS_TOKEN, JWT_REFRESH_TOKEN} from '@constants/cookies.constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService,
              private authService: AuthV2Service,
              private authHttpService: AuthHttpV2Service,
              private connectorService: ConnectorV2Service,
              ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addToken(req)).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.refreshTokenInterceptor(req, next).pipe(
            switchMap(newToken => {
              const clonedRequest = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${newToken}`)
              });
              return next.handle(clonedRequest);
            })
          );
        } else {
          return throwError(error);
        }
      })
    );
  }

  private addToken(req: HttpRequest<any>): HttpRequest<any> {
    const token = this.cookieService.get(JWT_ACCESS_TOKEN);
    if (token) {
      return req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) });
    }
    return req;
  }

  private refreshTokenInterceptor(req: HttpRequest<any>, next: HttpHandler): Observable<RefreshTokenResponse> {
    const refreshToken = this.cookieService.get(JWT_REFRESH_TOKEN);
    const connectorUser = this.connectorService.connectorUser.getValue();
    if (!refreshToken) {
      return throwError(() => 'Refresh token not found in cookies');
    } else if (!connectorUser?.uuid) {
      return throwError(() => 'Consumer UUID is not available in connector service');
    }
    return this.authHttpService.accounts_refreshToken({
      refreshToken,
      userUuid: connectorUser.uuid
    }).pipe(
      tap((newTokenResponse: RefreshTokenResponse): void => {
        this.authService.accounts_storeAccessToken(newTokenResponse.token);
      })
    );
  }
}

