<div class="otp-input">
  <form action="" [formGroup]="otpForm" (keyup.enter)="submitCode()">
    <div class="otp-input_fields" dir="ltr">
      <mat-form-field class="form-group" appearance="outline">
        <input matInput type="text" maxlength="1" formControlName="otp0"
               class="form-control"
               inputmode="numeric" pattern="[0-9]*"
               (keydown)="onKeyDown($event, 0)"
               (input)="onInput($event, 0)"
               (paste)="onPaste($event)">
      </mat-form-field>
      <mat-form-field class="form-group" appearance="outline">
        <input matInput type="text" maxlength="1" formControlName="otp1"
               class="form-control"
               inputmode="numeric" pattern="[0-9]*"
               (keydown)="onKeyDown($event, 1)"
        (input)="onInput($event, 1)">
      </mat-form-field>
      <mat-form-field class="form-group" appearance="outline">
        <input matInput type="text" maxlength="1" formControlName="otp2"
               class="form-control"
               inputmode="numeric" pattern="[0-9]*"
               (keydown)="onKeyDown($event, 2)"
        (input)="onInput($event, 2)">
      </mat-form-field>
      <mat-form-field class="form-group" appearance="outline">
        <input matInput type="text" maxlength="1" formControlName="otp3"
               class="form-control"
               inputmode="numeric" pattern="[0-9]*"
               (keydown)="onKeyDown($event, 3)"
        (input)="onInput($event, 3)">
      </mat-form-field>
    </div>
    <div class="otp-input_errors-container" *ngIf="getFormFieldsError([
        otpForm.controls.otp0, otpForm.controls.otp1,
        otpForm.controls.otp2, otpForm.controls.otp3]) as message">
      <mat-error class="otp-input_errors">
        {{'opt_input.errors' | translate | translateCut: message - 1}}
      </mat-error>
    </div>
  </form>
</div>
