<ul class="user-menu">
  <li *ngIf="nextLanguage" [dir]="currentLanguage.id == 2 ? 'ltr' : 'rtl'">
    <button class="icon-item language-link" (click)="onLanguageChange(nextLanguage.code)">
      <svg *ngIf="currentLanguage.id === 2">
        <use href="assets/images/sprite.svg#en"/>
      </svg>
      <svg *ngIf="currentLanguage.id === 1">
        <use href="assets/images/sprite.svg#ar"/>
      </svg>
    </button>
  </li>
  <li>
    <button class="btn btn-link icon-item" (click)="onThemeChange()">
      <span class="sr-only">{{'user_control_menu.light_mode'}}</span>
      <svg>
        <use href="assets/images/sprite.svg#theme"/>
      </svg>
    </button>
  </li>
  <li *ngIf="userInfo" class="notification-icon">
    <button [matMenuTriggerFor]="notificationMenu" #notificationMenuTrigger="matMenuTrigger" [dir]="menuDirection"
            (menuOpened)="onNotificationMenuOpen(notificationMenuTrigger)"
            class="icon-item">
      <svg>
        <use href="assets/images/sprite.svg#bell"/>
      </svg>
      <span *ngIf="unReadNotificationCount" class="user-menu__notification-count" dir="ltr">
        {{unReadNotificationCount}}
      </span>
    </button>
    <mat-menu #notificationMenu="matMenu" class="notification-menu-container">
      <ng-template matMenuContent>
        <alm-root-header-notification-menu (onCloseMenu)="closeNotificationMenu()"
                                           [isInNotificationMenu]="true"
                                           [unReadNotificationCount]="unReadNotificationCount"
        ></alm-root-header-notification-menu>
      </ng-template>
    </mat-menu>
  </li>
  <li *ngIf="userInfo" class="user-menu__profile-image-container">
    <img class="user-menu__profile-image user-menu__profile-image-dark" [imageLoader]="getProfileImage(userInfo.uuid)"
         [src]="updatedImage" [isLazyLoadedImage]="false"
         errorImage="assets/images/default-avatar-placeholder-dark.jpg" alt="Avatar" [matMenuTriggerFor]="userProfileMenu"
         #profileMenuTrigger="matMenuTrigger" (menuOpened)="onProfileMenuOpen(profileMenuTrigger)"
         [dir]="menuDirection">
    <img class="user-menu__profile-image user-menu__profile-image-light" [imageLoader]="getProfileImage(userInfo.uuid)"
         [src]="updatedImage" [isLazyLoadedImage]="false"
         errorImage="assets/images/default-avatar-placeholder-light.jpg" alt="Avatar" [matMenuTriggerFor]="userProfileMenu"
         #profileMenuTrigger="matMenuTrigger" (menuOpened)="onProfileMenuOpen(profileMenuTrigger)"
         [dir]="menuDirection">
    <span *ngIf="numberOfUnreadMessages" class="user-menu__messages-count" dir="ltr">
        {{numberOfUnreadMessages}}
      </span>
    <mat-menu #userProfileMenu="matMenu" class="user-profile-menu-container" yPosition="below">
      <ng-template matMenuContent>
        <alm-root-header-user-menu [isB2bUser]="isB2bUser"
                                   [userAvatar]="getProfileImage(userInfo.uuid)" (onItemSelect)="profileMenuTrigger.closeMenu()"
                                    [userInfo]="userInfo" [numberOfUnreadMessages]="numberOfUnreadMessages"></alm-root-header-user-menu>
      </ng-template>
    </mat-menu>
  </li>
  <ng-container *ngIf="!userInfo">
    <li class="login-item">
      <a class="login" [almCustomHref]="loginLink"
         (clickEvent)="navigateToLogin()">
        {{'user_control_menu.login' | translate}}</a>
    </li>
    <li>
      <a
        class="btn-danger btn-alm-filled user-menu_signup_link"
        (clickEvent)="onNavigationToSignUp()" [almCustomHref]="signupLink">
        {{'user_control_menu.sign_up' | translate}}
      </a>
    </li>
  </ng-container>
</ul>
