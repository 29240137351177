import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {LanguageControlService} from '@services/language/language-control.service';
import ar from './i18n/ar.json';
import en from './i18n/en.json';
import {UntypedFormControl} from '@angular/forms';
import {AlgoliaAnalyticsService} from '@services/search/algolia-analytics.service';
import {debounce, of, Subscription, timer} from 'rxjs';
import {MatAutocomplete, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {Router} from '@angular/router';
import {SearchService} from "@services/integrations/search/search.service";
import {
  AlgoliaEvents,
  InstantSearchResults,
  Result,
  SearchDetails,
  SearchResultType
} from "@interfaces/search/search.interface";
import {TrackingService} from "@services/tracking/tracking.service";
import {SearchType} from "@services/tracking/tracking.interface";

const MIN_SEARCH_LENGTH = 3;
const MAX_RESULTS_PER_TYPE = 3;
const SEARCH_DEBOUNCE_TIME = 300;

@Component({
  selector: 'alm-root-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss']
})
export class HeaderSearchComponent implements OnInit, OnDestroy {

  @Input() autoFocus: boolean = false;
  @Output() closeSearchInput: EventEmitter<any> = new EventEmitter<any>();
  searchInput: UntypedFormControl;
  searchHistory: string[] = [];
  searchSuggestions?: InstantSearchResults;
  currentLang: string;
  searchAutocomplete?: MatAutocomplete;
  private autocompleteClassAdded: boolean = false;
  private subscriptions: Subscription[] = [];
  private query: string;

  constructor(
    private languageControl: LanguageControlService,
    private algoliaSearchService: AlgoliaAnalyticsService,
    private router: Router,
    private searchService: SearchService,
    private trackingService: TrackingService,
  ) {
    this.languageControl.setTranslations('ar', ar);
    this.languageControl.setTranslations('en', en);
    this.currentLang = this.languageControl.currentLanguage.getValue().code;
    this.searchInput = new UntypedFormControl('');
  }

  ngOnInit(): void {
    let searchValueSub = this.searchInput.valueChanges
      .pipe(
        debounce(value => {
          return value?.length >= MIN_SEARCH_LENGTH ? timer(SEARCH_DEBOUNCE_TIME) : of(null)
        })
      ).subscribe((value) => {
      if (value.length < MIN_SEARCH_LENGTH) {
        this.getHistorySearchResults(value);
      } else {
        this.getInstantSuggestions(value);
      }
    });
    let langSub = this.languageControl.currentLanguage.subscribe((lang) => {
      this.currentLang = lang.code;
    })
    this.subscriptions.push(searchValueSub);
    this.subscriptions.push(langSub);
  }

  private getHistorySearchResults(value: string) {
    this.searchSuggestions = undefined;
    this.searchService.searchInLocalHistory(value).then((words) => {
      if (words) {
        this.searchHistory = words;
      } else {
        this.searchHistory = [];
      }
    });
  }

  private getInstantSuggestions(query: string) {
    this.searchHistory = [];
    this.query = query;
    this.searchService.instantSearch(query).then((instantSearchResults: InstantSearchResults) => {
      this.searchSuggestions = instantSearchResults;
      this.sendSearchInitiatedEvent(query, instantSearchResults);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.map((sub) => {
      sub.unsubscribe();
    });
    this.searchSuggestions = undefined;
    this.searchHistory = [];
  }

  onHistorySearchDelete(value: string, $event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.searchService.deleteValueFromLocalSearchHistory(value);
    if (this.searchInput.value.length < 3) {
      this.getHistorySearchResults(this.searchInput.value);
    }
  }

  initSearchHistory() {
    if (this.searchInput.value.length < 3) {
      this.getHistorySearchResults(this.searchInput.value);
    }
  }

  onAutocompleteOpened(searchAutocomplete: MatAutocomplete) {
    this.searchAutocomplete = searchAutocomplete;
    if (searchAutocomplete.panel?.nativeElement?.parentElement) {
      if (!this.autocompleteClassAdded) {
        searchAutocomplete.panel.nativeElement.parentElement.classList.add('header-search__autocomplete-container');
        this.autocompleteClassAdded = true;
      }
    }
  }

  private resetSearchAutocomplete(trigger: MatAutocompleteTrigger) {
    if (trigger) {
      trigger.closePanel();
    }
    this.closeSearchInput.emit();
    this.searchInput.setValue('');
  }

  onSearchSubmit(searchAutocompleteTrigger: MatAutocompleteTrigger) {
    if (this.searchInput.value) {
      let query = this.searchInput.value;
      this.router.navigate(['/search'], {queryParams: {'q': query}});
      this.resetSearchAutocomplete(searchAutocompleteTrigger);
    }
  }

  handleKeywordClick(trigger: MatAutocompleteTrigger, Keyword: string, searchData: SearchDetails<string>, index: number) {
    this.algoliaSearchService.sendEventClickAfterSearch({
      eventName: AlgoliaEvents.WordClicked,
      indexName: searchData.indexName,
      queryID: searchData.queryId,
      objectId: Keyword,
      position: index
    });
    this.onSearchSubmit(trigger);
  }

  handleCourseClick(trigger: MatAutocompleteTrigger, course: Result, searchData: SearchDetails<Result>, index: number) {
    if (course.permanentLink) {
      this.router.navigate([`/courses/${course.permanentLink}`]);
      this.resetSearchAutocomplete(trigger);
    }
    this.algoliaSearchService.sendEventClickAfterSearch({
      eventName: AlgoliaEvents.CourseClicked,
      indexName: searchData.indexName,
      queryID: searchData.queryId,
      objectId: course.id,
      position: index
    });
    this.trackingService.sendEvent('search_result_clicked', {
      event_properties: {
        search_type: SearchType.SEARCH_PAR,
        result_position: index + 1,
        result_type: SearchResultType.Course,
        search_keyword: this.query,
        number_of_results: searchData.results.length,
      }
    });
  }

  handleMentorClick(trigger: MatAutocompleteTrigger, mentor: Result, searchData: SearchDetails<Result>, index: number) {
    if (mentor.permanentLink) {
      this.router.navigate([`/mentors/${mentor.permanentLink}`]);
      this.resetSearchAutocomplete(trigger);
    }
    this.algoliaSearchService.sendEventClickAfterSearch({
      eventName: AlgoliaEvents.MentorClicked,
      indexName: searchData.indexName,
      queryID: searchData.queryId,
      objectId: mentor.id,
      position: index
    });
    this.trackingService.sendEvent('search_result_clicked', {
      event_properties: {
        search_type: SearchType.SEARCH_PAR,
        result_position: index + 1,
        result_type: SearchResultType.Mentor,
        search_keyword: this.query,
        number_of_results: searchData.results.length,
      }
    });
  }

  private sendSearchInitiatedEvent(query: string, instantSearchResults: InstantSearchResults) {
    const courseResults = instantSearchResults.coursesDetails;

    this.trackingService.sendEvent('search_initiated', {
      event_properties: {
        search_type: SearchType.SEARCH_PAR,
        search_keyword: query,
        result_type: courseResults.type,
        number_of_results: courseResults.results.length,
      }
    });
  }
}
