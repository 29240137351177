import { EnvironmentNames } from '@interfaces/environment/environment.interface';
import { AlmentorClientEnvironment } from "@interfaces/common/almentor-client-environment.interface";

export const environment: AlmentorClientEnvironment = {
  production: true,
  environmentName: EnvironmentNames.production,
  infrastructure: 'Production',
  apiGatewayUrl: 'https://api-gateway.almentor.net/api',
  platforms: {
    base: { link: 'almentor.net' },
    anonymous: { link: 'https://www.almentor.net' },
    b2c: { link: 'https://app.almentor.net', code: '00' },
    admin: { link: 'https://admin.almentor.net', code: '02' },
    dashboard: { link: 'https://mentor-dashboard.almentor.net', code: '03' },
    ld: { link: 'https://orgadmin.almentor.net', code: '05' },
    preview: { link: 'https://preview.almentor.net', code: '02' },
    payment: { link: 'https://payment.almentor.net', code: '2D' },
    marketing: { link: 'https://business.almentor.net' },
    sso: { link: 'https://account.almentor.net' },
    futureX: { link: 'https://futurex.almentor.net', code: '5D' },
    sandbox: { link: 'https://sandbox.almentor.net', code: '05' },
  },
  certificateValidator: 'https://validate-certificate.almentor.net',
  cdnUrl: 'https://cdn-share.almentor.net',
  connectorUrl: 'https://auth-share.almentor.net',
  facebookId: '510357686322993',
  googleId: '987757772688-f5k9ug05jtlcjnal51r382gmum7s1gie.apps.googleusercontent.com',
  paypalAppId: "AXxn0nMkifvkb_fJ_4Z73NqzMc6NyF_b-zoFjtepFNRzp-Y4w4o6nGSNk-2GtoYSg2Cc-5wodQJNrnB4",
  bcPlayerConfig: {
    accountId: '6415782337001',
    playerId: 'jZNdE49vY'
  },
  firebase: {
    apiKey: "AIzaSyCQL6NwkAssWuiUinHKUrEd_cpwbDDDwbo",
    authDomain: "almentor-revamp-production.firebaseapp.com",
    projectId: "almentor-revamp-production",
    storageBucket: "almentor-revamp-production.appspot.com",
    messagingSenderId: "570042542124",
    appId: "1:570042542124:web:71eda169ca32b39dda8989",
    measurementId: "G-ME9JVFHL0B"
  },
  algolia: {
    apiKey: "da88ed68c2dcd599792d83b0f2c1505e",
    appId: "FRYEJSU9VH",
    indices: {
      keywords: "revamp_prod_Keywords",
      courses: "revamp_prod_Course",
      publicAndPrivateCourses: "revamp_prod_Public_And_Private_Course",
      publicAndPrivateCoursesMentors: "revamp_prod_Public_And_Private_Course_Mentor",
      bundles: "revamp_prod_Bundle",
      mentors: "revamp_prod_Mentor"
    }
  },
  gtmID: "GTM-W2Z8933",
  amplitudeApiKey: "781ab44e25ee7ed6f3087bf309b4ff15",
  braze: {
    apiKey: '18e6f6b3-5ba4-45af-967e-9f0c90cce1a9',
    baseUrl: 'sdk.fra-01.braze.eu'
  },
  isrServerConfig: {
    maxAge: 3600 * 6, // seconds null | 0 | > 0
    staleDuration: 3600 * 12, // seconds null | 0 | > 0,
    errorDuration: 3600 * 24, // seconds null | 0 | > 0
  },
  mixpanel: {
    b2cToken: 'df04c80eff821c07529540963fca1d83',
    b2bToken: '6ed5596169bb7121ed716d68e88975b8',
    debug: false
  },
  paymobIframes: {
    standalone: '707941',
    embedded: '707942'
  },
  checkout:{
    cdn: 'https://cdn.checkout.com/js/framesv2.min.js',
    publicKey: 'pk_vb35igejojmto525find3gtps4t',
  },
  sandbox : {
    ldCredentials: {
      email: 'sandbox@almentor.net',
      password: 'Sandbox@123'
    }
  }
};
