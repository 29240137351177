import {AfterViewInit, Component, inject, Inject, PLATFORM_ID} from '@angular/core';
import {environment} from '@environments/environment';
import {ChildActivationEnd, NavigationEnd, Router} from '@angular/router';
import {IUserInfo, SubscriptionStatus} from '@interfaces/authorized-user/user.interface';
import {BehaviorSubject} from 'rxjs';
import {LanguageControlService} from '@services/language/language-control.service';
import {NotificationService} from '@services/integrations/notification/notification.service';
import {ILoyaltyInfo} from '@interfaces/user/user-common.interface';
import {ChatService} from '@services/integrations/chat/chat.service';
import {GoogleTagManagerService} from '@services/marketing/google-tag-manager.service';
import {ZendeskService} from '@services/zendesk/zendesk.service';
import {AmplitudeService} from '@services/marketing/amplitude.service';
import {ConnectorV2Service} from '@services/connector/connector-v2.service';
import {isPlatformBrowser} from '@angular/common';
import {filter, skip, take} from 'rxjs/operators';
import {BrazeService} from '@services/marketing/braze/braze.service';
import {UserManagerService} from '@services/user/user-manager.service';
import {UserAfterLoginActionsService} from '@services/user/user-after-login-actions.service';
import {SentryService} from '@services/sentry/sentry.service';
import {NotificationSoftRequestService} from '@components/notification-soft-request/notification-soft-request.service';
import {EncryptionService} from '@services/request-token/encryption.service';
import {getAccumulatedRouteData} from '@services/routing/routing-helper/routing-helper';
import {IRoutingData} from '@services/routing/routing-helper/routing.interface';
import {TrackingService} from '@services/tracking/tracking.service';
import {TrackingDataService} from '@services/tracking/tracking-data.service';
import {UserService} from '@services/integrations/user/user.service';
import {LanguageId} from '@interfaces/common/language.interface';
import commonAr from '@shared-translations/http-errors/common-ar.json';
import commonEn from '@shared-translations/http-errors/common-en.json';
import subscriptionAr from '@shared-translations/http-errors/subscription-ar.json';
import subscriptionEn from '@shared-translations/http-errors/subscription-en.json';
import catalogAr from '@shared-translations/http-errors/catalog-ar.json';
import catalogEn from '@shared-translations/http-errors/catalog-en.json';
import {MarketingService} from '@services/integrations/marketing/marketing.service';
import {PAYMENT_STATUS_PATH} from '@constants/http.constants';
import {VwoExperimentTrackingService} from '@services/tracking/vwo-experiment-tracking.service';

@Component({
  selector: 'alm-authorized-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  private marketingService = inject(MarketingService);
  private vwoExperimentTrackingService = inject(VwoExperimentTrackingService);

  hideMainFooter: boolean = false;
  showSimplifiedHeader: boolean = true;
  userInfoSub: BehaviorSubject<IUserInfo | null>;
  unreadNotificationCount: number = 0;
  numberOfUnreadMessages: number = 0;
  onNavigationChange: boolean = false;
  loyaltyInfo?: ILoyaltyInfo = {
    loyal: false,
    inviteesCap: 0,
    prizeCap: 0,
    prizeCode: '',
    referralCode: '',
    referralCount: 0
  };
  showAppAnnouncement: boolean = false;

  constructor(
              private router: Router,
              private userManagerService: UserManagerService,
              private connectorService: ConnectorV2Service,
              private gtmService: GoogleTagManagerService,
              private languageControl: LanguageControlService,
              private zendeskService: ZendeskService,
              private chatService: ChatService,
              private amplitudeService: AmplitudeService,
              private brazeService: BrazeService,
              private notificationService: NotificationService,
              @Inject(PLATFORM_ID) private platformId: any,
              private afterLoginUserActions: UserAfterLoginActionsService,
              private sentryService: SentryService,
              private trackingService: TrackingService,
              private notificationSoftRequestService: NotificationSoftRequestService,
              private encryptionService: EncryptionService,
              private trackingData: TrackingDataService,
              private userService: UserService,
              ) {
    this.userInfoSub = this.userManagerService.userInfo;
    this.languageControl.setTranslations('ar', {...commonAr,...subscriptionAr,...catalogAr,});
    this.languageControl.setTranslations('en', {...commonEn,...subscriptionEn,...catalogEn,});
  }

  ngAfterViewInit(): void {

    this.checkCourseViewerPresent();

    this.userInfoSubscriber();

    this.notificationNumberUpdater();

    this.scriptsLoader();

    this.connectorInitializer();

    this.afterLoginUserActions.handleDemographicsPopup();

    this.vwoExperimentTrackingService.trackExperiments();
  }

  private userInfoSubscriber() {
    if (isPlatformBrowser(this.platformId)) {
      this.brazeService.initBraze();
      this.notificationSoftRequestService.initWithDelay(300);
      this.userManagerService.userInfo.subscribe(user => {
        if (user) {
          this.getUnreadNotificationCount();
          this.getUnreadMessagesCount();
          this.brazeService.setUserId(user.uuid);
          this.sentryService.setUser({
            id: user.uuid,
            email: user.email,
            username: user.name
          });
          this.checkDeepLinkingTracking();
          this.updateUserTrackingInfo(user);
        }
      });
    }
  }

  private notificationNumberUpdater() {
    this.notificationService.onNotificationUpdate.subscribe((value) => {
      if (this.userManagerService.userInfo.getValue()) {
        this.getUnreadNotificationCount();
      }
    });

    this.chatService.onChatStatusUpdate.subscribe((value) => {
      if (this.userManagerService.userInfo.getValue()) {
        this.getUnreadMessagesCount();
      }
    });
  }

  private scriptsLoader() {
    this.gtmService.loadGtm(environment.gtmID);
    this.amplitudeService.loadAmplitude(environment.amplitudeApiKey, this.userInfoSub.getValue()?.uuid);
    this.zendeskService.loadZendeskWidget('ca70126b-03f9-45fb-8bb3-daead16e4adf');
  }

  private connectorInitializer() {
    this.connectorService.connectorLoaded.pipe(skip(1)).toPromise().then((loaded) => {
      this.connectorService.connectorUser.subscribe((user) => {
        if (!user && isPlatformBrowser(this.platformId)) {
          setTimeout(() => {
            window.location.replace(`${environment.platforms.anonymous.link}/home`);
          }, 500);
        }
      });
    });
  }

  checkCourseViewerPresent() {
    this.router.events.pipe(skip(1)).subscribe((event) => {
      if (event instanceof ChildActivationEnd) {
        const data = getAccumulatedRouteData(event);
        this.onNavigationDataChanged(data);
      }
    });
  }

  onNavigationDataChanged(data: IRoutingData) {
    this.onNavigationChange = true;
    this.hideMainFooter = data.hideMainFooter;
    this.showSimplifiedHeader = data.showSimplifiedHeader;
    if (this.hideMainFooter) {
      let support = document.getElementById('launcher');
      if (support) {
        support.style.display = 'none';
      }
    } else {
      let support = document.getElementById('launcher');
      if (support) {
        support.style.display = 'block';
      }
    }
    setTimeout(() => {
      this.onNavigationChange = false;
    }, 200);
    window.scroll(0, 0);
  }

  private getUnreadNotificationCount() {
    this.notificationService.getUnreadNotificationsCount('header-notification').then((unreadNotificationCount) => {
      this.unreadNotificationCount = unreadNotificationCount.count;
    });
  }

  private getUnreadMessagesCount() {
    this.chatService.getUnreadMsg('header-messages', true).then((unreadMessagesCount) => {
      this.numberOfUnreadMessages = unreadMessagesCount.count;
    });
  }

  private async updateUserTrackingInfo(user: IUserInfo) {
    let subscriptionType = null;
    let subscriptionId = null;

    if (user.subscriptionStatus === SubscriptionStatus.Subscribed) {
      const userSubscription = await this.userService.getUserSubscriptionPlan('tracking', false, LanguageId.ENGLISH);
      subscriptionId = userSubscription.id;
      subscriptionType = userSubscription.name;
    } else {
      subscriptionType = SubscriptionStatus[user.subscriptionStatus];
    }

    this.trackingData.setSuperProperties({
      // set hashed name and mail for DataHash to use them for PII
      th_capi_fn: this.encryptionService.normalizeAndHashString(user.name),
      th_capi_em: this.encryptionService.normalizeAndHashString(user.email),
      subscription_type: subscriptionType,
    });
    const anonymousUserProperties = this.trackingData.getStoredAnonymousUserProperties();

    this.trackingService.sendEvent('user_details', {
      event_properties: {
        user_uuid: user.uuid,
        user_id: user.email,
        user_name: user.name,
        subscription_type: subscriptionType,
        subscription_id: subscriptionId,
      },
      user_properties: anonymousUserProperties,
    });

    this.trackingData.removeStoredAnonymousUserProperties();
  }

  private checkDeepLinkingTracking() {
    const { firstAuthAfterSubscription } = this.userManagerService.userInfo.getValue();
    if (!firstAuthAfterSubscription) return;
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      take(1),
    ).subscribe(navigationEnd => {
      if (navigationEnd.urlAfterRedirects.startsWith(`/${PAYMENT_STATUS_PATH}`)) return;
      this.marketingService.getAdUserOrderDetails('app-component');
    });
  }
}
