import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SkeletonPlaceholderComponent } from './skeleton-placeholder.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';



@NgModule({
  declarations: [SkeletonPlaceholderComponent],
  exports: [
    SkeletonPlaceholderComponent
  ],
  imports: [
    CommonModule,
    NgxSkeletonLoaderModule
  ]
})
export class SkeletonPlaceholderModule { }
