import {IPaymentMethod, PaymentMethods} from '@interfaces/common/payment.interface';

export interface ISubscriptionPlan {
  id: number;
  currency: string;
  description: string;
  duration: number;
  finalPrice: number;
  finalPriceUSD: number;
  name: string;
  nameEn: string;
  originalPrice: number;
  originalPriceUSD: number;
  message?: string;
  amountPerMonth?: number;
  originalAmountPerMonth?: number;
  discountItems?: DiscountItem[];
  countryName: string;
  paymentMethods: PaymentMethods[];
  price: number;
}

export interface DiscountItem {
  discountSourceId: number;
  discountIdentifier: string;
  discountAmount: number;
}

export interface UserSubscriptionPlan extends ISubscriptionPlan{
  isCancel?: boolean;
  isChanged?: boolean;
  renewDate?: string;
  paymentMethod?: PaymentMethods;
}

export interface SubscriptionPlans {
  countryCode: string;
  plans: SubscriptionPlan[];
}

export interface SubscriptionPlan {
  planId:          number;
  name:            string;
  nameEn:          string;
  subtitle:        string | null;
  discountMessage: string | null;
  duration:        number;
  price:           SubscriptionPlanPrice;
  paymentMethods:  IPaymentMethod[];
}

export interface SubscriptionPlanPrice {
  currency:                    string;
  price:                       number;
  originalPrice:               number;
  pricePerMonth:               number;
  originalPricePerMonth:       number;
  priceInUsd:                  number;
  originalPriceInUsd:          number;
  pricePerMonthInUsd:          number;
  originalPricePerMonthInUsd:  number;
}

export interface ISubscriptionPlanWithExtraInfo extends ISubscriptionPlan {
  isAnnualPlan: boolean;
}

export interface ICourseEnrollRequest {
  itemId: number;
  itemType: number;
  originalAmountUSD: number;
  finalAmountUSD: number;
  localFinalAmount: number;
}

export interface IUserPlanSelection {
  coupon?: string;
  plan?: number ;
  paymentMethodId?: PaymentMethods;
}

export interface IOnSelectPlanEvent {
  plan: ISubscriptionPlanWithExtraInfo;
  isSelectedByUser?: boolean;
}

export enum DcbOperator {
  //egypt
  VodafoneDcb = 'vodafone-eg',
  OrangeDcb = 'orange-eg',
  EtisalatDcb = 'eand-eg',
  WeDcb = 'we-eg',

  // KSA
  ZainDcb = 'zain-sa'
}

export type DcbPaymentMethod = keyof typeof DcbOperator;



export interface DcbSubscriptionPlan {
  planId:                 string;
  name:                   string;
  subtitle:               string | null;
  discountMessage:        string | null;
  duration:               number;
  durationText:           string;
  freeTrialDuration:      number;
  freeTrialDurationText:  string;
  price:                  SubscriptionPlanPrice;
  cancellationInstructions: string;
}

export interface DcbOperators {
  name: DcbPaymentMethod
  plans: DcbSubscriptionPlan[]
}

export interface DcbPaymentOptions {
  countryCode: string;
  countryName: string;
  dialingCountryCode: string;
  paymentMethods:  DcbOperators[];
}

export enum PlanDurations {
  Month = 30,
  Quarter = 90,
  Year = 365,
}
