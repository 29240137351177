import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@guards/auth.guard';
import {DevicesGuard} from '@guards/devices.guard';
import {SubscriptionPageGuard} from '@guards/subscription-page.guard';
import {addLanguageGuard, generateLanguageRoutes, getWildcardRoutes,} from '@services/routing/routing-helper/routing-helper';
import {AVAILABLE_LANGUAGES} from '@constants/ui.constants';
import {DEFAULT_HOME_PATH, NOT_FOUND_PATH, PAYMENT_STATUS_PATH} from '@constants/http.constants';
import {CustomRoutes} from '@services/routing/routing-helper/routing.interface';
import {DcbOperator, DcbPaymentMethod} from '@interfaces/subscription/subscription.interface';
import {UserInfoGuard} from '@guards/user-info.guard';


const routes: CustomRoutes = [
  //Only in Authorized
  {
    path: DEFAULT_HOME_PATH, loadChildren: () => import('@authorized-modules/home-v2/home-v2.module')
      .then(mod => mod.HomeV2Module),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: true}
  },
  {
    path: 'profile', loadChildren: () => import('@authorized-modules/user-profile/user-profile.module')
      .then(mod => mod.UserProfileModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: false}
  },
  {
    path: 'my-progress', loadChildren: () => import('@authorized-modules/my-progress/my-progress.module')
      .then(mod => mod.MyProgressModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: false}
  },
  {
    path: 'settings', loadChildren: () => import('@authorized-modules/user-settings/user-settings.module')
      .then(mod => mod.UserSettingsModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: false}
  },
  {
    path: 'messages', loadChildren: () => import('@authorized-modules/user-messages/user-messages.module')
      .then(mod => mod.UserMessagesModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: false}
  },
  {
    path: 'notifications', loadChildren: () => import('@authorized-modules/notifications/notifications.module')
      .then(mod => mod.NotificationsModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: false}
  },
  {
    path: 'invite-earn', loadChildren: () => import('@authorized-modules/invite-earn/invite-earn.module')
      .then(mod => mod.InviteEarnModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: false}
  },
  {
    path: 'gtm',
    loadChildren: () => import('@authorized-modules/gtm/gtm.module')
      .then(mod => mod.GtmModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: false}
  },
  {
    path: 'cart', redirectTo: `/${DEFAULT_HOME_PATH}`
  },
  {
    path: PAYMENT_STATUS_PATH, loadChildren: () => import('@authorized-modules/payment-status/payment-status.module')
      .then(mod => mod.PaymentStatusModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: false}
  },
  {
    path: 'saved-list', loadChildren: () => import('@authorized-modules/saved-list/saved-list.module')
      .then(mod => mod.SavedListModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: false}
  },
  {
    path: 'viewer',
    loadChildren: () => import('@course-viewer/course-viewer.module')
      .then(mod => mod.CourseViewerModule),
    canActivate: [AuthGuard],
    data: {
      redirectToAnonymous: true,
      hideMainFooter: true,
    }
  },

  //Shared from authorized and anonymous
  {
    path: 'search',
    loadChildren: () => import('@authorized-modules/search/search.module')
      .then(mod => mod.SearchModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: true}
  },
  {
    path: 'courses',
    loadChildren: () => import('@authorized-modules/courses/courses.module')
      .then(mod => mod.CoursesModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: true}
  },
  {
    path: 'categories',
    loadChildren: () => import('@authorized-modules/categories/categories.module')
      .then(mod => mod.CategoriesModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: true}
  },
  {
    path: 'learning-programs',
    loadComponent: () => import('@components/learning-programs/learning-programs-list/learning-programs-list.component')
      .then(mod => mod.LearningProgramsListComponent),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: true}
  },
  {
    path: 'bundles/:permanentLink',
    loadComponent: () => import('@components/learning-programs/learning-program-details/learning-program-details.component')
      .then(mod => mod.LearningProgramDetailsComponent),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: true}
  },
  {
    path: 'mentors',
    loadChildren: () => import('@authorized-modules/mentors/mentors.module')
      .then(mod => mod.MentorsModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: true}
  },
  {
    path: 'partners',
    loadChildren: () => import('@authorized-modules/partners/partners.module')
      .then(mod => mod.PartnersModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: true}
  },
  {
    path: 'subscription',
    redirectTo: '/plans',
  },
  {
    path: 'subscription-plans',
    redirectTo: '/plans',
  },
  {
    path: 'plans',
    loadComponent: () => import('@components/subscription/subscription.component')
      .then(mod => mod.SubscriptionComponent),
    canActivate: [
      AuthGuard,
      SubscriptionPageGuard
    ],
    data: {
      redirectToAnonymous: true,
    }
  },
  {
    path: 'checkout',
    loadComponent: () => import('@components/subscription/subscription-checkout/subscription-checkout.component')
      .then(mod => mod.SubscriptionCheckoutComponent),
    canActivate: [
      AuthGuard,
      SubscriptionPageGuard
    ],
    data: {
      redirectToAnonymous: true,
    }
  },
  {
    path: 'offers',
    loadChildren: () => import('@authorized-modules/offers/offers.module')
      .then(mod => mod.OffersModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: true}
  },
  {
    path: 'become-mentor',
    loadChildren: () => import('@authorized-modules/become-mentor/become-mentor.module')
      .then(mod => mod.BecomeMentorModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: true}
  },
  {
    path: 'devices',
    loadChildren: () => import('@authorized-modules/device-management/device-management.module')
      .then(mod => mod.DeviceManagementModule),
    canActivate: [DevicesGuard],
    data: {
      showSimplifiedHeader: true
    }
  },
  {
    path: 'user-info',
    loadComponent: () => import('@authorized-modules/user-info/user-info.component')
      .then(mod => mod.UserInfoComponent),
    canActivate: [UserInfoGuard],
    data: {
      showSimplifiedHeader: true,
    }
  },
  {
    path: 'my-courses',
    loadChildren: () => import('@authorized-modules/my-courses/my-courses.module')
      .then(mod => mod.MyCoursesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'my-certificates',
    loadChildren: () => import('@b2b-modules/my-certificates/my-certificates.module')
      .then(mod => mod.MyCertificatesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'certificate',
    loadChildren: () => import('@pages/certificate-page/certificate-page.module')
      .then(mod => mod.CertificatePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'my-notes',
    loadChildren: () => import('@b2b-modules/my-notes/my-notes.module')
      .then(mod => mod.MyNotesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'invite-to-organization',
    loadComponent: () => import('@pages/migration-invitation/migration-invitation.component')
        .then(mod => mod.MigrationInvitationComponent),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: true}
  },
  {
    path: NOT_FOUND_PATH,
    loadChildren: () => import('@authorized-modules/not-found/not-found.module')
      .then(mod => mod.NotFoundModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: true}
  },
  {
    path: 'dcb',
    loadComponent: () => import('@components/dcb-payment/dcb-all-operators/dcb-all-operators.component')
      .then(mod => mod.DcbAllOperatorsComponent),
    data: {
      redirectToAnonymous: true,
    },
    canActivate: [
      AuthGuard,
      SubscriptionPageGuard
    ],
  },
  ...Object.entries(DcbOperator).map(([OperatorId, operatorName]) => {
    return {
      path: `dcb/${operatorName}`,
      loadComponent: () => import('@components/dcb-payment/dcb-subscription/dcb-subscription.component')
        .then(mod => mod.DcbSubscriptionComponent),
      data: {
        redirectToAnonymous: true,
        paymentMethod: OperatorId as DcbPaymentMethod
      },
      canActivate: [
        AuthGuard,
        SubscriptionPageGuard
      ],
    }
  }),
];

addLanguageGuard(routes);
const languageRoutes: Routes = generateLanguageRoutes(AVAILABLE_LANGUAGES, routes);
const wildcardRoutes: Routes = getWildcardRoutes(DEFAULT_HOME_PATH, AVAILABLE_LANGUAGES);

@NgModule({
  imports: [
    RouterModule.forRoot([
      ...routes,
      ...languageRoutes,
      ...wildcardRoutes
    ], {
      onSameUrlNavigation: 'ignore',
      scrollPositionRestoration: 'top',
      initialNavigation: 'enabledBlocking'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
