import {Injectable} from '@angular/core';
import {CustomHttpService} from '@services/request-token/custom-http.service';
import {IDemographics, IInterest} from '@interfaces/common/auth.interface';
import {IUserInfo} from '@interfaces/authorized-user/user.interface';
import {
  IAccountInfo,
  IB2bBannerInfo,
  ILoyaltyInfo,
  IPersonalInfo,
  IPurchaseLog,
  ISocialMediaProfiles,
  IUserPlatform
} from '@interfaces/user/user-common.interface';
import {ISubscriptionPlan, UserSubscriptionPlan} from '@interfaces/subscription/subscription.interface';
import {IDevice} from '@interfaces/common/device.interface';

@Injectable({
  providedIn: 'root'
})
export class UserHttpService {

  constructor(private customHttpService: CustomHttpService) {
  }

  getAllInterestsByLanguageId(sender: string, languageId: number): Promise<IInterest[]> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/getUser/api/users/GetAllInterestsByLanguageId',
      sender: sender,
      receiver: 'GetAllInterestsByLanguageId',
      body: {languageId: languageId},
    });
  }

  saveUserInterests(sender: string, interests: number[]): Promise<any> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/addEditUser/api/users/SaveUserInterest',
      sender: sender,
      receiver: 'SaveUserInterest',
      body: {rankedTagIds: interests}
    });
  }

  saveUserDemographics(sender: string, demographics: IDemographics): Promise<any> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/addEditUser/api/users/UpdateUserData',
      sender: sender,
      receiver: 'UpdateUserData',
      body: demographics
    });
  }

  authorizeUser(sender: string): Promise<any> {
    return this.customHttpService.sendRequest({
      endpoint: 'orchestrator/api/auth/authorize',
      sender: sender,
      receiver: 'authorize',
      body: {}
    }, true);
  }

  getUserInfo(): Promise<IUserInfo> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/getUser/api/users/mini/info',
      sender: 'app',
      receiver: 'info',
      body: {}
    });
  }

  getPersonalInfo(sender: string): Promise<IPersonalInfo> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/getUser/api/users/userInfo',
      sender: sender,
      receiver: 'getUserInfo',
      body: {},
    });
  }

  getSocialMediaProfiles(sender: string): Promise<ISocialMediaProfiles[]> {
    return this.customHttpService.sendRequest({
      endpoint: 'orchestrator/api/users/socialMedia',
      sender: sender,
      receiver: 'getSocialMediaProfiles',
      body: {}
    });
  }

  getAccountInfo(sender: string): Promise<IAccountInfo> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/getUser/api/users/accountInfo',
      sender: sender,
      receiver: 'getAccountInfo',
      body: {},
    });
  }

  updatePersonalInfo(sender: string, payload: IPersonalInfo): Promise<{result: boolean}> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/addEditUser/api/users/update/personalInfo',
      sender: sender,
      receiver: 'updatePersonalInfo',
      body: payload
    });
  }

  updateSocialMediaProfiles(sender: string, socialProfiles: { channelUrl: string }[]) {
    return this.customHttpService.sendRequest({
      endpoint: 'orchestrator/api/users/save/socialMediaProfiles',
      sender: sender,
      receiver: 'updateSocialMediaProfiles',
      body: {
        socialProfiles
      }
    });
  }

  getUserSubscriptionPlan(sender: string, languageId: number): Promise<UserSubscriptionPlan> {
    return this.customHttpService.sendRequest({
      endpoint: 'orchestrator/api/subscriptions/user/plan',
      sender: sender,
      receiver: 'plan',
      body: {
        languageId: languageId
      }
    });
  }

  getUserPurchaseLogOrders(sender: string, languageId: number): Promise<IPurchaseLog[]> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/getUser/api/transaction/user/orders',
      sender: sender,
      receiver: 'orders',
      body: {
        languageId: languageId
      },
    });
  }

  changeMobileNumber(sender: string, changeMobileNumberBody: object): Promise<{ result: boolean }> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/addEditUser/api/users/update/mobileNumber',
      sender: sender,
      receiver: 'mobileNumber',
      body: changeMobileNumberBody
    });
  }

  sendPinCode(sender: string, sendPinCodeObject: object): Promise<{result: boolean}> {
    return this.customHttpService.sendRequest({
      endpoint: 'orchestrator/api/emails/sendPinCode',
      sender: sender,
      receiver: 'sendPinCode',
      body: sendPinCodeObject
    })
  }

  verifyEmailPinCode(sender: string, activationObject: {pinCode: number,
    userData?: { email?: string, password?: string, userType: number, accessToken?: string } | {}}): Promise<{ result: boolean }> {
    return this.customHttpService.sendRequest({
      endpoint: 'orchestrator/api/emails/verifyPinCode',
      sender: sender,
      receiver: 'verifyPinCode',
      body: activationObject
    });
  }

  activateMobileNumber(sender: string, activationCode: number): Promise<{ result: boolean }> {
    return this.customHttpService.sendRequest({
      endpoint: 'orchestrator/api/sms/verifyPinCode',
      sender: sender,
      receiver: 'verifyPinCode',
      body: {
        pinCode: activationCode
      }
    });
  }

  getUserDevices(userInfo: {email: string, password: string, userType: number} | {}): Promise<IDevice[]> {
    return this.customHttpService.sendRequest({
      endpoint: 'orchestrator/api/auth/device',
      sender: 'devices',
      receiver: 'device',
      body: userInfo
    });
  }

  removeUserDevice(removeDevice: object): Promise<{ result: boolean }> {
    return this.customHttpService.sendRequest({
      endpoint: 'orchestrator/api/auth/device/logout',
      sender: 'devices',
      receiver: 'logout',
      body: removeDevice
    });
  }

  getLoyaltyInfo(): Promise<ILoyaltyInfo> {
    return this.customHttpService.sendRequest({
      endpoint: 'orchestrator/api/campaign/checkLoyalUser',
      sender: 'app',
      receiver: 'checkLoyalUser',
      body: {}
    });
  }

  getB2bBannerInfo(languageId?: number): Promise<IB2bBannerInfo> {
    return this.customHttpService.sendRequest({
      endpoint: 'orchestrator/api/organization/bannerData',
      sender: 'homePage',
      receiver: 'b2bBannerInfo',
      body: {
        languageId: languageId
      }
    })
  }

  getUserPlatforms(): Promise<IUserPlatform[]>{
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/SSO/api/sso/getUserPlatforms',
      sender: 'redirect-center',
      receiver: 'redirect-center',
      body: {}
    });
  }
}
