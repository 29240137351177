import {Injectable} from '@angular/core';
import {CustomHttpService} from "@services/request-token/custom-http.service";
import {IShoppingCartInfo} from "@interfaces/shopping-cart/shopping-cart-list.interface";

@Injectable({
  providedIn: 'root'
})
export class UserCartHttpService {

  constructor(private customHttpService: CustomHttpService) {
  }

  getAllItemsInShoppingCart(sender: string, languageId: number): Promise<IShoppingCartInfo> {
    return this.customHttpService.sendRequest({
      endpoint: 'orchestrator/api/carts/all',
      sender: sender,
      receiver: 'all',
      body: {
        languageId: languageId
      }
    });
  }

  addItemToShoppingCart(sender: string, itemId: number, cartItemType: number): Promise<{ result: true }> {
    return this.customHttpService.sendRequest({
      endpoint: 'orchestrator/api/carts/add',
      sender: sender,
      receiver: 'add',
      body: {
        itemId: itemId,
        cartItemType: cartItemType
      }
    });
  }

  deleteItemFromShoppingCart(sender: string, id: number, cartItemType: number): Promise<{ result: true }> {
    return this.customHttpService.sendRequest({
      endpoint: 'orchestrator/api/carts/delete',
      sender: sender,
      receiver: 'delete',
      body: {
        itemId: id,
        cartItemType: cartItemType
      }
    });
  }

  getCartItemNumber(): Promise<{numberOfCart: number}> {
    return this.customHttpService.sendRequest({
      endpoint: 'orchestrator/api/carts/count',
      sender: 'mini-cart',
      receiver: 'add',
      body: {}
    });
  }

  transferToWishlist(sender: string, itemId: number, cartItemType: number): Promise<{result: true}> {
    return this.customHttpService.sendRequest({
      endpoint: 'orchestrator/api/carts/toWishList',
      sender: sender,
      receiver: 'toWishList',
      body: {
        itemId: itemId,
        cartItemType: cartItemType
      }
    });
  }
}
