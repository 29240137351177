import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {SearchHttpService} from "@services/integrations/search/search-http.service";
import {IPaginationRequest} from '@interfaces/common/pagination.interface';
import {LanguageControlService} from "@services/language/language-control.service";
import {ICourse} from "@interfaces/course/course.interface";
import {
  SearchResponse,
  InstantSearchResults,
  ISearchMentor,
  SearchEntityType
} from "@interfaces/search/search.interface";
import {LOCAL_SEARCH_HISTORY} from "@constants/cookies.constants";
import {isPlatformServer} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(
    private searchHttp: SearchHttpService,
    private languageControl: LanguageControlService,
    @Inject(PLATFORM_ID) private platformId: any,
    ) { }

  searchInLocalHistory(query: string): Promise<string[] | undefined> {
    const searchHistoryList = this.getSearchHistory();
    return new Promise<string[] | undefined>(resolve => {
      if (searchHistoryList?.length) {
        let hits = searchHistoryList.filter(value => value.includes(query.toLowerCase()));
        resolve(hits.slice(0, 6))
      } else {
        resolve(undefined);
      }
    });
  }

  getSearchHistory(): string[] {
    const lsSearchHistory = localStorage.getItem(LOCAL_SEARCH_HISTORY) ?? null;
    if (lsSearchHistory) {
      try {
        let parsedLsSearchHistory: string[] = JSON.parse(lsSearchHistory);
        return Array.from(new Set(parsedLsSearchHistory))
      } catch (e) {
        return [];
      }
    } else {
      return [];
    }
  }

  addValueToLocalSearchHistory(value: string) {
    if(isPlatformServer(this.platformId)) return;
    const searchHistoryList = this.getSearchHistory();
    let localSearchHistorySet = new Set(searchHistoryList);
    if (!localSearchHistorySet.has(value.toLowerCase())) {
      searchHistoryList.unshift(value.toLowerCase());
      localStorage.setItem(LOCAL_SEARCH_HISTORY, JSON.stringify(searchHistoryList));
    }
  }

  deleteValueFromLocalSearchHistory(value: string) {
    const searchHistoryList = this.getSearchHistory();
    let localSearchHistorySet = new Set(searchHistoryList);
    if (localSearchHistorySet.has(value.toLowerCase())) {
      localSearchHistorySet.delete(value.toLowerCase());
      localStorage.setItem(LOCAL_SEARCH_HISTORY, JSON.stringify(Array.from(localSearchHistorySet)));
    }
  }

  instantSearch(query: string): Promise<InstantSearchResults> {
    return this.searchHttp.instantSearch(query);
  }

  getSearchResultsIds<T>(sender: string, query: string, entityType: SearchEntityType, paginationRequest: IPaginationRequest, filterMap?: { objectID: string }): Promise<SearchResponse<T>> {
    return this.searchHttp.getSearchResultsIds(sender, query, entityType, paginationRequest, filterMap);
  }

  getCoursesByIds(sender: string, coursesIds: number[]): Promise<ICourse[]> {
    const currentLanguageId = this.languageControl.currentLanguage.getValue().id;
    return this.searchHttp.getCoursesByIds(sender, coursesIds, currentLanguageId);
  }

  getMentorsByIds(sender: string, mentorsIds: string[]): Promise<ISearchMentor[]> {
    const currentLanguageId = this.languageControl.currentLanguage.getValue().id;
    return this.searchHttp.getMentorsByIds(sender, mentorsIds, currentLanguageId);
  }
}
