import {Inject, Injectable, Optional, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from '@angular/common';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Request} from 'express';
import {filter} from "rxjs/operators";
import {Observable} from "rxjs";
import {NOT_FOUND_PATH, StatusCode} from "@constants/http.constants";
import {REQUEST} from "@nguniversal/express-engine/tokens";

@Injectable({
  providedIn: 'root'
})
export class RouterEventsService {
  constructor(@Inject(PLATFORM_ID) private platformId: any,
              @Optional() @Inject(REQUEST) private request: Request,
              private router: Router,
              ) {
  }

  get navigationStart(): Observable<NavigationStart> {
    return this.router.events.pipe(
      filter((event): event is NavigationStart => event instanceof NavigationStart)
    );
  }
  get navigationEnd(): Observable<NavigationEnd> {
    return this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    );
  }

  private handleNavigationEnd(navigationEvent: NavigationEnd) {
    if (this.isRedirect(navigationEvent)) {
      this.redirectHandler(navigationEvent);
    }
  }


  subscribeToServerRouterEvents() {
    if (isPlatformServer(this.platformId)) {
      this.navigationEnd.subscribe(this.handleNavigationEnd.bind(this));
    }
  }

  private isRedirect(navigationEvent: NavigationEnd) {
    return navigationEvent.url !== navigationEvent.urlAfterRedirects
      && !navigationEvent.urlAfterRedirects.endsWith(`/${NOT_FOUND_PATH}`);
  }

  private redirectHandler(navigationEvent: NavigationEnd) {
    if (!this.request.res) return;
      this.request.res.header('Cache-Control', 'no-cache');
      this.request.res.status(StatusCode.REDIRECT_MOVED_PERMANENTLY);
      this.request.res.location(navigationEvent.urlAfterRedirects);
  }
}
