import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {SubscriptionHttpService} from "@services/integrations/subscription/subscription-http.service";
import {LanguageControlService} from "@services/language/language-control.service";
import {
  ICourseEnrollRequest,
  ISubscriptionPlan,
  ISubscriptionPlanWithExtraInfo,
  SubscriptionPlan, SubscriptionPlans
} from '@interfaces/subscription/subscription.interface';
import {environment} from '@environments/environment';
import {CustomHttpService} from '@services/request-token/custom-http.service';
import {Subject} from 'rxjs';
import {GoogleTagManagerService} from "@services/marketing/google-tag-manager.service";
import {isPlatformBrowser} from "@angular/common";
import {PaymentMethods} from "@interfaces/common/payment.interface";
import {TrackingDataService} from "@services/tracking/tracking-data.service";

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  private subscriptionPlans: { [Key: number]: ISubscriptionPlanWithExtraInfo[] } = {};
  public userPlanChanged: Subject<boolean> = new Subject();

  constructor(private subscriptionHttp: SubscriptionHttpService, private languageControl: LanguageControlService,
              private customHttpService: CustomHttpService,
              private trackingData: TrackingDataService,
              @Inject(PLATFORM_ID) private platformId: object,
              ) {
  }

  getAllSubscriptionPlans(): Promise<ISubscriptionPlanWithExtraInfo[]> {
    let currentLanguageId = this.languageControl.currentLanguage.getValue().code;
    return new Promise<ISubscriptionPlanWithExtraInfo[]>((resolve, reject) => {
      if (this.subscriptionPlans[currentLanguageId]) {
        resolve(this.subscriptionPlans[currentLanguageId]);
      } else {
        this.subscriptionHttp.getSubscriptionPlans().then((subscriptionPlans) => {
          const mappedSubscriptionPlans = this.mapSubscriptionPlans(subscriptionPlans);
          const subscriptionPlansWithExtraInfo: ISubscriptionPlanWithExtraInfo[] = mappedSubscriptionPlans.map(subscriptionPlan => {
            return {
              ...subscriptionPlan,
              isAnnualPlan: subscriptionPlan.duration > 90,
            }
          })
          this.subscriptionPlans[currentLanguageId] = subscriptionPlansWithExtraInfo;
          if (subscriptionPlans.countryCode) {
            this.trackingData.setSuperProperties({
              user_country: subscriptionPlans.countryCode,
            })
          }
          resolve(subscriptionPlansWithExtraInfo);
        }).catch(error => {
          if (isPlatformBrowser(this.platformId)) {
            console.error('Failed to retrieve Subscription Plans from API');
            console.error(error);
          }
          reject(error);
        });
      }
    });
  }

  async getPromotedSubscriptionPlan(): Promise<ISubscriptionPlanWithExtraInfo> {
    const plans = await this.getAllSubscriptionPlans();
    return plans.reduce((prevPlan, currentPlan) => {
      return (currentPlan.duration > prevPlan.duration) ? currentPlan : prevPlan;
    });
  }

  enrollInCourse(sender: string, course: ICourseEnrollRequest): Promise<{ result: boolean }> {
    return this.subscriptionHttp.enrollInCourse(course.itemId);
  }
  updateFutureXEnrollment(sender: string, courseId: number): void {
    if (this.customHttpService.platformCode == environment.platforms.futureX.code) {
      this.subscriptionHttp.updateFutureXEnrollment(sender, courseId);
    }
  }

  cancelPlan( reason: string): Promise<void> {
    return this.subscriptionHttp.cancelPlan(reason);
  }

  changePlan(planId: number): Promise<{ result: boolean }> {
    return this.subscriptionHttp.changePlan(planId).then((response) => {
      this.userPlanChanged.next(true);
      return response;
    });
  }

  private mapSubscriptionPlans(subscriptionPlans: SubscriptionPlans): ISubscriptionPlan[] {
    return subscriptionPlans.plans.map(plan => {
      return {
        currency: plan.price.currency,
        description: plan.name,
        duration: plan.duration,
        finalPrice: plan.price.price,
        id: plan.planId,
        name: plan.name,
        nameEn: plan.nameEn,
        originalPrice: plan.price.originalPrice,
        message: plan.subtitle,
        amountPerMonth: plan.price.pricePerMonth,
        originalAmountPerMonth: plan.price.originalPricePerMonth,
        countryName: subscriptionPlans.countryCode,
        paymentMethods: plan.paymentMethods.map(method => PaymentMethods[method]),
        originalPriceUSD: plan.price.originalPriceInUsd,
        finalPriceUSD: plan.price.priceInUsd,
        price: plan.price.price,
      }
    })

  }
}
