import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AppRedirectsService} from '@services/app-redirects/app-redirects.service';
import {UiLoaderService} from '@services/ui-loader/ui-loader.service';
import {ConnectorV2Service} from '@services/connector/connector-v2.service';
import {UserManagerService} from '@services/user/user-manager.service';
import {SSORedirectUrlParams} from '@interfaces/common/http.interface';
import {EPrevError} from '@interfaces/user/user-common.interface';
import {REDIRECT_PARAMETER} from '@constants/query-strings.constants';
import {DEFAULT_HOME_PATH} from '@constants/http.constants';

@Injectable({
  providedIn: 'root'
})
export class UserInfoGuard  {

  constructor(private userManagerService: UserManagerService, private router: Router, private appRedirects: AppRedirectsService,
              private uiLoader: UiLoaderService, private connectorService: ConnectorV2Service) {
  }

  private checkUserAuth(state: RouterStateSnapshot, route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.userManagerService.checkUserAuthorization(route, this.userManagerService.userPlatform)
        .then(({
                 authorized,
                 reachedDevicesLimit,
                 name
               }) => {
          console.log('name', name);
          if (authorized) {
            this.connectorService.connectorLoaded.next(true);
            this.connectorService.connectorLoaded.complete();
            if (!name) {
              resolve(true);
            } else {
              resolve(false);
              const urlPath = route.queryParams[REDIRECT_PARAMETER] ?? `/${DEFAULT_HOME_PATH}`
              this.router.navigateByUrl(urlPath);
            }
          } else {
            resolve(false);
            this.redirectToRedirectCenter({prevErr: EPrevError.NoAccess});
          }
        }).catch((e) => {
        resolve(false);
        this.redirectToRedirectCenter({prevErr: EPrevError.NoAccess});
      });
    });
  }

  private redirectToRedirectCenter(extraParams?: SSORedirectUrlParams) {
    this.uiLoader.startUiLoader('outside-loader');
    this.appRedirects.redirectToSSO('redirect-center', extraParams);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkUserAuth(state, route);
  }

}
