import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'alm-root-skeleton-placeholder',
  templateUrl: './skeleton-placeholder.component.html',
  styleUrls: ['./skeleton-placeholder.component.scss']
})
export class SkeletonPlaceholderComponent implements OnInit {
  @Input() count: number = 5;
  @Input() animation: 'progress' | 'progress-dark' | 'pulse' | 'false' | false = 'progress';
  @Input() wrapperClasses: string = '';
  
  constructor() {
  }

  ngOnInit(): void {
  }

}
