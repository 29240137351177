import {Injectable} from '@angular/core';
import {LookupsHttpService} from './lookups-http.service';
import {CourseCategory} from '@interfaces/course/category.interface';
import {LanguageControlService} from '@services/language/language-control.service';
import {ICity, ICountry, ICountryTranslated} from '@interfaces/common/auth.interface';

@Injectable({
  providedIn: 'root'
})
export class LookupsService {

  private allCategories: { [key: number]: CourseCategory[] } = {};
  private allCountries: { [key: number]: ICountry[] } = {};

  constructor(private lookupsHttp: LookupsHttpService, private languageService: LanguageControlService) {
  }

  getAllCategories(sender: string, categoryTypeId: number): Promise<CourseCategory[]> {
    return new Promise<CourseCategory[]>((resolve, reject) => {
      let currentLanguageId = this.languageService.currentLanguage.getValue().id;
      if (this.allCategories[currentLanguageId]) {
        resolve(this.allCategories[currentLanguageId]);
      } else {
        this.lookupsHttp.getAllCategoryByLanguageId(sender, currentLanguageId, categoryTypeId)
          .then((categories) => {
            this.allCategories[currentLanguageId] = categories;
            resolve(categories);
          })
          .catch((e) => reject(e));
      }
    });
  }

  getAllCountriesByLanguageId(sender: string): Promise<ICountry[]> {
    return new Promise<ICountry[]>((resolve, reject) => {
      let currentLanguageId = this.languageService.currentLanguage.getValue().id;
      if (this.allCountries[currentLanguageId]) {
        resolve(this.allCountries[currentLanguageId]);
      } else {
        this.lookupsHttp.getAllCountriesByLanguageId(sender, currentLanguageId)
          .then((allCountries) => {
            allCountries = allCountries.sort((a, b) => {
              let nameA = a.name.toUpperCase();
              let nameB = b.name.toUpperCase();
              return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
            });
            this.allCountries[currentLanguageId] = allCountries;
            resolve(allCountries);
          })
          .catch((e) => reject(e));
      }
    });
  }

  getAllMentorsCountriesByLanguageId(sender: string): Promise<ICountry[]> {
    return new Promise<ICountry[]>((resolve, reject) => {
      let currentLanguageId = this.languageService.currentLanguage.getValue().id;
      if (this.allCountries[currentLanguageId]) {
        resolve(this.allCountries[currentLanguageId]);
      } else {
        this.lookupsHttp.getAllMentorsCountriesByLanguageId(sender, currentLanguageId)
          .then((allCountries) => {
            allCountries = allCountries.sort((a, b) => {
              let nameA = a.name.toUpperCase();
              let nameB = b.name.toUpperCase();
              return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
            });
            this.allCountries[currentLanguageId] = allCountries;
            resolve(allCountries);
          })
          .catch((e) => reject(e));
      }
    });
  }

  getAllCitiesById(sender: string, entityId: number): Promise<ICity[]> {
    let currentLanguageId = this.languageService.currentLanguage.getValue().id;
    return this.lookupsHttp.getAllCitiesByCountryId(sender, entityId, currentLanguageId);
  }

  getPhoneCode(sender: string): Promise<{phoneCode: string}> {
    return this.lookupsHttp.getPhoneCode(sender);
  }

  getCountries(sender: string): Promise<ICountryTranslated[]> {
    return this.lookupsHttp.getCountries(sender)
  }
}
