import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import aa from 'search-insights';
import {isPlatformBrowser, isPlatformServer} from "@angular/common";
import {environment} from "@environments/environment";
import {UserManagerService} from "@services/user/user-manager.service";
import {EventClickAfterSearch} from "@interfaces/search/search.interface";
import {CookieService} from "ngx-cookie";
import {ALGOLIA_ANONYMOUS_USER_TOKEN, baseLinkCookieOptions} from "@constants/cookies.constants";

@Injectable({
  providedIn: 'root'
})
export class AlgoliaAnalyticsService {

  constructor(private userManagerService: UserManagerService,
              @Inject(PLATFORM_ID) private platformId: any,
              private cookieService: CookieService,
  ) {
    this.initAlgoliaAnalytics(environment.algolia.appId, environment.algolia.apiKey)
  }

  initAlgoliaAnalytics(appId: string, apiKey: string): void {
      if (isPlatformServer(this.platformId)) return;
      aa('init', {
        appId: appId,
        apiKey: apiKey,
        anonymousUserToken: true,
        userToken: this.getUserToken(),
      });
      aa('onUserTokenChange', (userToken) => {
        this.storeUserToken(userToken);
      });
  }

  sendEventClickAfterSearch(clickAfterSearch: EventClickAfterSearch) {
    let userInfo = this.userManagerService.userInfo.getValue();
    aa('clickedObjectIDsAfterSearch', {
      eventName: clickAfterSearch.eventName,
      index: clickAfterSearch.indexName,
      queryID: clickAfterSearch.queryID,
      objectIDs: [clickAfterSearch.objectId],
      positions: [clickAfterSearch.position + 1],
      authenticatedUserToken: userInfo?.uuid
    });

  }

  sendEventClick(object: { objectId: string, eventName: string, index: string }) {
      let userInfo = this.userManagerService.userInfo.getValue();
      if (userInfo?.uuid) {
        aa('clickedObjectIDs', {
          userToken: userInfo.uuid,
          eventName: object.eventName,
          index: object.index,
          objectIDs: [object.objectId]
        });
      }
  }

  private storeUserToken(userToken: string | number) {
    let cookieDate = new Date();
    cookieDate.setFullYear(cookieDate.getFullYear() + 1);
    this.cookieService.put(ALGOLIA_ANONYMOUS_USER_TOKEN,
      userToken.toString(),
      {
        ...baseLinkCookieOptions,
        expires: cookieDate,
      }
    );
  }
  private getUserToken(): string | undefined {
    return this.cookieService.get(ALGOLIA_ANONYMOUS_USER_TOKEN);
  }
}
