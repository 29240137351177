import { Injectable } from '@angular/core';
import {CustomHttpService} from "@services/request-token/custom-http.service";
import {CouponVaidationRequest, ICartCoupon, IPlanCoupon} from "@interfaces/coupon/coupon.interface";

@Injectable({
  providedIn: 'root'
})
export class CouponsHttpService {

  constructor(private customHttpService: CustomHttpService)  { }

  validateCouponForCart(couponCode: string, languageId: number):Promise<ICartCoupon> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/couponValidate/api/campaign/coupon/validate',
      sender: 'cart',
      receiver: 'coupon',
      body: {
        couponCode: couponCode,
        languageId: languageId
      }
    })
  }
  validateCouponForAuthorized(couponVaidationRequest: CouponVaidationRequest):Promise<IPlanCoupon> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/couponValidate/api/campaign/coupon/validate',
      sender: 'subscription',
      receiver: 'coupon',
      body: couponVaidationRequest
    })
  }

  validateCouponForAnonymous(couponVaidationRequest: CouponVaidationRequest):Promise<IPlanCoupon> {
    return this.customHttpService.sendRequest({
      endpoint: 'new-orchestrator/couponValidate/api/campaign/coupon/validate-anonymous',
      sender: 'subscription',
      receiver: 'coupon',
      body: couponVaidationRequest
    })
  }
}
