<div class="app-container" [dir]="appDirection"
     [ngClass]="{'course-viewer-margin': removeHeader, 'remove-header-margin': isMobileMenuOpen, 'gtm-ribbon-margin': isGtmRibbonOpen}">
  <div *ngIf="!hasSimplifiedHeader" class="header-container" [ngStyle]="{display: removeHeader ? 'none' : 'block'}">
    <alm-root-main-header-new [userInfoSubject]="userInfoSub"
                              [loginLink]="loginLink" [signupLink]="signupLink"
                              [unReadNotificationCount]="unReadNotificationCount"
                              [numberOfUnreadMessages]="numberOfUnreadMessages"
                              (onMobileSearchOpen)="closeMobileMenu()"
                              (onMobileMenuToggle)="onMenuButtonClick($event)"
                              (closeGtmRibbon)="onClosingGtmRibbon($event)"
                              (openGtmRibbon)="openGtmRibbon($event)"
                              [loyaltyInfo]="loyaltyInfo"
                              [removeHeader]="removeHeader || isMobileMenuOpen"
                              [isB2bUser]="isB2bUser"></alm-root-main-header-new>
  </div>
  <div *ngIf="hasSimplifiedHeader" class="header-container">
    <alm-root-simplified-header>
    </alm-root-simplified-header>
  </div>
  <div *ngIf="showAppAnnouncement" class="header"
       [class.app-announcement-bg]="showAppAnnouncement">
    <alm-root-top-ribbon-app-announcement
      (closeAppAnnouncement)="hideAppAnnouncement()"></alm-root-top-ribbon-app-announcement>
  </div>
  <div class="app-container__mobile-side-menu"
       [class.app-container__mobile-side-menu__grace-period]="userInfoSub && userInfoSub?.getValue() && userInfoSub?.getValue()?.subscribed && userInfoSub?.getValue()?.subscriptionStatus== 3"
       [class.app-container__mobile-side-menu__opened]="!isProfileMenu && isMobileMenuOpen"
       [class.app-container__mobile-side-menu__opened__profile]="isProfileMenu && isMobileMenuOpen">
    <mat-drawer-container class="app-container__mobile-side-menu__drawer-container"
                          [ngStyle]="{ maxHeight: isMobileMenuOpen ? '100vh' : 'unset'}">
      <mat-drawer #headerMobileDrawer class="app-container__mobile-side-menu__drawer-sidenav"
                  [autoFocus]="false"
                  [position]="isProfileMenu ? 'end' : 'start'"
                  [opened]="isMobileMenuOpen" mode="over"
                  [class.app-announcement-space]="showAppAnnouncement">
        <alm-root-header-mobile-menu (onMobileMenuToggle)="onMenuButtonClick(false)"
                                     (onItemSelect)="closeMobileMenu()"
                                     [currentView]="mobileMenuView"
                                     (onViewChange)="changeHeaderMenuView($event)"
                                     *ngIf="!isProfileMenu"
                                     [loginLink]="loginLink" [signupLink]="signupLink"
                                     [isB2bUser]="isB2bUser"
                                     [userInfoSubject]="userInfoSub"
                                     [organizationNames]="organizationNames"></alm-root-header-mobile-menu>
        <div class="app-container__mobile-side-menu__drawer-sidenav-profile-menu" *ngIf="userInfoSub && isProfileMenu">
          <div class="app-container__mobile-side-menu__drawer-sidenav-profile-menu_close">
            <alm-root-header-user-menu [userInfo]="userInfoSub.getValue()"
                                       [isB2bUser]="isB2bUser"
                                       (onItemSelect)="closeMobileMenu()"
                                       [userAvatar]="getProfileImage(userInfoSub.getValue()?.uuid)"
                                       [numberOfUnreadMessages]="numberOfUnreadMessages"></alm-root-header-user-menu>
          </div>
        </div>
      </mat-drawer>
      <div class="app-container__mobile-side-menu__drawer-content"
           [class.app-announcement-space]="showAppAnnouncement">
        <ng-content></ng-content>
      </div>
    </mat-drawer-container>
  </div>
</div>
