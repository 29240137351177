import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID
} from '@angular/core';
import {ILanguage} from "@interfaces/common/language.interface";
import {ThemeControlService, THEMES} from "@services/theme/theme-control.service";
import {LanguageControlService} from "@services/language/language-control.service";
import ar from './i18n/ar.json';
import en from './i18n/en.json';
import {IUserInfo} from "@interfaces/authorized-user/user.interface";
import {FilesManagerService} from "@services/files/files-manager.service";
import {MatMenuTrigger} from "@angular/material/menu";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {GoogleTagManagerService} from "@services/marketing/google-tag-manager.service";
import {isPlatformBrowser} from "@angular/common";
import {AmplitudeService} from "@services/marketing/amplitude.service";
import {environment} from "@environments/environment";
import {AppRedirectsService} from "@services/app-redirects/app-redirects.service";
import {BrazeUserAttributesService} from '@services/marketing/braze/events/braze-user-attributes.service';
import {ELanguage} from "@interfaces/common/connector.interface";
import {DEFAULT_LOGIN_PATH} from '@constants/http.constants';

@Component({
  selector: 'alm-root-user-control-menu',
  templateUrl: './user-control-menu.component.html',
  styleUrls: ['./user-control-menu.component.scss']
})
export class UserControlMenuComponent implements OnInit, OnDestroy {

  @Input() loginLink: string = '';
  @Input() signupLink: string = '';
  @Input() updatedImage: string | undefined = undefined;
  @Input() userInfo: IUserInfo | null = null;
  @Input() numberOfCartItems: number = 0;
  @Input() numberOfUnreadMessages: number = 0;
  @Input() unReadNotificationCount: number = 0;
  @Input() isB2bUser: boolean = false;
  @Output() onOpeningUserMenu: EventEmitter<any> = new EventEmitter<any>();
  languages: ILanguage[] = [];
  currentLanguage: ILanguage;
  nextLanguage: ILanguage | undefined;
  currentTheme: THEMES;
  menuDirection: 'rtl' | 'ltr' = 'ltr';
  private cartMenuTrigger?: MatMenuTrigger;
  private profileMenuTrigger?: MatMenuTrigger;
  private notificationMenu?: MatMenuTrigger;
  private subscriptions: Subscription[] = [];

  constructor(private languageControl: LanguageControlService, private themeControl: ThemeControlService,
              private filesManager: FilesManagerService, private router: Router, private gtmService: GoogleTagManagerService,
              @Inject(PLATFORM_ID) private platformId: any, private amplitudeService: AmplitudeService,
              private appRedirects: AppRedirectsService, private brazeUserAttributesService: BrazeUserAttributesService) {
    this.currentLanguage = this.languageControl.currentLanguage.getValue();
    this.currentTheme = this.themeControl.currentTheme.getValue();
    this.languageControl.setTranslations('ar', ar);
    this.languageControl.setTranslations('en', en);
    let langSub = this.languageControl.currentLanguage.subscribe((lang) => {
      this.menuDirection = lang.direction;
    });
    this.subscriptions.push(langSub);

  }

  @HostListener('window:resize')
  onWindowResize(): void {
    this.controlMenuOnWindowResize();
  }

  ngOnInit(): void {
    this.languageControl.getAllLanguages().then((languages) => {
      this.languages = languages;
      this.getNextLanguage(this.currentLanguage);
    });
    let langSub = this.languageControl.currentLanguage.subscribe((currentLanguage) => {
      this.currentLanguage = currentLanguage;
      this.getNextLanguage(currentLanguage);
    });
    this.subscriptions.push(langSub);
    let themeSub = this.themeControl.currentTheme.subscribe((currentTheme) => {
      this.currentTheme = currentTheme;
    });
    this.subscriptions.push(themeSub);

  }

  onLanguageChange(language: ELanguage): void {
    this.languageControl.changeLanguageAndRedirect(language);
    this.brazeUserAttributesService.setUserLanguage(language);
    this.amplitudeService.languageChangedEvent({
      Website_Language_Changed_Language: language === 'ar' ? 'Arabic' : 'English'
    })
  }

  getNextLanguage(currentLanguage: ILanguage): void {
    this.languages.map((language: ILanguage) => {
      if (language.code != currentLanguage.code) {
        this.nextLanguage = language;
      }
    });
  }

  onThemeChange(): void {
    let theme = this.currentTheme == THEMES.LIGHT ? THEMES.DARK : THEMES.LIGHT;
    this.themeControl.changeTheme(theme);
    this.brazeUserAttributesService.setUserTheme(theme);
    this.gtmService.sendCustomEvent('THEME-CHANGED', {changedTo: theme == THEMES.LIGHT ? 'LIGHT' : 'DARK'});
    this.amplitudeService.themeChangedEvent({
      Website_Theme_Changed_Theme: theme === THEMES.DARK ? 'DARK' : 'LIGHT'
    })
  }

  getProfileImage(userId: string): string {
    return this.filesManager.getHashedImage(`user-${userId}-profile`)
  }


  closeCartMenu() {
    if (this.cartMenuTrigger) {
      this.cartMenuTrigger.closeMenu();
    }
  }

  closeNotificationMenu() {
    if (this.notificationMenu) {
      this.notificationMenu.closeMenu();
    }
  }

  controlMenuOnWindowResize(): void {
    if (isPlatformBrowser(this.platformId)) {
      let windowWidth = window.innerWidth;
      if (windowWidth < 991) {
        this.closeCartMenu();
        this.closeNotificationMenu();
      }
    }
  }

  onMiniCartOpen(cartMenuTrigger: MatMenuTrigger) {
    this.cartMenuTrigger = cartMenuTrigger;
  }

  onNotificationMenuOpen(notificationMenu: MatMenuTrigger) {
    this.notificationMenu = notificationMenu;
  }

  onProfileMenuOpen(profileMenuTrigger: MatMenuTrigger) {
    this.profileMenuTrigger = profileMenuTrigger;
    this.onOpeningUserMenu.emit();
  }

  ngOnDestroy(): void {
    this.subscriptions.map((sub) => {
      sub.unsubscribe();
    })
  }

  openCartPage() {
    if (this.userInfo) {
      if (!this.numberOfCartItems || this.numberOfCartItems <= 0) {
        this.router.navigate(['/cart'])
      }
    } else {
      this.router.navigate([this.loginLink]);
    }
  }

  onCartItemsCountUpdated($event: number) {
    this.numberOfCartItems = $event;
  }

  onNavigationToSignUp() {
    this.appRedirects.redirectToSSO(DEFAULT_LOGIN_PATH, {ref: 'header_signup_button'});
  }

  navigateToLogin() {
    this.appRedirects.redirectToSSO(DEFAULT_LOGIN_PATH, {ref: 'header_login_button'});
  }
}
