import {Injectable} from '@angular/core';
import {CouponsHttpService} from "@services/integrations/coupons/coupons-http.service";
import {CouponVaidationRequest, ICartCoupon, IPlanCoupon} from "@interfaces/coupon/coupon.interface";
import {LanguageControlService} from "@services/language/language-control.service";
import {TranslateService} from "@ngx-translate/core";
import {UserManagerService} from "@services/user/user-manager.service";

@Injectable({
  providedIn: 'root'
})
export class CouponsService {

  constructor(
    private couponsHttpService: CouponsHttpService,
    private languageControl: LanguageControlService,
    private translateService: TranslateService,
    private userManagerService: UserManagerService,
              ) {
  }

  validateCouponForCart(couponCode: string): Promise<ICartCoupon> {
    let currentLanguageId = this.languageControl.currentLanguage.getValue().id;
    return this.couponsHttpService.validateCouponForCart(couponCode, currentLanguageId);
  }

  validateCouponForSubscriptionPlan(couponVaidationRequest: CouponVaidationRequest): Promise<IPlanCoupon> {
    let userInfo = this.userManagerService.userInfo.getValue();
    if (userInfo) {
      return this.couponsHttpService.validateCouponForAuthorized(couponVaidationRequest);
    } else {
      return this.couponsHttpService.validateCouponForAnonymous(couponVaidationRequest);
    }
  }

  getValidationError(code: number): string {
    let httpKey = `http_error.coupon_${code}`;
    let translation = this.translateService.instant(httpKey);
    if (translation == httpKey || translation == '') {
      httpKey = `http_error.0`;
    }
    return httpKey;
  }
}
